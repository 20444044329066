<template>
  <div>

    <div class="section m-0">

    <div class="container">

      <section id="content" class="pricing">   
        <div class="container pt-5"> 

            <div class="col-md-12 text-center">
              <h4 class="title-text">{{$t('youSetPrice')}}</h4>
              <h3 class="title-text2">{{$t('howMuchMining')}}</h3>
          </div>

    <div class="row g-4 mt-5 slider-box-wrap package-container">
        <div class="col-12">
            <div class="slider-bottom-box package gold shadow-lg">
              <div class="row">
                <div class="col-lg-4 mb-3 mb-lg-0 vertLine">
                    <h5>{{$t('amountPay')}} </h5>
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" 
                      v-model="inputAmount" 
                      @input="updateCalculator"
                      @keypress="restrictToNumbers" 
                      aria-label="Amount (to the nearest dollar)">
                      <span class="input-group-text">USD</span>
                    </div>
                    <div class="col d-flex align-items-center">
                      <p><strong>{{$t('minerHashRate')}}</strong></p>
                      <span class="ms-auto">{{ minerHashRate }} {{ hashUnit }}</span>
                    </div>
                    <div class="col d-flex align-items-center">
                        <p class="form-text mb-0"><strong>{{$t('unitPrice')}}</strong></p>
                        <span class="ms-auto">{{ pricesData.hash_price }} USD</span>
                    </div>
                    <!-- <hr>
                    <div class="col d-flex align-items-center">
                        <p><strong>{{$t('minimum')}}</strong></p>
                        <span class="ms-auto">{{ minimumHash.toFixed(2) }} TH/s</span>
                    </div>
                    <div class="col d-flex align-items-center">
                      <p><strong>{{$t('maximum')}}</strong></p>
                      <span class="ms-auto">{{ maximumHash.toFixed(2) }} PH/s</span>
                    </div> -->
                    
                </div>
                <div class="col-lg-8 col-sm-6">
                    <h5>{{$t('estimatedIncome')}}</h5>
                    <div class="d-flex flex-wrap">
                        <div class="col-md-4">
                            <p><strong>{{$t('perHour')}}</strong></p>
                        </div>
                        <div class="col-md-4">
                            <p class="primary-text">{{ (hourlyIncome * 100).toFixed(2) }} %</p>
                        </div>
                        <div class="col-md-4">
                            <p class="primary-text"><strong>$ {{ hourlyIncomeValue }}</strong></p>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap">
                        <div class="col-md-4">
                            <p><strong>{{$t('perDay')}}</strong></p>
                        </div>
                        <div class="col-md-4">
                            <p class="primary-text">{{ (dailyIncome * 100).toFixed(2) }} %</p>
                        </div>
                        <div class="col-md-4">
                            <p class="primary-text"><strong>$ {{ dailyIncomeValue }}</strong></p>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap">
                        <div class="col-md-4">
                            <p><strong>{{$t('perWeek')}}</strong></p>
                        </div>
                        <div class="col-md-4">
                            <p class="primary-text">{{ (weeklyIncome * 100).toFixed(2) }} %</p>
                        </div>
                        <div class="col-md-4">
                            <p class="primary-text"><strong>$ {{ weeklyIncomeValue }}</strong></p>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap">
                        <div class="col-md-4">
                            <p><strong>{{$t('perMonth')}}</strong></p>
                        </div>
                        <div class="col-md-4">
                            <p class="primary-text">{{ (monthlyIncome * 100).toFixed(2) }} %</p>
                        </div>
                        <div class="col-md-4">
                            <p class="primary-text"><strong>$ {{ monthlyIncomeValue }}</strong></p>
                        </div>
                    </div>
                    <!-- <div class="d-flex flex-wrap">
                        <div class="col-md-4">
                            <p><strong>{{$t('perMonth')}}</strong></p>
                        </div>
                        <div class="col-md-4">
                            <p>{{ (monthlyIncome * 100).toFixed(2) }} %</p>
                        </div>
                        <div class="col-md-4">
                            <p>$ {{ monthlyIncomeValue }}</p>
                        </div>
                    </div> -->
                    <hr>
                    <div class="d-flex">
                      <div class="col-md-4">
                        <p class="text-muted">{{$t('estimatedROI')}}</p>
                      </div>
                      <div class="col-md-4">
                      <span class="ms-auto primary-text">~ {{ roi === '365' ? '∞' : roi +' '+ $t('days') }} </span>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="col-md-4">
                        <p class="text-muted">{{$t('contract_duration')}}:</p>
                      </div>
                      <div class="col-md-4">
                      <span class="ms-auto primary-text"> {{ contractDuration +' ' + $t('days') }} </span>
                      </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
</div>

</section>

</div>


<div class="container ">
<section id="content" class="pricing">
    <div class="container pt-5 package gold">
  <div class="row g-4">
    <!-- Imágenes de hardware -->
    <div class="col-lg-6">
      <div class="row g-3">
        <div class="col-md-4">
          <div class="rounded-2xl h-100 shadow p-4 d-flex align-items-center justify-content-center">
            <img src="@/assets/images/static/s21xp.jpg" class="img-fluid" alt="Bitcoin Miner S21 XP Hyd">
          </div>
        </div>
        <div class="col-md-4">
          <div class="rounded-2xl h-100 shadow p-4 d-flex align-items-center justify-content-center">
            <img src="@/assets/images/static/l9.jpg" class="img-fluid" alt="L9">
          </div>
        </div>
        <div class="col-md-4">
          <div class="rounded-2xl h-100 shadow p-4 d-flex align-items-center justify-content-center">
            <img src="@/assets/images/static/rack.jpg" class="img-fluid" alt="Rack">
          </div>
        </div>
      </div>
    </div>

    <!-- Texto de descripción -->
    <div class="col-lg-6 order-lg-first">
  <div class="text-center text-lg-start">
    <p class="text-dark fs-5 fw-medium mb-1">{{ $t('cloudMining.title') }}</p>
    <h2 class="text-dark fw-bold display-5" v-html="$t('cloudMining.subtitle')"></h2>
    <ul class="list-unstyled mt-4 text-secondary fs-5 fw-medium">
      <li class="mb-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="primary-text me-2" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
        </svg>
        <span v-html="$t('cloudMining.features.topHardware')"></span>
      </li>
      <li class="mb-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="primary-text me-2" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
        </svg>
        <span v-html="$t('cloudMining.features.noFees')"></span>
      </li>
      <li class="mb-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="primary-text me-2" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
        </svg>
        <span v-html="$t('cloudMining.features.affiliateCommission')"></span>
      </li>
      <li class="mb-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="primary-text me-2" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
        </svg>
        <span v-html="$t('cloudMining.features.signupBonus')"></span>
      </li>
    </ul>
  </div>
</div>

  </div>
</div>
</section>
</div>


</div>

<Footer/>

  </div>
</template>

<script>
import Footer from './Footer.vue';
import axios from 'axios';
export default {
  name: 'CloudhashFrontendPricing',
  components: { Footer },
  data() {
    return {
        contractDuration: 0,
        inputAmount: 10,
        unitPrice: 0.21,
        minimumHash: 4.20,
        maximumHash: 50.10,
        minerHashRate: 0,
        hourlyIncome: 0,
        hourlyIncomeValue: 0,
        dailyIncome: 0,
        dailyIncomeValue: 0,
        weeklyIncome: 0,
        weeklyIncomeValue: 0,
        monthlyIncome: 0,
        monthlyIncomeValue: 0,
        roi: 0,
        hashUnit: 'TH/s',
        pricesData: {
            miner: 'Antminer Bitcoin',
            unit: 'GH/s',
            hash_price: 0.0500,
        }
    };
  },

  mounted() {
    this.updateCalculator();
  },

  methods: {
    restrictToNumbers(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        if (!/^[0-9]+$/.test(keyValue)) {
            event.preventDefault();
        }
    },
    convertHashRate(value, fromUnit, toUnit) {
        const units = {
            GH: 1,
            TH: 1000,
            PH: 1000000,
            EH: 1000000000
        };

        const fromValue = value * units[fromUnit];
        const toValue = fromValue / units[toUnit];

        return toValue;
    },
    convertToTHS(amount) {
        // Convertir a TH/s
        // Puedes ajustar esta función según las unidades que uses
        // GH/s, TH/s, PH/s, EH/s
        // Por ejemplo, si inputAmount es en PH/s, necesitas multiplicar por 1000 para obtener TH/s
        const hashUnits = ['GH/s', 'TH/s', 'PH/s', 'EH/s']; // Unidades de hash
        let index = 0; // Índice de TH/s por defecto
        
        while (amount >= 1000 && index < hashUnits.length - 1) {
            amount /= 1000;
            index++;
        }
        
        return {
            hashValue: amount,
            hashUnit: hashUnits[index]
        };
    },
    updateCalculator() {
        const inputAmount = this.inputAmount; // Valor por defecto
        const unitPrice = 0.21; // Valor por defecto
        const maxHash = 50.10; // Máximo de compra en PH/s
        let dailyPercentage = 0.025; // Porcentaje por defecto (2.5%)
        let dailyProfit = 0;
        let hourlyProfit = 0;
        let weeklyProfit = 0;
        let monthlyProfit = 0;
        let roi = 0;

        // Determinar el porcentaje diario según el monto ingresado
        if (inputAmount <= 60) {
            dailyPercentage = 0.025; // 2.5%
        } else if (inputAmount >= 61 && inputAmount <= 100) {
            dailyPercentage = 0.03; // 3%
        } else if (inputAmount >= 101 && inputAmount <= 220) {
            dailyPercentage = 0.035; // 3.5%
        } else if (inputAmount >= 221 && inputAmount <= 700) {
            dailyPercentage = 0.04; // 4%
        } else if (inputAmount >= 701) {
            dailyPercentage = 0.05; // 5%
        }
        
        //console.log(dailyPercentage * 100+' %');

        this.minerHashRate = (inputAmount / this.pricesData.hash_price).toFixed(2);
        //this.minerHashRate = hashValue;
        //this.hashrateUnit = priceData.unit;

        // Calcular ganancias diarias, mensuales y ROI
        dailyProfit = inputAmount * dailyPercentage;
        hourlyProfit = dailyProfit / 24;
        weeklyProfit = dailyProfit * 7;
        monthlyProfit = dailyProfit * 30;
        roi = (inputAmount / monthlyProfit) * 30; // ROI en días
        const daysToDouble = roi * 2;
        const totalProfit = inputAmount * 2;

        this.contractDuration = daysToDouble % 1 === 0 ? daysToDouble.toFixed(0) : daysToDouble.toFixed(1);
        
        

        // Convertir el valor ingresado a TH/s
        let { hashValue, hashUnit } = this.convertToTHS(this.minerHashRate);

        //console.log(hashValue);
//        this.minerHashRate = hashValue;
        this.hashUnit = hashUnit; // Unidad de hash
        
        // Ajustar los valores de los beneficios
        this.hourlyIncome = dailyPercentage / 24;
        this.hourlyIncomeValue = hourlyProfit.toFixed(2);
        this.dailyIncome = dailyPercentage;
        this.dailyIncomeValue = dailyProfit.toFixed(2);
        this.weeklyIncome = dailyPercentage * 7;
        this.weeklyIncomeValue = weeklyProfit.toFixed(2);
        this.monthlyIncome = dailyPercentage * 30;
        this.monthlyIncomeValue = monthlyProfit.toFixed(2);
        
        this.roi = roi % 1 === 0 ? roi.toFixed(0) : roi.toFixed(1);
        
        
    },
    profitPerTime(amount, days) {
        let monthly = amount < 100 ? 1.2 : 1.93;
        let daily = monthly / 30;
        let weekly = daily * 7;
        let hourly = daily / 60;

        return { hourly, daily, weekly, monthly };  
    }
    
  },
};
</script>
<style scope>
.card {
    border: none;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.card-title {
    color: #3883dd;
}

hr {
    border-top: 1px solid #dee2e6;
}

.slider-bottom-box {
    display: block;
    padding: 40px;
    background: #fff;
    box-shadow: 0 10px 40px 0 rgba(47,47,47,.1);
    border-radius: 3px;
}

.vertLine {
    border-right: 1px solid #dee2e6;
    height: 100%;
}

.package-container .package {
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right top;
    background-size: cover;
    box-shadow: 0 1px 4px #23232329;
    border-radius: 8px;
    padding: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.slider-bottom-box {
    display: block;
    padding: 40px;
    background: #fff;
    box-shadow: 0 10px 40px 0 rgba(47, 47, 47, .1);
    border-radius: 3px;
}

.slider-box-wrap {
    margin-top: 5rem;
}

.slider-box-wrap p {
  margin-bottom: 5px; /* Espacio entre párrafos */
}

hr {
  opacity: 0.45;
}

.input-group input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 4px #23232329;
    font-size: 20px;
    border: 1px solid transparent;
    color: #3a3939;
    transition: .2s ease box-shadow;
    padding: 9px 16px 11px;
    margin: 0;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.5;
    color: var(--bs-body-color);
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    box-shadow: 0 1px 4px #23232329;
    border: 1px solid transparent;
    border-radius: var(--bs-border-radius);
}

.input-group input:focus {
    outline: none;
    border: 1px solid rgb(25, 52, 93);
    box-shadow: 0 5px 10px #fba3421a
}

.input-group input:hover {
    box-shadow: 0 4px 12px #23232329
}

.primary-text {
    color: #002BAE
}

.pricing {
    background-color: #fff;
    margin-top: 40px;
    box-shadow: 0 1px 4px #23232329;
    border-radius: 8px;
    padding: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.title-text {
    line-height: 36px;
    font-family: Lora, serif;
    font-style: italic;
    color: #002BAE;
}

.title-text2 {
    color: #fbc241;
    line-height: 36px;
    font-family: Lora, serif;
    font-style: italic;
}

.pricing h2 {
    font-size: 48px;
    font-weight: 800;
    line-height: 36px;
    font-family: Lora, serif;
    font-style: italic;
}
</style>