<template>
  <div>
    <div class="container mb32 mt-5">
        <div class="row">
            <div class="col-lg-3">
                <SidebarSettings/>
            </div>
            <div class="col-lg-9">
        <div class="content shadow ml0-sm-down mt32-sm-down">
            <div class="panel">
                <div class="mb32">
                    <h1 class="text-bold mb8">{{$t('accountSettings')}}</h1>
                    <small class="text-muted mb32">Set up language, currency and feature preferences.</small>
                </div>
                <div class="mb24">
                    <h3 class="mt32">Language</h3>
                    <hr>
                    
                </div>
            </div>
        </div>
    </div>
    </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from './Footer.vue';
import SidebarSettings from './SidebarSettings.vue';
export default {
  name: 'CloudhashFrontendAccountSettings',
  components: { Footer, SidebarSettings },
  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>
<style scoped>
h1 {
    font-size: 24px;
    font-weight: 700;
}

.mb8 {
    margin-bottom: 8px;
}

.text-muted {
    color: #959595;
}

small {
    font-size: 12px;
    line-height: 1.42;
}

.mb24 {
    margin-bottom: 24px;
}

hr {
    border: 0;
    border-bottom: 1px solid #EEE;
}

h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
</style>