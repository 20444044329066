<template>
  <div>

    <div class="toast-container position-fixed top-50 start-50 translate-middle">
          <div class="toast align-items-center border-0 fade" :class="{'show': showToast ,'text-bg-danger': showError }" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
              <div class="toast-body">
                <p class="text-center">{{message}}</p>
              </div>
              <button type="button" @click="closeToast" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
          </div>
      </div>

      <div class="toast-container position-fixed top-50 start-50 translate-middle">
          <div class="toast align-items-center border-0 fade" :class="{'show': showToastSc ,'text-bg-success': showSuccess }" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
              <div class="toast-body">
                <p class="text-center">{{message}}</p>
              </div>
              <button type="button" @click="closeToastSc" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
          </div>
      </div>

    <div class="container mb32 mt-5">
      <div class="row">
        <div class="col-lg-3">
          <Sidebar/>
        </div>
        <div class="col-lg-9">
          <div class="content">
            <div class="panel shadow">
              <div class="mb32">
                <h1 class="text-bold mb8">{{$t('withdraw')}}</h1>
                <small class="text-muted mb32"> {{$t('withdrawSmallDesc')}} </small>
              </div>
              <div class="container mt-3">
                <div class="row">
                  <div class="col-md-3">
                    <h3 class="mb16">{{ $t('overview') }}</h3>
                    <div class="box shadow">
                      <div class="total mined">
                        <small class="text-muted">{{$t('balanceUser')}} <span class="text-secondary pointer" @click="changeCurrency">{{currency}} <i class="fa fa-sort"></i></span></small></small>
                        <h1 class="pt8 pb16 balance">
                          <template v-if="currency === 'USD'">
                            {{balance}} {{currency}}
                          </template>
                          <template v-else>
                            <span class="fs16">{{balanceBTC}} {{currency}}</span>
                          </template>
                        </h1>
                      </div>
                      <div class="total mined">
                        <small class="text-muted">{{$t('totalPayout')}}</small>
                        <template v-if="showSkeleton">
                          <h1 class="pt8 pb16 balance skeleton-balance">
                              <div class="skeleton-left">
                                  <div class="line h25 w50 m10"></div>
                              </div>
                          </h1>
                        </template>
                        <template v-else>
                          <h1 class="pt8 pb16 balance">
                        <template v-if="currency === 'USD'">
                          {{withdrawsAmount}} {{currency}}
                        </template>
                        <template v-else>
                          <span class="fs16">{{withdrawsAmountBtc}} {{currency}}</span>
                        </template>
                      </h1>
                      </template>
                      </div>
                      <div class="total">
                        <small class="text-muted">{{$t('quantityWithdrawals')}}</small>
                        <template v-if="showSkeleton">
                          <h1 class="pt8 pb16 balance skeleton-balance">
                              <div class="skeleton-left">
                                  <div class="line h25 w50 m10"></div>
                              </div>
                          </h1>
                        </template>
                        <template v-else>
                          <h1 class="pt8 pb16 balance">{{withdrawsCount}}</h1>
                        </template>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-9">
                    <h3 class="mb16">{{$t('withdraw')}}</h3>
                    <hr>
                    <div class="field-wrap input-undefined relative mb-3">
                      <div class="label-row">
                        <label>{{$t('amount')}} </label>
                      </div>
                      <div class="input-group2 large">
                        <div class="input-group-prepend">
                          <span class="">USD</span>
                        </div>
                        <input type="text" v-model="inputAmount" @keypress="restrictToNumbersAndDecimal" class="form-control" maxlength="">
                      </div>
                        <div class="use-max">
                          <button class="btn primary small outline" @click="useMax">
                            <span>{{$t('useMaxBtn')}}</span>
                          </button>
                        </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-sm-6">
                        <small class="text-muted">{{$t('minimum')}}</small>
                      </div>
                      <div class="col-sm-6 text-right">
                        <template v-if="showSkeleton">
                          <h1 class="pt8 pb16 balance skeleton-balance">
                              <div class="skeleton-left">
                                  <div class="line h25 w50 m10"></div>
                              </div>
                          </h1>
                        </template>
                        <template v-else>
                          <small class="text-muted">$ {{minimum.toFixed(2)}} (≈ {{formatedValue}} BTC)</small>
                        </template>

                      </div>
                    </div>
                    <div class="field-wrap input-undefined relative mt-3">
                      <div class="label-row">
                        <label>{{$t('btcWallet')}} </label>
                      </div>
                      <div class="input-group2 large">
                        <input type="text" class="form-control" 
                        v-model="bitcoinAddress">
                      </div>
                      <small v-if="!isValidBitcoinAddress" class="text-danger"> {{$t('walletValidation')}}</small>
                    </div>
                    

                    <p class="mt-3 text-muted"> {{$t('withdrawDisclaimer')}} </p>
                    <div class="row mt24">
                      <button class="btn primary normal flex__fill-gap" @click="withdraw" :disabled="isSubmitting">
                        <span v-if="!isSubmitting">{{$t('proceedBtn')}}</span>
                        <span v-else>
                          <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                          {{ $t('loggingIn') }}...
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col mt-5">
          <h3>{{$t('yourPayouts')}}</h3>
          <div class="table-wrap relative shadow">
            <div class="table-responsive table-container">
              <table class="table-striped nh padding-normal" v-if="withdrawData.data.length > 0">
                <thead>
                    <tr>
                        <th scope="col">{{$t('transaction')}}</th>
                        <th scope="col" class="text-right">{{$t('status')}}</th>
                        <th scope="col" class="text-right">{{$t('amount')}}</th>
                        <th scope="col" class="text-right">{{$t('dateTime')}}</th>
                    </tr>
                </thead>
                <tbody>
                  <tr v-for="(withdraw, index) in withdrawData.data" :key="index">
                    <td>
                      <template v-if="withdraw.processed === 0 || withdraw.processed === 2">
                        <span class="text-primary">{{$t('pending')}}</span>
                      </template>
                    <template v-else-if="withdraw.processed === 3">
                      <span class="text-warning">In queue</span>
                    </template>
                      <template v-else-if="withdraw.processed === 1">
                        <a :href="'https://www.blockchain.com/explorer/transactions/btc/' + withdraw.transaction_id" target="_blank">
                              {{ withdraw.transaction_id.slice(0, 20) }}...
                          </a>
                      </template>
                    </td>
                    <td class="text-right">
                        <span v-if="withdraw.processed === 0" class="text-primary">{{$t('pending')}}</span>
                        <span v-else-if="withdraw.processed === 1" class="text-success">{{$t('completed')}}</span>
                        <span v-else-if="withdraw.processed === 2" class="text-warning">{{$t('processing')}}</span>
                        <span v-else-if="withdraw.processed === 3" class="text-warning">In queue</span>
                    </td>
                    <td class="text-right">{{ withdraw.amount }} <span class="text-muted">BTC</span> ~ {{parseFloat(withdraw.usd).toFixed(2)}} <span class="text-muted">USD</span></td>
                    <td class="text-right">{{ formatDate(withdraw.created_at) }}</td>
                  </tr>
                </tbody>
              </table>

              <div v-else class="d-flex justify-content-center mt-4">
                  <p>{{$t('noData')}}</p>
              </div>

              <div class="overlay loader" v-if="isLoadingTable">
                <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
              </div>


            </div>
          </div>

          <vue-pagination v-if="withdrawData.data.length > 0"  :pagination="withdrawData"
                                @paginate="getWithdraws()"
                                :offset="4">
                </vue-pagination>



        </div>

      </div>

            </div><!-- panel -->
          </div>
      </div>

      
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from './Footer.vue';
import Sidebar from './Sidebar.vue';
import axios from 'axios';
import VuePagination from '../components/Pagination.vue';
import moment from 'moment';
import { validate, getAddressInfo } from 'bitcoin-address-validation';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'CloudhashFrontendWithdraw',
  components: { Footer, Sidebar, VuePagination },
  data() {
    return {
      currency: 'USD',
      isLoadingTable: false,
      showSkeleton: false,
      withdrawData: {
        data: [],
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1
      },
      offset: 4,
      minimum: 10,
      isValidBitcoinAddress: true,
      estimateFee: 0,
      bitcoinAddress: '',
      btcPrice: 0,
      btcValue: 0,
      balanceBTC: 0,
      inputAmount: 0,
      withdrawsCount: 0,
      withdrawsAmountBtc: 0,
      withdrawsAmount: 0,
      isSubmitting: false,
      showMessage: false,
      message: '',
      showToast: false,
      showError: false,
      showSuccess: false,
      showToastSc: false,
    };
  },

  mounted() {
    this.getBtcPrice();
    this.getWithdraws();
    this.getWithdrawsData();
    this.$store.dispatch('user/fetchSaldo');
  },
  computed: {
    formatedValue() {
      return this.btcValue.toFixed(8);
    },
    ...mapGetters({
            balance: 'user/balance'
        }),
  },
  methods: {
    calculateWithdraw(){
      if (this.btcPrice) {
          this.btcValue = this.minimum / this.btcPrice;
      }
    },
    withdraw() {
        // Verificar la conexión con el servidor
        if (!navigator.onLine) {
            // Mostrar un mensaje de error si no hay conexión a internet
            this.showToast = true;
            this.showError = true;
            this.message = 'No internet connection available. Please try again later.';
            setTimeout(() => {
                this.showToast = false;
                this.showError = false;
                this.message = '';
            }, 5000);
            return;
        }
        // Validar el monto ingresado
        const usd = parseFloat(this.inputAmount);
        

        // Verificar si el valor ingresado es un número válido y mayor que cero
        if (isNaN(usd) || usd <= 0) {
            // Mostrar un mensaje de error o realizar alguna acción
            this.showToast = true;
            this.showError = true;
            this.message = 'Please enter a valid amount greater than zero';
            setTimeout(() => {
                this.showToast = false;
                this.showError = false;
                this.message = '';
            }, 5000);
            return;
        }

        // Validar la dirección de Bitcoin
        if (!this.isValidBitcoinAddress) {
          // Mostrar un mensaje de error o realizar alguna acción
            this.showToast = true;
            this.showError = true;
            this.message = 'Please enter a valid Bitcoin address.';
            setTimeout(() => {
                this.showToast = false;
                this.showError = false;
                this.message = '';
            }, 5000);
            return;
        }

        // Verificar si el monto a retirar es mayor que el saldo disponible
        if (usd > this.balance) {
            // Mostrar un mensaje de error si el monto es mayor que el saldo
            this.showToast = true;
            this.showError = true;
            this.message = 'Insufficient balance';
            setTimeout(() => {
                this.showToast = false;
                this.showError = false;
                this.message = '';
            }, 5000);
            return;
        }

        const amountBtc = (usd / this.btcPrice).toFixed(8);

        // Crear un objeto con los datos del retiro
        const withdrawData = {
            usd: usd,
            amount: amountBtc,
            bitcoinAddress: this.bitcoinAddress
        };

        // Realizar la solicitud HTTP al backend para procesar el retiro
        this.isSubmitting = true; // Mostrar spinner de carga
        axios.post(process.env.VUE_APP_API_URL + 'withdraw', withdrawData)
        .then(response => {
            // Manejar la respuesta del backend
            this.showToastSc = true;
            this.showSuccess = true;
            this.message = response.data.message;
            this.isSubmitting = false; // Ocultar spinner de carga
            // Actualizar información del usuario
            this.$store.dispatch('user/fetchSaldo');
            this.inputAmount = 0; // Limpiar el campo de monto
            this.bitcoinAddress = ''; // Limpiar el campo de dirección de Bitcoin
            this.getBtcPrice();
            this.getWithdraws();
            this.getWithdrawsData();
            setTimeout(() => {
                this.showToastSc = false;
                this.showSuccess = false;
                this.message = '';
            }, 5000);
        })
        .catch(error => {
            // Manejar errores de la solicitud
            if (error.response.status === 422) {
              if (typeof error.response.data.error === 'string') {
                this.message = error.response.data.error;
                this.showToast = true;
                this.showError = true;
                this.isSubmitting = false;
                setTimeout(()=>{
                  this.showToast = false;
                  this.showError = false;
                  this.message = '';
                },5000);
              } 
            }
            
        });
    },
    useMax() {
      if (confirm('Are you sure you want to withdraw your entire balance?')) {
        this.inputAmount =  this.balance;
        //this.reinvestCalculator();
      }
    },
    closeToastSc() {
      this.showToastSc = false;
      this.showSuccess = false;
    },
    closeToast() {
      this.showToast = false;
      this.showError = false;
    },
    getWithdrawsData() {
      this.showSkeleton = true;
      axios.get(process.env.VUE_APP_API_URL+'getWithdrawsData')
      .then((res)=>{
        this.withdrawsCount = res.data.withdrawsCount;
        this.withdrawsAmount = res.data.withdrawsAmount.toFixed(2);
        this.showSkeleton = false;
      })
      .catch((error)=>{
        console.log('error getting Data',error.response.error);
        this.showSkeleton = false;
      })
    },
    changeCurrency() {
      this.currency = this.currency === 'USD' ? 'BTC' : 'USD';

      if (this.currency === 'BTC') {
          // Convertir el saldo a BTC si la moneda es BTC
          this.balanceBTC = (this.balance / this.btcPrice).toFixed(8);
          this.withdrawsAmountBtc = (this.withdrawsAmount / this.btcPrice).toFixed(8);
      } 
    },
    restrictToNumbersAndDecimal(event) {
        // Permitir solo números y un punto decimal
        const charCode = event.keyCode;
        const inputValue = event.target.value;
        // 46 es el código para el punto
        if ((charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) ||
            (inputValue.indexOf('.') !== -1 && charCode === 46)) {
            event.preventDefault();
        }
    },
    getBtcPrice() {
      axios.get(process.env.VUE_APP_API_URL+'getBtcPrice')
      .then((res)=>{
        this.btcPrice = res.data.price;
        this.calculateWithdraw();
      })
      .catch((error)=>{
        console.log('error getting BTC Price',error.response.error);
      })
    },
    formatDate(date) {
        return moment(date).format('DD MMM YYYY HH:mm');
    },
    getWithdraws() {
      this.isLoadingTable = true;
      axios.get(process.env.VUE_APP_API_URL+`getWithdraws?page=${this.withdrawData.current_page}`)
      .then((res)=>{
        this.withdrawData = res.data;
        this.isLoadingTable = false;
      })
      .catch((error)=>{
        console.log('error getting Data',error.response.error);
        this.isLoadingTable = false;
      })
    }
  },
  watch: {
    bitcoinAddress(newValue) {
      // Expresión regular para validar la dirección de Bitcoin
      var valid = validate(newValue);
        if(valid)
          this.isValidBitcoinAddress = true;
        else
          this.isValidBitcoinAddress = false;
    }
  }
};
</script>
<style scoped>
.mined {
  background-color: rgb(25, 52, 93);
  color: white;
  border-radius: 4px;
}

.btn.primary:hover:not(.disabled) {
    background: linear-gradient(90deg,#19345D,#19345D);
    box-shadow: 0 24px 12px -19px #19345D8f
}

.btn.primary.normal {
    box-shadow: 0 12px 4px -10px #19345D66;
}

.btn.fluid {
    width: 100%;
}

.btn.normal {
    padding: 13px 24px 12px;
    font-size: 12px;
    letter-spacing: .5px;
}

.btn.primary {
    background: linear-gradient(90deg,#19345D,#19344D);
    color: #fff;
    box-shadow: 0 20px 4px -18px #19345D52;
}

.btn {
    border-radius: 4px;
    border: 0;
    padding: 10px 12px 9px;
    transition: box-shadow .2s ease,border-radius .2s ease,border-radius .2s ease,background-color .2s ease,color .2s ease;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn.secondary {
    background: #fff;
    color: #3a3939;
    box-shadow: 0 1px 4px #23232329
}

.btn.secondary:hover:not(.disabled) {
    box-shadow: 0 4px 12px #23232329
}

.input-group.copy-on-hover .copy {
    display: none
}

.input-group.copy-on-hover:hover .copy {
    display: block
}

.input-group input:disabled[type=copy], .input-group input:read-only[type=copy] {
    cursor: default;
}

.input-group.auth input, .input-group.medium input {
    padding: 18px 14px;
}

.input-group input:disabled, .input-group input:read-only {
    box-shadow: none;
    border: 1px solid #EEE;
}

.input-group .copy {
    position: absolute;
    right: 8px;
    top: 8px;
}

.btn.small {
    padding: 6px 23px;
    font-size: 10px;
    letter-spacing: .4px;
}

.input-group.medium .copy {
    top: 16px;
    right: 16px;
}

.copy-feedback {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    line-height: 20px;
    padding: 13px 0;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .05em;
    text-align: center;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    border: 1px solid rgba(211, 224, 243, 0.5);
    color: #00ba94;
}

.copy-address {
    border: none;
    color: #495463;
    line-height: 20px;
    padding: 12px 50px 12px 40px;
    border-radius: 4px;
    border: 1px solid rgba(211, 224, 243, 0.5);
    width: 100%;
    background: #fff;
}

.table {
    box-shadow: 0 1px 4px #23232329;
    margin: 0 auto 80px;
}

.table-container {
    position: relative;
    min-height: 90px;
    overflow-x: auto;
}

table.nh {
    width: 100%;
    border: none;
}

table.nh thead {
    background: #fff;
}

table.nh th:first-child {
    padding-left: 32px;
}

.table th {
    color: #959595;
}

table.nh th {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.6;
    font-weight: 400;
    padding: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

table.nh tbody tr:nth-child(odd) {
    background-color: #fcfcfc;
}

table.nh tbody tr:first-child {
    box-shadow: inset 1px 4px 6px -5px #23232373;
}

table.nh tbody tr {
    background-color: #fff;
}

table.nh tbody tr td:first-child {
    padding-left: 32px;
}

table.nh tbody tr td {
    padding: 12px 16px;
    border-bottom: 1px solid #EEE;
    font-size: 12px;
    line-height: 1.33;
    color: #3a3939;
}

.relative {
    position: relative;
}

.field-wrap .label-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    font-size: 12px;
}

.field-wrap .label-row label {
    color: #959595;
    display: block;
    flex: 1;
}

.input-group2 {
    position: relative;
}

.input-group2.large .input-group-prepend {
    width: 76px;
    line-height: 72px;
    height: 72px;
    color: #959595;
    font-size: 24px;
}

.input-group2 .input-group-prepend {
    position: absolute;
    top: 1px;
    min-width: 44px;
    border-right: 1px solid #EEE;
    z-index: 1;
    text-align: center;
    line-height: 38px;
    height: 37px;
    color: #cecdcd;
}

.input-group2.large .input-group-prepend~input {
    padding-left: 92px;
}

.input-group2.large input {
    height: 72px;
    font-size: 32px;
}

.input-group2.large .input-group-prepend span.smaller {
    font-size: 20px;
    position: relative;
    top: -2px
}

.input-group2.large .input-group-prepend~input {
    padding-left: 92px
}

.use-max {
    position: absolute;
    top: 48px;
    right: 16px;
}

.btn.outline.primary {
    color: rgb(25, 52, 93);
    box-shadow: inset 0 0 0 1px rgb(25, 52, 93)!important;
}

.input-group2 input:hover {
    box-shadow: 0 4px 12px #23232329
}

.input-group2 input:focus {
    outline: none;
    border: 1px solid #19345D;
    box-shadow: 0 5px 10px #19345D1a
}

.input-group2 input:-moz-read-only {
    box-shadow: none;
    border: 1px solid #EEE
}

.input-group2 input:disabled,.input-group input:read-only {
    box-shadow: none;
    border: 1px solid #EEE
}

.use-max button {
    width: 60px;
    margin-left: 8px;
    padding: 6px 0 !important;
}

.btn.small {
    padding: 6px 23px;
    font-size: 10px;
    letter-spacing: .4px;
}

.btn.outline.primary:hover,.btn.outline.primary:active {
    background: rgb(25, 52, 93);
    color: #fff;
    box-shadow: inset 0 0 0 1px rgb(25, 52, 93)!important
}

.btn {
    border-radius: 4px;
    border: 0;
    padding: 10px 12px 9px;
    transition: box-shadow .2s ease,border-radius .2s ease,border-radius .2s ease,background-color .2s ease,color .2s ease;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
}

div:focus label:before {
    border-color: #19345D;
    box-shadow: 0 5px 10px #19345D1a
}

div input+label:after {
    content: none
}

.field-wrap+.field-wrap {
    margin-top: 24px
}

.field-wrap .label-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    font-size: 12px
}

.field-wrap .label-row label {
    color: #959595;
    display: block;
    flex: 1
}

.field-wrap.dark label {
    color: #ffffff80
}

.btn.outline {
    background: transparent;
}

.text-secondary {
    color: #fba342 !important;
}

.fs16 {
  font-size: 16px;
}

.skeleton-balance .line {
     height: 12px;
     margin-top: 6px;
     margin-bottom: 6px;
     border-radius: 4px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation:  shimmer 1.5s infinite linear;
}

.skeleton-left {
    flex:1;
}

.skeleton-balance {
     padding:15px 15px 15px 0;
     max-width: 300px;
     width: 135px;
     height: 10px;
     color: transparent; /* Texto transparente para simular un esqueleto */
     margin-top: 5px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 4px;
}
</style>