export default {
    en: {
        welcome: 'Welcome to CloudHash!',
        dashboard: 'Dashboard',
        profile: 'Profile',
        settings: 'Settings',
        signUp: 'Sign Up',
        home: 'Home',
        pricing: 'Pricing',
        insights: 'Insights',
        about: 'About',
        faqs: 'FAQs',
        affiliate: 'Affiliate',
        login: 'Login',
        email: 'Email',
        username: 'Username',
        password: 'Password',
        signUpSubtitle: 'Sign up today and get 1000 GH/s mining power for free. Join the future of mining with CloudHash!',
        switchToLogin: 'Switch to Login',
        switchToSignUp: 'Switch to Sign Up'
        // otros mensajes en inglés...
    },
    es: {
        welcome: '¡Bienvenido a CloudHash!',
        dashboard: 'Tablero',
        profile: 'Perfil',
        settings: 'Ajustes',
        signUp: 'Registrarse',
        home: 'Inicio',
        pricing: 'Precios',
        insights: 'Información',
        about: 'Acerca de',
        faqs: 'Preguntas frecuentes',
        affiliate: 'Afiliados',
        login: 'Iniciar sesión',
        email: 'Correo electrónico',
        username: 'Nombre de usuario',
        password: 'Contraseña',
        signUpSubtitle: '¡Regístrate hoy y obtén 1000 GH/s de potencia de minado gratis. Únete al futuro de la minería con CloudHash!',
        switchToLogin: 'Cambiar a Iniciar sesión',
        switchToSignUp: 'Cambiar a Registrarse'
        // otros mensajes en español...
    },
    fr: {
        welcome: 'Bienvenue sur CloudHash!',
        dashboard: 'Tableau de bord',
        profile: 'Profil',
        settings: 'Paramètres',
        signUp: 'S\'inscrire',
        home: 'Accueil',
        pricing: 'Tarification',
        insights: 'Perspectives',
        about: 'À propos',
        faqs: 'FAQ',
        affiliate: 'Affilié',
        login: 'Connexion',
        email: 'Email',
        username: 'Nom d\'utilisateur',
        password: 'Mot de passe',
        signUpSubtitle: 'Inscrivez-vous aujourd\'hui et obtenez gratuitement une puissance de minage de 1000 GH/s. Rejoignez l\'avenir du minage avec CloudHash!',
        switchToLogin: 'Changer pour Se connecter',
        switchToSignUp: 'Changer pour S\'inscrire'
        // otros mensajes en francés...
    },
    ru: {
        welcome: 'Добро пожаловать на CloudHash!',
        dashboard: 'Панель',
        profile: 'Профиль',
        settings: 'Настройки',
        signUp: 'Зарегистрироваться',
        home: 'Главная',
        pricing: 'Ценообразование',
        insights: 'Инсайты',
        about: 'О нас',
        faqs: 'Часто задаваемые вопросы',
        affiliate: 'Партнер',
        email: 'Электронная почта',
        username: 'Имя пользователя',
        password: 'Пароль',
        signUpSubtitle: 'Зарегистрируйтесь сегодня и получите бесплатно 1000 GH/s мощности для майнинга. Присоединяйтесь к будущему майнинга с CloudHash!',
        switchToLogin: 'Переключиться на Вход',
        switchToSignUp: 'Переключиться на Регистрацию'
        // otros mensajes en ruso...
    },
    it: {
        welcome: 'Benvenuti su CloudHash!',
        dashboard: 'Dashboard',
        profile: 'Profilo',
        settings: 'Impostazioni',
        signUp: 'Iscriviti',
        home: 'Casa',
        pricing: 'Prezzi',
        insights: 'Approfondimenti',
        about: 'Circa',
        faqs: 'Domande frequenti',
        affiliate: 'Affiliato',
        email: 'Email',
        username: 'Nome utente',
        password: 'Password',
        signUpSubtitle: 'Registrati oggi e ottieni gratuitamente una potenza di mining di 1000 GH/s. Unisciti al futuro del mining con CloudHash!',
        switchToLogin: 'Passa a Login',
        switchToSignUp: 'Passa a Registrati'
        // otros mensajes en italiano...
    },
    de: {
        welcome: 'Willkommen bei CloudHash!',
        dashboard: 'Dashboard',
        profile: 'Profil',
        settings: 'Einstellungen',
        signUp: 'Registrieren',
        home: 'Startseite',
        pricing: 'Preise',
        insights: 'Einblicke',
        about: 'Über uns',
        faqs: 'FAQs',
        affiliate: 'Partnerprogramm',
        login: 'Anmelden',
        email: 'E-Mail',
        username: 'Benutzername',
        password: 'Passwort',
        signUpSubtitle: 'Registrieren Sie sich noch heute und erhalten Sie 1000 GH/s Mining-Leistung kostenlos. Seien Sie dabei und gestalten Sie die Zukunft des Minings mit CloudHash!',
        switchToLogin: 'Wechseln zu Anmeldung',
        switchToSignUp: 'Wechseln zu Registrieren'
        // otros mensajes en alemán...
    },
    pt: {
        welcome: 'Bem-vindo ao CloudHash!',
        dashboard: 'Dashboard',
        profile: 'Perfil',
        settings: 'Configurações',
        signUp: 'Registrar-se',
        home: 'Página Inicial',
        pricing: 'Preços',
        insights: 'Visão Geral',
        about: 'Sobre',
        faqs: 'Perguntas Frequentes',
        affiliate: 'Afiliados',
        login: 'Login',
        email: 'Email',
        username: 'Nome de usuário',
        password: 'Senha',
        signUpSubtitle: 'Registre-se hoje e ganhe 1000 GH/s de potência de mineração grátis. Junte-se ao futuro da mineração com CloudHash!',
        switchToLogin: 'Alternar para Login',
        switchToSignUp: 'Alternar para Inscrever-se'
        // otros mensajes en portugués...
    },
    zh: {
        welcome: '欢迎来到 CloudHash！',
        dashboard: '仪表盘',
        profile: '个人资料',
        settings: '设置',
        signUp: '注册',
        home: '主页',
        pricing: '定价',
        insights: '见解',
        about: '关于',
        faqs: '常见问题',
        affiliate: '联盟',
        login: '登录',
        email: '电子邮件',
        username: '用户名',
        password: '密码',
        signUpSubtitle: '今天注册，免费获得1000 GH/s的挖矿算力。用CloudHash开启挖矿的未来！',
        switchToLogin: '切换到登录',
        switchToSignUp: '切换到注册'
        // otros mensajes en chino...
    },
    ja: {
        welcome: 'CloudHashへようこそ！',
        dashboard: 'ダッシュボード',
        profile: 'プロフィール',
        settings: '設定',
        signUp: '登録',
        home: 'ホーム',
        pricing: '価格',
        insights: '洞察',
        about: 'について',
        faqs: 'よくある質問',
        affiliate: 'アフィリエイト',
        login: 'ログイン',
        email: 'メールアドレス',
        username: 'ユーザー名',
        password: 'パスワード',
        signUpSubtitle: '今日登録して、無料で1000 GH/sのマイニングパワーをゲットしよう。CloudHashでマイニングの未来に参加しよう！',
        switchToLogin: 'ログインに切り替える',
        switchToSignUp: 'サインアップに切り替える'
        // otros mensajes en japonés...
    },
    hi: {
        welcome: 'निम्बसहैश में आपका स्वागत है!',
        dashboard: 'डैशबोर्ड',
        profile: 'प्रोफ़ाइल',
        settings: 'सेटिंग्स',
        signUp: 'साइन अप',
        home: 'होम',
        pricing: 'मूल्य निर्धारण',
        insights: 'अंतरदृष्टि',
        about: 'के बारे में',
        faqs: 'पूछे जाने वाले प्रश्न',
        affiliate: 'सहयोगी',
        login: 'लॉग इन करें',
        email: 'ईमेल',
        username: 'उपयोगकर्ता नाम',
        password: 'पासवर्ड',
        signUpSubtitle: 'आज ही साइन अप करें और मुफ्त में 1000 जीएच/एस माइनिंग पावर प्राप्त करें। CloudHash के साथ माइनिंग के भविष्य में शामिल हों!',
        switchToLogin: 'लॉगिन पर स्विच करें',
        switchToSignUp: 'साइन अप पर स्विच करें'
        // otros mensajes en hindi...
    }
    // más idiomas aquí...
};