<template>

<div class="slider-element dark min-vh-25 min-vh-md-50 include-header py-5" style="background-color: #002BAE">
          

          <div class="slider-inner">
              <div class="vertical-middle text-center">
                <div class="container">
                  <div class="row align-items-center justify-content-center">
                      <div class="col-lg-6 col-md-6">
                        <h2 class="fw-bold text-white mb-2">{{$t('afiliados.title')}}</h2>
                        <p class="lead mb-0 fw-normal">{{$t('afiliados.description')}}</p>
                      </div>
                  </div>
                </div>
            </div>

  
      <div class="row justify-content-center mt-3">
        <div class="col-md-7 dotted-bg">
          
          <div class="toggle-wrap">

          <div class="toggle">
            <div class="toggle-header">
              <h3 class="toggle-title">{{$t('afiliados.introduction')}}</h3>
            </div>
            <div class="toggle-content" style="display: block;">
              <p>
                {{$t('afiliados.introductionContent')}} 
              </p>
            </div>
          </div>
          
          <div class="toggle">
            <h3 class="toggle-title">{{$t('afiliados.commissionPercentages')}}</h3>
            <div class="toggle-content" style="display: block;">
              <ul>
                <div v-html="$t('afiliados.commissionPercentagesContent')">
                </div>
              </ul>
            </div>
          </div>

          <div class="toggle">
            <h3 class="toggle-title">{{$t('afiliados.howItWorks')}}</h3>
            <div class="toggle-content" style="display: block;">
              <ul>
                <div v-html="$t('afiliados.howItWorksContent')">
                </div>
              </ul>
            </div>
          </div>

          <div class="toggle">
            <h3 class="toggle-title">{{$t('afiliados.example')}}</h3>
            <div class="toggle-content" style="display: block;">
              <ul>
                <div v-html="$t('afiliados.exampleContent')">
                </div>
              </ul>
            </div>
          </div>
          
          
        </div>
      </div>
    </div>
  </div>
  <Footer/>
</div>
</template>

<script>
import Footer from './Footer.vue';
export default {
  name: 'CloudhashFrontendAffiliate',
  components: { Footer },
  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>
<style scoped>

</style>