<template>
  <div>
    <div class="container mb32 mt-5">
      <div class="row">
        <div class="col-lg-3">
          <SidebarSettings/>
        </div>
        <div class="col-lg-9">
        <div class="content shadow ml32 ml0-sm-down mt32-sm-down">
        </div>      
        </div>
    </div>
  </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from './Footer.vue';
import SidebarSettings from './SidebarSettings.vue';
export default {
  name: 'CloudhashFrontendNotification',
  components: { Footer, SidebarSettings },
  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>