<template>
    <div>
  
      <!-- <BitcoinPrice key="bitcoin-price" /> -->
  
      
  
      <div class="section m-0 " style="padding: 40px 0; background-color: rgb(25, 52, 93);">
        <!-- <img class="img-map position-absolute" src="@/assets/images/map-light.svg" alt="Image Map" style="opacity: 0.05; margin-top: 0px;"> -->
        
          <div class="cloud-wrap">
            <div class="c1"><div class="cloud"></div></div>
            <div class="c2"><div class="cloud"></div></div>
            <div class="c3"><div class="cloud"></div></div>
            <div class="c4"><div class="cloud"></div></div>
            <div class="c5"><div class="cloud"></div></div>
          </div>
  
          <div class="toast-container start-50 translate-middle-x">
            <div class="toast align-items-center border-0 fade" :class="{'show': showToast ,'text-bg-danger': showError }" role="alert" aria-live="assertive" aria-atomic="true">
              <div class="d-flex">
                <div class="toast-body">
                  <template v-if="errors">
                    <ul>
                      <template v-for="(fieldErrors, field) in errors">
                        <li v-for="error in fieldErrors" :key="error">{{ field }}: {{ error }}</li>
                      </template>
                    </ul>
                </template>
                <template v-else-if="message !== ''">
                  <p>{{message}}</p>
                </template>
                </div>
                <button type="button" @click="closeToast" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
              </div>
            </div>
        </div>
  
      <div class="container">
        
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="text-center dark mb-5">
                <h2 class="fw-bold mb-2 text-white">{{ isSignUp ? $t('signUp') : $t('login') }}</h2>
                <p class="fw-normal mb-2 text-white lead">{{ isSignUp ? $t('signUpSubtitle') : $t('loginDescription') }}</p>
                
            </div>
            
            <div class="card bg-white shadow-lg border-0" >
              <div class="card-body">
                <form @submit.prevent="register">
                  
                  <!-- <div class="form-floating input-group mb-3">
                    <input type="email" class="form-control" id="email" v-model="formData.email" required>
                    <label for="email">{{ $t('email') }}</label>
                  </div> -->

                  <div class="field-wrap input- mb24">
                    <div class="input-group2 auth">
                      <div class="input-group-prepend">
                        <i class="fa fa-user"></i>
                      </div>
                      <input autocomplete="off" type="email" @focus="isEmailFocused = true" @blur="isEmailFocused = false" name="email" :class="{ 'up': isEmailFocused || formData.email  }" class="form-control" v-model="formData.email" placeholder="" maxlength="" required>
                      <span class="floating-placeholder prepend" :class="{ 'up': isEmailFocused || formData.email  }">{{ $t('email') }}</span>
                    </div>
                  </div>

                  <!-- <div class="form-floating input-group mb-3">
                    <input type="text" class="form-control" id="username" v-model="formData.username" required>
                    <label for="username">{{ $t('username') }}</label>
                  </div> -->

                  <div class="field-wrap input- mb24">
                    <div class="input-group2 auth">
                      <div class="input-group-prepend">
                        <i class="fa fa-user"></i>
                      </div>
                      <input autocomplete="off" type="text" @focus="isUsernameFocused = true" @blur="isUsernameFocused = false" name="username" :class="{ 'up': isUsernameFocused || formData.username  }" class="form-control" v-model="formData.username" placeholder="" maxlength="" required>
                      <span class="floating-placeholder prepend" :class="{ 'up': isUsernameFocused || formData.username  }">{{ $t('username') }}</span>
                    </div>
                  </div>

                  <div class="field-wrap input- mb24">
                    <div class="input-group2 auth">
                      <div class="input-group-prepend">
                        <i class="fa fa-lock"></i>
                      </div>
                      <input autocomplete="current-password" :type="showPassword ? 'text' : 'password'" 
                      @focus="isPasswordFocused = true" @blur="isPasswordFocused = false"
                      :class="{ 'up': isPasswordFocused || formData.password  }"
                      name="password" class="form-control" 
                      @input="validatePassword"
                      v-model="formData.password" placeholder="" maxlength="">
                      <span class="floating-placeholder prepend" :class="{ 'up': isPasswordFocused || formData.password  }">{{ $t('password') }}</span>
                      <div class="show-password" :class="showPassword ? 'show' : ''" @click="togglePasswordVisibility">
                        <span>{{!showPassword ? $t('showPw') : $t('hidePw')}}</span>
                        <i class="fa" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="form-floating input-group mb-3">
                    <input 
                    :type="showPassword ? 'text' : 'password'" 
                    class="form-control" 
                    id="password" 
                    @input="validatePassword"
                    v-model="formData.password" required>
                    <label for="password">{{ $t('password') }}</label>
                    <span class="input-group-text pointer" @click="togglePasswordVisibility">
                      <i class="fa" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                    </span>
                  </div> -->

                  <div class="mb24">
                    <div v-for="(req, index) in requirements" :key="index" class="col">
                      <span :class="req.valid ? 'text-success' : 'text-disabled'">
                        <small v-if="req.valid"><i class="far fa-smile"></i> {{ $t(req.text) }}</small>
                        <small v-else><i class="far fa-circle"></i> {{ $t(req.text) }}</small>
                      </span>
                    </div>
                  </div>
                  <div class="col-12">
                    <button type="submit" class="btn w-100 text-white btn-primary rounded-3 py-3 fw-semibold text-uppercase mt-2" :disabled="isSubmitting">
                      <span v-if="!isSubmitting">{{ $t('signUp') }}</span>
                      <span v-else>
                          <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                          {{ $t('loggingIn') }}...
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            
            
              
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </template>
  
  <script>
  import { mapActions } from 'vuex';
  import axios from 'axios';
  export default {
    name: 'CloudhashFrontendReferralRegister',
    data() {
      return {
        isPasswordFocused: false,
        isPasswordFocused2: false,
        isEmailFocused: false,
        isEmailFocused2: false,
        isUsernameFocused: false,
        loginForm:{
          email: '',
          password: '',
        },
        formData: {
          email: '',
          username: '',
          password: '',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        errors: {},
        isSignUp: true, // Por defecto, mostramos el formulario de registro
        showPassword: false,
        showPassword2: false,
        isSubmitting: false,
        message: '',
        showToast: false,
        showError: false,
        requirements: [
          { text: 'validations.text1', valid: false },
          { text: 'validations.text2', valid: false },
          { text: 'validations.text3', valid: false },
          { text: 'validations.text4', valid: false },
          { text: 'validations.text5', valid: false }
        ]
      };
    },
    created() {
      
    },
  
    mounted() {
      
    },
  
    methods: {
      ...mapActions({
        signIn: 'user/signIn'
      }),
      validatePassword() {
        const password = this.formData.password;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;
        const numberRegex = /[0-9]/;
        const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        
        this.requirements[0].valid = lowercaseRegex.test(password);
        this.requirements[1].valid = uppercaseRegex.test(password);
        this.requirements[2].valid = password.length >= 8;
        this.requirements[3].valid = numberRegex.test(password);
        this.requirements[4].valid = specialCharRegex.test(password);
      },
      togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
      },
      togglePasswordVisibility2() {
        this.showPassword2 = !this.showPassword2;
      },
      toggleFormMode() {
          this.isSignUp = !this.isSignUp;
      },
      register() {
        // Evita el envío si el formulario ya se está enviando
        if (this.isSubmitting) return;
  
        // Llama a la función de validación de contraseña
        this.validatePassword();
  
        // Verifica si algún requisito de la contraseña no se cumple
        const isPasswordValid = this.requirements.every(req => req.valid);
        if (!isPasswordValid) {
          this.message = 'The password does not meet the requirements.';
          this.showToast = true;
          this.showError = true;
          return;
        }
  
        // Inicia la animación del botón y deshabilítalo
        this.isSubmitting = true;
        this.errors = {};
  
        axios.post(process.env.VUE_APP_API_URL + 'register', {
          email: this.formData.email,
          username: this.formData.username,
          password: this.formData.password,
          timezone: this.formData.timezone,
          referralCode: this.$route.query.ref
        })
        .then(response => {
            // Limpia los campos después de enviar
            this.email = '';
            this.username = '';
            this.password = '';
            // Si la solicitud fue exitosa, puedes redirigir al usuario a la página de inicio de sesión
            this.$router.push('/login');
          })
        .catch(error => {
            //console.log(error.response.data.error);
          // Si la solicitud falla, maneja los errores
          if (error.response.status === 422) {
            if (typeof error.response.data.error === 'string') {
              this.message = error.response.data.error;
              this.showToast = true;
              this.showError = true;
              this.isSubmitting = false;
              setTimeout(()=>{
                this.showToast = false;
                this.showError = false;
                this.message = '';
              },5000);
            } else {
              this.errors = error.response.data.error;
              this.showToast = true;
              this.showError = true;
              this.isSubmitting = false;
              setTimeout(()=>{
                this.showToast = false;
                this.showError = false;
                this.errors = {};
              },5000);
            }
            
          } else {
            // Maneja otros tipos de errores
            console.error(error);
          }
        });
      },
      closeToast() {
        this.showToast = false;
        this.showError = false;
      }
    },
  };
  </script>
  <style scoped>
  .btn-alternate {
    background-color: transparent;
    color: #3883dd; /* Color azul */
    border: none;
    font-weight: bold;
    transition: color 0.3s;
  }
  
  .btn-alternate:hover {
    color: #007bff; /* Color azul más oscuro al pasar el mouse */
  }
  
  .pointer {
    cursor: pointer
  }
  
  .input-group-text {
      background-color: transparent;
  }
  
  .toast-body ul {
      list-style-type: none; /* Quita el marcador de lista */
      padding: 0; /* Elimina el relleno predeterminado de la lista */
  }
  
  .mb24 {
      margin-bottom: 24px;
  }
  
  .requirements {
      padding: 0 15px;
  }
  
  .text-muted2, .text-disabled {
      color: #cecdcd;
  }
  
  small {
      font-size: 12px;
      line-height: 1.42;
  }
  
  .text-success {
      color: #68b641;
  }

  .mb24 {
    margin-bottom: 24px;
}

.input-group2 {
    position: relative;
}

.input-group2.auth .input-group-prepend, .input-group.medium .input-group-prepend {
    line-height: 54px;
    height: 53px;
}

.input-group2 .input-group-prepend {
    position: absolute;
    top: 3px;
    min-width: 44px;
    border-right: 1px solid #EEE;
    z-index: 1;
    text-align: center;
    line-height: 38px;
    height: 37px;
    color: #cecdcd;
}

.input-group2.auth .input-group-prepend~input, .input-group.medium .input-group-prepend~input {
    padding-left: 56px;
}

.input-group2.auth input, .input-group.medium input {
    padding: 18px 14px;
}

.input-group2.auth span.floating-placeholder, .input-group.medium span.floating-placeholder {
    position: absolute;
    pointer-events: none;
    left: 19px;
    top: 19px;
    transition: .2s ease all;
    color: #cecdcd;
    font-size: 14px;
}

.input-group2.auth span.floating-placeholder.prepend,.input-group.medium span.floating-placeholder.prepend {
    left: 56px!important
}

.input-group2.auth span.floating-placeholder.up,.input-group.medium span.floating-placeholder.up {
    top: 11px;
    left: 15px;
    font-size: 10px;
    color: #959595
}

.input-group2.auth input.up,.input-group.medium input.up {
    padding-top: 25px;
    padding-bottom: 11px
}

.input-group2 input:focus {
    outline: none;
    border: 1px solid #19345D;
    box-shadow: 0 5px 10px #19345D1a
}

.input-group2.auth .show-password, .input-group.medium .show-password {
    line-height: 54px;
}

.input-group2.auth .show-password {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 60px;
    z-index: 1;
    color: #19345D;
    cursor: pointer;
}

.input-group2.auth .show-password span {
    margin-right: 7px;
    font-size: 12px;
}

.input-group-text {
    background-color: transparent;
}

.toast-body ul {
    list-style-type: none; /* Quita el marcador de lista */
    padding: 0; /* Elimina el relleno predeterminado de la lista */
}

.requirements {
    padding: 0 15px;
}

.text-muted2, .text-disabled {
    color: #cecdcd;
}

small {
    font-size: 12px;
    line-height: 1.42;
}

.text-success {
    color: #68b641;
}

.input-group2 .show-password.show {
    color: #cecdcd;
    right: 20px
}
  </style>