<template>
  <div id="content">


      <!-- <div class="popover fade" :class="{ 'show': isPopoverOpen }" :style="{ top: popoverTop, left: popoverLeft }">
      <div class="arrow"></div>
      <div class="popover-body">
        Your popover content
      </div>
    </div> -->



          <Sidebar/>


        <div class="col-lg-9">
      <div class="dashboard-container">
        <div class="mt-3">

          <div class="enable2fa-banner shadow ml32 mb24" v-if="!user?.user?.google2fa_enable">
            <div class="row">
              <div class="col-sm-8">
                <div class="text-bold">{{ $t('staySafe') }}</div>
                <div class="text text-muted">{{ $t('staySafeDesc') }}</div>
              </div>
              <div class="col-sm-4 text-right">
                <router-link to="/my/settings/security" class="btn primary normal">{{ $t('enable2FA')}}</router-link>
              </div>
            </div>
          </div>

          
          <div class="row mb16 ml32">
            <div class="col-md-12">
              <h2 class="inline-block">{{$t('miningStatus')}}</h2>
            </div>
            <hr>
            <div class="mb-3 justify-content-center">
              <div class="col-12">
                <!-- <div class="slider-bottom-box package gold"> -->
                  <!-- Contenido del paquete -->
                  <div class="row align-items-center">


                <div class="shared-packages-container mb32">
                  <div class="package-container normal">
                    <div class="package normal gold">
                      <div class="flex flex--align-vertical flex--justify-space-between flex--no-wrap head mb24">
                        <div class="package-name flex flex--align-vertical flex--no-wrap">
                          <!-- <img src="@/assets/images/Team-Gold-icon.png" alt="shared package icon" class="icon"> -->
                          <div class="name fs16 text-bold mr8">{{$t('miner')}}: {{ user?.user?.username || 'Guest' }}</div>
                        </div>
                        
                        
                          

                        <div class="pointer" >
                          <div class="countdown">
                            <div class="countdown-label fs14 text-bold flex flex--justify-center flex--align-bottom pb4"><template v-if="nextReward"><countdown :date="nextReward" /></template></div>
                          </div>
                          <div class="countdown-inner">
                          <VTooltip
                          :placement='placement'
                          :distance="24">
                            <div class="v-popper v-popper--theme-dropdown">
                              <i class="fa fa-info-circle info countdown"></i>
                            </div>                  
                          <template #popper>
                            <div>
                              <p>{{$t('nextMinerPayment')}}</p>
                            </div>
                          </template>
                        </VTooltip>

                          </div>
                        </div>

  
                          
                          
                          

                        

                        
                      </div>
                      <div class="info flex flex--align-top flex--justify-left mb32">
                        <div class="probability mr16">
                          <div class="flex flex--no-wrap mb2">
                            <div class="text-muted fs12 mr8">{{$t('totalDevicePower')}}</div>
                            <div class="default" style="width: 16px; height: 16px; margin-top: -2px;">
                              <div class="icon"></div>
                            </div>
                          </div>
                          <template v-if="isLoading">
                            <h4 class="miner-color skeleton-power">
                                <div class="skeleton-left">
                                    <div class="line h20 w50 m10"></div>
                                </div>
                            </h4>
                          </template>
                          <template v-else>
                            <div class="text-bold fs32">{{userPower}}</div>
                          </template>
                        </div>

                        <div class="block-reward">
                          <div class="text-muted fs12 mb3">{{$t('nextReward')}}</div>
                          <template v-if="isLoading">
                            <h4 class="miner-color skeleton-power">
                                <div class="skeleton-left">
                                    <div class="line h20 w50 m10"></div>
                                </div>
                            </h4>
                          </template>
                          <template v-else>
                            <div class="text-bold fs32 mb2"> {{hourlyEarningBTC}} <small class="fs12 text-normal">BTC</small></div>
                          </template>
                          <template v-if="isLoading">
                            <h4 class="miner-color skeleton-power">
                                <div class="skeleton-left">
                                    <div class="line h20 w50 m10"></div>
                                </div>
                            </h4>
                          </template>
                          <template v-else>
                            <div class="text-muted fs12">≈ $ {{hourlyEarningUSD}}</div>
                          </template>
                        </div>
                      </div>
                      <div class="flex flex--no-wrap flex--justify-space-between flex--align-vertical mb4">
                        <div style="max-width: 240px;">
                          <div class="flex flex--align-vertical text-muted fs12 mb4 noselect">
                            <div class="v-popper--has-tooltip" style="width: 110px;">{{ $t('purchased') }}</div>
                            <template v-if="isLoading">
                            <h4 class="miner-color skeleton-power">
                                <div class="skeleton-left">
                                    <div class="line h20 w50 m10"></div>
                                </div>
                            </h4>
                          </template>
                          <template v-else>
                            <div>

                              <span class="fs12 text-color">{{purchasedPower}}</span>&nbsp; <span>&nbsp;{{convertPower(purchasedPower)}}</span>
                            </div>
                          </template>
                          </div>
                          <div class="flex flex--align-vertical text-muted fs12 mb4 noselect">
                            <div>{{ $t('commissionPower')}}:</div>
                            <template v-if="isLoading">
                            <h4 class="miner-color skeleton-power">
                                <div class="skeleton-left">
                                    <div class="line h20 w50 m10"></div>
                                </div>
                            </h4>
                          </template>
                          <template v-else>
                            <div>
                              <span class="fs12 text-color" style="margin-left: 15px">{{commissionPower}}</span>&nbsp; <span>&nbsp;{{convertPower(commissionPower)}}</span>
                            </div>
                          </template>
                          </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                </div>
              </div>
            </div>
          </div>

          <div class="container mt-3">
            <div class="row">
              

              <div class="col-md-12">
                <div class="field-wrap input-undefined mb24">
                  <!-- Contenido de input-undefined -->

                  <div class="label-row">
                    <label>{{$t('referralLink')}}</label>
                  </div>
                  <div class="input-group medium copy-on-hover">
                    <span class="copy-feedback" 
                    :style="showMessage 
                    ? 
                    'display:block;' 
                    : 
                    'display:none'">
                    {{$t('copiedClipboard')}}
                    </span>
                    <input readonly="" v-on:focus="$event.target.select()" type="text" ref="myinput" class="copy-address" :value="'https://cloudhashx.com/sign-up?ref='+user?.user?.referral_code" maxlength="">
                  <button @click="copyRef" class="btn primary small copy" type="button">
                    <span>{{$t('copyBtn')}}</span>
                  </button>
                </div>

                </div>

                <div class="row package-container">
                  <div class="col-12">
                    <h3 class="">{{$t('calculateFuture')}}</h3>
                    <div class="slider-bottom-box  shadow">
                      <!-- Contenido del cálculo de futuro -->

                      <div class="row">
                <div class="col-lg-4 mb-3 mb-lg-0 vertLine">
                    <h5>{{$t('amountPay')}} </h5>
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" 
                      v-model="inputAmount" 
                      @input="updateCalculator"
                      @keypress="restrictToNumbers" 
                      aria-label="Amount (to the nearest dollar)">
                      <span class="input-group-text">USD</span>
                    </div>
                    <div class="col d-flex align-items-center">
                      <p><strong>{{$t('minerHashRate')}}</strong></p>
                      <span class="ms-auto">{{ minerHashRate }} {{ hashUnit }}</span>
                    </div>
                    <div class="col d-flex align-items-center">
                        <p class="form-text mb-0"><strong>{{$t('unitPrice')}}</strong></p>
                        <span class="ms-auto">{{ unitPrice }} USD</span>
                    </div>
                    <hr>
                    <div class="col d-flex align-items-center">
                        <p><strong>{{$t('minimum')}}</strong></p>
                        <span class="ms-auto">{{ minimumHash.toFixed(2) }} TH/s</span>
                    </div>
                    <div class="col d-flex align-items-center">
                      <p><strong>{{$t('maximum')}}</strong></p>
                      <span class="ms-auto">{{ maximumHash.toFixed(2) }} PH/s</span>
                    </div>
                    
                </div>
                <div class="col-lg-8 col-sm-6">
                    <h5>{{$t('estimatedIncome')}}</h5>
                    <div class="d-flex flex-wrap">
                        <div class="col-md-4">
                            <p><strong>{{$t('perHour')}}</strong></p>
                        </div>
                        <div class="col-md-4">
                            <p>{{ (hourlyIncome * 100).toFixed(2) }} %</p>
                        </div>
                        <div class="col-md-4">
                            <p>$ {{ hourlyIncomeValue }}</p>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap">
                        <div class="col-md-4">
                            <p><strong>{{$t('perDay')}}</strong></p>
                        </div>
                        <div class="col-md-4">
                            <p>{{ (dailyIncome * 100).toFixed(2) }} %</p>
                        </div>
                        <div class="col-md-4">
                            <p>$ {{ dailyIncomeValue }}</p>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap">
                        <div class="col-md-4">
                            <p><strong>{{$t('perWeek')}}</strong></p>
                        </div>
                        <div class="col-md-4">
                            <p>{{ (weeklyIncome * 100).toFixed(2) }} %</p>
                        </div>
                        <div class="col-md-4">
                            <p>$ {{ weeklyIncomeValue }}</p>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap">
                        <div class="col-md-4">
                            <p><strong>{{$t('perMonth')}}</strong></p>
                        </div>
                        <div class="col-md-4">
                            <p>{{ (monthlyIncome * 100).toFixed(2) }} %</p>
                        </div>
                        <div class="col-md-4">
                            <p>$ {{ monthlyIncomeValue }}</p>
                        </div>
                    </div>
                    <hr>
                    <div class="d-flex">
                      <div class="col-md-4">
                        <p class="text-muted">{{$t('estimatedROI')}}</p>
                      </div>
                      <div class="col-md-4">
                      <span class="ms-auto">~ {{ roi === '365' ? '∞' : roi +' '+ $t('days') }} </span>
                      </div>
                    </div>
                </div>
              </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  

    

    <div class="modal-backdrop fade show" v-if="showModal"></div>
    <!-- <Footer/> -->
  </div>
</template>


<script>
import BitcoinPrice from '../components/BitcoinPrice.vue';
import Countdown from '../components/Countdown.vue';
import Footer from './Footer.vue';
import Sidebar from './Sidebar.vue';
import axios from 'axios';
import { mapGetters, mapActions, mapState } from 'vuex';
export default {
  name: 'CloudhashFrontendDashboard',
  components: { BitcoinPrice, Footer, Sidebar, Countdown },
  data() {
    return {
        contractDuration: 0,
        placement: 'left',
        isPopoverOpen: false,
        nextReward: null,
        hourlyEarningBTC: 0,
        hourlyEarningUSD: 0,
        monthlyEarning: 0,
        inputAmount: 10,
        unitPrice: 0.21,
        minimumHash: 4.20,
        maximumHash: 50.10,
        minerHashRate: 0,
        hourlyIncome: 0,
        hourlyIncomeValue: 0,
        dailyIncome: 0,
        dailyIncomeValue: 0,
        weeklyIncome: 0,
        weeklyIncomeValue: 0,
        monthlyIncome: 0,
        monthlyIncomeValue: 0,
        roi: 0,
        isLoading: false,
        hashUnit: 'TH/s',
        showMessage: false,
        userPower: 0,
        interval: null,
        btcPrice: 0,
        btcValue: 0,
        balanceBTC: 0,
        currency: 'USD',
        originalMonthlyEarning: 0,
        dailyEarning: 0,
        weeklyEarning: 0,
        hourlyEarning: 0,
        purchasedPower: 0,
        commissionPower: 0,
        popoverStyle: {},
        popoverLeft: 0,
        popoverTop: 0,
        showModal: false,
        pricesData: {
            miner: 'Antminer Bitcoin',
            unit: 'GH/s',
            hash_price: 0.0500,
        }
    };
  },

  mounted() {
    this.updateCalculator();
    //this.interval = setInterval(this.updatePower, 1000);
    //this.getBtcPrice();
    //this.$store.dispatch('user/fetchSaldo');
    //this.fetchMiningPower();
    
    
  },
  beforeMount() {

  },
  computed: {
    ...mapGetters({
          autenticado: 'user/autenticado',
          user: 'user/usuario',
          balance: 'user/balance',
          power: 'user/power'
      }),
  },
  beforeDestroy() {
      clearInterval(this.interval);
      //window.removeEventListener('resize', this.calculatePopoverPosition);
  },
  methods: {
    checkEntrance() {
        const hasSeenModal = localStorage.getItem('hasSeenAirdrop');
        if (!hasSeenModal) {
            // Si no la ha visto, muestra la modal y marca que la ha visto
            this.showModal = true;
            localStorage.setItem('hasSeenAirdrop', 'true');
        }
    },
    closeModal() {
      this.showModal = false;
      document.body.classList.remove('modal-open');
    },
    showPopover() {
      this.isPopoverOpen = !this.isPopoverOpen;
    },
    convertPower(amount) {
        const hashUnits = ['GH/s', 'TH/s', 'PH/s', 'EH/s']; // Unidades de hash
        let index = 0; // Índice de TH/s por defecto
        if(amount === 0){
          index = 0;
        }
        
        while (amount >= 1000 && index < hashUnits.length - 1) {
            amount /= 1000;
            index++;
        }
        
        return hashUnits[index];
    },
    fetchMiningPower() {
      this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL+'getMiningPower')
      .then((res)=>{
        this.nextReward = res.data.next_reward;
        this.purchasedPower = res.data.purchased_power;
        this.commissionPower = res.data.commission_power;
        this.monthlyEarning = res.data.monthly_earning;
        this.originalMonthlyEarning  = res.data.monthly_earning;
        this.calculateEarnings();
        this.calculateReward();
        this.isLoading = false;
      })
      .catch((error)=>{
        console.log('error getting User Power',error.response.error);
        this.isLoading = false;
      })
    },
    calculateReward() {
      const daily = this.originalMonthlyEarning / 30;
      const hourly = daily / 24;
      this.hourlyEarningBTC = (hourly / this.btcPrice).toFixed(8);
      this.hourlyEarningUSD = hourly.toFixed(2);
    },
    calculateEarnings() {
        // Calcular las ganancias diarias, semanales y por hora basadas en las ganancias mensuales
        const daily = this.originalMonthlyEarning / 30;
        const weekly = daily * 7;
        const hourly = daily / 24;

        // Redondear los valores a 2 decimales
        this.dailyEarning = daily.toFixed(2);
        this.weeklyEarning = weekly.toFixed(2);
        this.hourlyEarning = hourly.toFixed(2);
    },
    changeCurrency() {
      this.currency = this.currency === 'USD' ? 'BTC' : 'USD';

      if (this.currency === 'BTC') {
          // Convertir el saldo a BTC si la moneda es BTC
          this.balanceBTC = (this.balance / this.btcPrice).toFixed(8);
          this.monthlyEarning = (this.monthlyEarning / this.btcPrice).toFixed(8);
          this.dailyEarning = (this.dailyEarning / this.btcPrice).toFixed(8);
          this.weeklyEarning = (this.weeklyEarning / this.btcPrice).toFixed(8);
          this.hourlyEarning = (this.hourlyEarning / this.btcPrice).toFixed(8)
      } else {
          this.monthlyEarning = this.originalMonthlyEarning;
          this.calculateEarnings();
      }
    },
    getBtcPrice() {
      axios.get(process.env.VUE_APP_API_URL+'getBtcPrice')
      .then((res)=>{
        this.btcPrice = res.data.price;
        this.calculateReward();
      })
      .catch((error)=>{
        console.log('error getting BTC Price',error.response.error);
      })
    },
    convertToTHS(amount) {
        // Convertir a TH/s
        // Puedes ajustar esta función según las unidades que uses
        // GH/s, TH/s, PH/s, EH/s
        // Por ejemplo, si inputAmount es en PH/s, necesitas multiplicar por 1000 para obtener TH/s
        const hashUnits = ['GH/s', 'TH/s', 'PH/s', 'EH/s']; // Unidades de hash
        let index = 0; // Índice de TH/s por defecto
        
        while (amount >= 1000 && index < hashUnits.length - 1) {
            amount /= 1000;
            index++;
        }

        // Retroceder si el valor es menor que 1
        while (amount < 1 && index > 0) {
            amount *= 1000;
            index--;
        }
        
        return {
            hashValue: amount,
            hashUnit: hashUnits[index]
        };
    },
    updatePower() {
        const random = Math.random() * (0.025 + 0.025) - 0.025;
        let userPower = this.power; // Este valor sería dinámico, pero por ahora lo dejamos fijo para el ejemplo
        
        let { hashValue, hashUnit } = this.convertToTHS(userPower);
        userPower = parseFloat((hashValue + random).toFixed(2));
        this.userPower = `${userPower} ${hashUnit}`;
        
    },
    copyRef() {
        this.showMessage = true;
        this.$refs.myinput.focus();
        document.execCommand('copy');
        setTimeout(() => {
            this.showMessage = false
        }, 5000)
    },
    restrictToNumbers(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        if (!/^[0-9]+$/.test(keyValue)) {
            event.preventDefault();
        }
    },
    convertHashRate(value, fromUnit, toUnit) {
        const units = {
            GH: 1,
            TH: 1000,
            PH: 1000000,
            EH: 1000000000
        };

        const fromValue = value * units[fromUnit];
        const toValue = fromValue / units[toUnit];

        return toValue;
    },
    convertToTHS(amount) {
        // Convertir a TH/s
        // Puedes ajustar esta función según las unidades que uses
        // GH/s, TH/s, PH/s, EH/s
        // Por ejemplo, si inputAmount es en PH/s, necesitas multiplicar por 1000 para obtener TH/s
        const hashUnits = ['GH/s', 'TH/s', 'PH/s', 'EH/s']; // Unidades de hash
        let index = 0; // Índice de TH/s por defecto
        
        while (amount >= 1000 && index < hashUnits.length - 1) {
            amount /= 1000;
            index++;
        }
        
        return {
            hashValue: amount,
            hashUnit: hashUnits[index]
        };
    },
    updateCalculator() {
        const inputAmount = this.inputAmount; // Valor por defecto
        const unitPrice = 0.21; // Valor por defecto
        const maxHash = 50.10; // Máximo de compra en PH/s
        let dailyPercentage = 0.025; // Porcentaje por defecto (2.5%)
        let dailyProfit = 0;
        let hourlyProfit = 0;
        let weeklyProfit = 0;
        let monthlyProfit = 0;
        let roi = 0;

        // Determinar el porcentaje diario según el monto ingresado
        if (inputAmount <= 60) {
            dailyPercentage = 0.025; // 2.5%
        } else if (inputAmount >= 61 && inputAmount <= 100) {
            dailyPercentage = 0.03; // 3%
        } else if (inputAmount >= 101 && inputAmount <= 220) {
            dailyPercentage = 0.035; // 3.5%
        } else if (inputAmount >= 221 && inputAmount <= 700) {
            dailyPercentage = 0.04; // 4%
        } else if (inputAmount >= 701) {
            dailyPercentage = 0.05; // 5%
        }
        
        //console.log(dailyPercentage * 100+' %');

        this.minerHashRate = (inputAmount / this.pricesData.hash_price).toFixed(2);
        //this.minerHashRate = hashValue;
        //this.hashrateUnit = priceData.unit;

        // Calcular ganancias diarias, mensuales y ROI
        dailyProfit = inputAmount * dailyPercentage;
        hourlyProfit = dailyProfit / 24;
        weeklyProfit = dailyProfit * 7;
        monthlyProfit = dailyProfit * 30;
        roi = (inputAmount / monthlyProfit) * 30; // ROI en días
        const daysToDouble = roi * 2;
        const totalProfit = inputAmount * 2;

        this.contractDuration = daysToDouble % 1 === 0 ? daysToDouble.toFixed(0) : daysToDouble.toFixed(1);
        
        

        // Convertir el valor ingresado a TH/s
        let { hashValue, hashUnit } = this.convertToTHS(this.minerHashRate);

        //console.log(hashValue);
//        this.minerHashRate = hashValue;
        this.hashUnit = hashUnit; // Unidad de hash
        
        // Ajustar los valores de los beneficios
        this.hourlyIncome = dailyPercentage / 24;
        this.hourlyIncomeValue = hourlyProfit.toFixed(2);
        this.dailyIncome = dailyPercentage;
        this.dailyIncomeValue = dailyProfit.toFixed(2);
        this.weeklyIncome = dailyPercentage * 7;
        this.weeklyIncomeValue = weeklyProfit.toFixed(2);
        this.monthlyIncome = dailyPercentage * 30;
        this.monthlyIncomeValue = monthlyProfit.toFixed(2);
        
        this.roi = roi % 1 === 0 ? roi.toFixed(0) : roi.toFixed(1);
        
        
    },
  },
};
</script>
<style scoped>

.dashboard-container {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 32px;
}

.enable2fa-banner {
    position: relative;
    box-shadow: 0 1px 4px #23232329;
    border-radius: 4px;
    padding: 16px 24px;
    line-height: 1.5;
    background-color: #fff;
}

.enable2fa-banner .text {
    font-size: 14px;
}

.enable2fa-banner .text-bold {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 4px;
}

.mb24 {
    margin-bottom: 24px;
}

.btn.primary.normal {
    box-shadow: 0 12px 4px -10px #19345D66;
}

.btn.fluid {
    width: 100%;
}

.btn.normal {
    padding: 13px 24px 12px;
    font-size: 12px;
    letter-spacing: .5px;
}

.btn.primary {
    background: linear-gradient(90deg,#002BAE,#002BAE);
    color: #fff;
    box-shadow: 0 20px 4px -18px #19345D52;
}

.btn {
    border-radius: 4px;
    border: 0;
    padding: 10px 12px 9px;
    transition: box-shadow .2s ease,border-radius .2s ease,border-radius .2s ease,background-color .2s ease,color .2s ease;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
}

#content {
    flex: 1 0 auto;
    position: relative;
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 16px;
    padding-left: 16px;
}

.content {
    box-shadow: none;
    background: rgb(255, 255, 255);
    flex: 1 1 0%;
    border-radius: 4px;
}

.box {
    box-shadow: 0 1px 4px #23232329;
    border-radius: 4px;
    margin-bottom: 16px;
    background: #fff;
}

.box .total {
    padding: 24px 16px 0;
    border-bottom: 1px solid #EEE;
    position: relative;
}

.box .available {
    padding: 24px 16px 12px;
    position: relative;
}

.box i.info {
    position: absolute;
    top: 12px;
    right: 12px;
}

.box .total h1 {
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -.1px;
    line-height: 40px;
}

.mined {
  background-color: #002BAE;
  color: white;
  border-radius: 4px;
}

.box .available h1 {
  font-size: 24px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -.1px;
    line-height: 40px;
}

.mb40 {
    margin-bottom: 40px;
}

.mb16 {
    margin-bottom: 16px;
}

.mt16 {
    margin-top: 16px;
}

.ml32 {
  margin-left: 32px;
}

.ml24 {
  margin-left: 18px;
}

.fs12 {
  font-size: 12px
}

.text-toggle {
  color: #fba342;
}

.input-group.copy-on-hover .copy {
    display: none
}

.input-group.copy-on-hover:hover .copy {
    display: block
}

.input-group input:disabled[type=copy], .input-group input:read-only[type=copy] {
    cursor: default;
}

.input-group.auth input, .input-group.medium input {
    padding: 18px 14px;
}

.input-group input:disabled, .input-group input:read-only {
    box-shadow: none;
    border: 1px solid #EEE;
}

.input-group .copy {
    position: absolute;
    right: 8px;
    top: 8px;
}

.btn.small {
    padding: 6px 23px;
    font-size: 10px;
    letter-spacing: .4px;
}

.input-group.medium .copy {
    top: 16px;
    right: 16px;
}

.copy-feedback {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    line-height: 20px;
    padding: 13px 0;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .05em;
    text-align: center;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    border: 1px solid rgba(211, 224, 243, 0.5);
    color: #00ba94;
}

.copy-address {
    border: none;
    color: #495463;
    line-height: 20px;
    padding: 12px 50px 12px 40px;
    border-radius: 4px;
    border: 1px solid rgba(211, 224, 243, 0.5);
    width: 100%;
    background: #fff;
}

.miner-color {
  color: rgb(25, 52, 93);
}

.vertLine {
    border-right: 1px solid #dee2e6;
    height: 100%;
}

@keyframes shimmer {
    to {
        background-position: -200% 0;
    }
}

.skeleton {
     padding:15px;
     max-width: 300px;
     width: 120px;
     height: 40px;
     /* background-color: rgba(242, 242, 242, 0.2); */ /* Color de fondo gris claro con opacidad */
     color: transparent; /* Texto transparente para simular un esqueleto */
     margin-top: 5px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 4px;
     /* box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .2), 0 1px 8px 0 rgba(0, 0, 0, .12); */
}

.skeleton-power {
     padding:15px;
     max-width: 300px;
     /* width: 120px; */
     height: 10px;
     /* background-color: rgba(242, 242, 242, 0.2); */ /* Color de fondo gris claro con opacidad */
     color: transparent; /* Texto transparente para simular un esqueleto */
     margin-top: 5px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 4px;
     /* box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .2), 0 1px 8px 0 rgba(0, 0, 0, .12); */
}

.skeleton-balance {
     padding:15px 15px 15px 0;
     max-width: 300px;
     width: 135px;
     height: 10px;
     color: transparent; /* Texto transparente para simular un esqueleto */
     margin-top: 5px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 4px;
}

.skeleton-power .line {
     height: 12px;
     margin-top:6px;
     border-radius: 4px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation:  shimmer 1.5s infinite linear;
}

.skeleton-balance .line {
     height: 12px;
     margin-top: 6px;
     margin-bottom: 6px;
     border-radius: 4px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation:  shimmer 1.5s infinite linear;
}

.skeleton-left {
    flex:1;
}

.skeleton .line {
     height: 12px;
     margin-top:6px;
     border-radius: 4px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation:  shimmer 1.5s infinite linear;
}

.h25{
    height: 35px !important;
}

.h20{
    height: 20px !important;
}

.w50{
    width: 100% !important
}

.skeleton-purchased .line {
    height: 12px;
    margin-top: 0px;
    margin-bottom: 20px;
    border-radius: 4px;
    background: rgba(130, 130, 130, 0.2);
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation: shimmer-22ba47ca 1.5s infinite linear;
}

.package-container .package .head .countdown {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: 4px;
}

.package-container .package .head .countdown .countdown-label {
    position: absolute;
    width: 120px;
    height: 60px;
    right: -42.5px;
    top: -12.5px;
    padding-top: 10px;
    color: #fff;
    background: #fba342;
    transform: matrix(.71,.7,-.71,.71,0,0);
}

.fs14 {
    font-size: 14px;
}

.flex--align-bottom {
    align-items: flex-end;
}

.flex--align-top {
    align-items: flex-start;
}

.flex--justify-center {
    justify-content: center;
}

.flex--justify-left {
    justify-content: flex-start;
}

.flex--align-vertical {
    align-items: center;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.mb4 {
    margin-bottom: 4px;
}

.package-container .package .info .probability {
    min-width: 110px;
}

.text-bold {
    font-weight: 700;
}

.package-container .package .info .fs32 {
    font-size: 32px;
    line-height: 32px;
}

.flex--no-wrap {
    flex-wrap: nowrap;
}

.mb2 {
    margin-bottom: 2px;
}

.mr16 {
    margin-right: 16px;
}

.mb32 {
    margin-bottom: 32px;
}

div.countdown-inner {
    top: 7px;
    right: 25px;
    position: absolute;
    z-index: 99;
}

.info.countdown {
    color: #fff;
}

.info {
    color: #19345D;
    margin-top: 2px;
}

.package-container .package .head .icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.package-container .package .info .probability .icon {
    width: 16px;
    height: 16px;
    background-size: 16px;
    left: calc(50% - 8px);
    top: calc(50% - 8px);
}

.default {
    position: relative;
    height: 35px;
    width: 35px;
}

.icon {
    background: url("data:image/svg+xml,%3csvg%20width='60px'%20height='60px'%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20100%20100'%20preserveAspectRatio='xMidYMid'%20class='lds-ring'%20style='background:%20none;'%3e%3ccircle%20cx='50'%20cy='50'%20fill='none'%20r='30'%20stroke='none'%20stroke-width='10'%20class=''%3e%3c/circle%3e%3ccircle%20cx='50'%20cy='50'%20fill='none'%20r='30'%20stroke='%23fba342'%20stroke-width='10'%20stroke-linecap='square'%20transform='rotate(630%2050%2050)'%20class=''%3e%3canimateTransform%20attributeName='transform'%20type='rotate'%20calcMode='linear'%20values='0%2050%2050;180%2050%2050;720%2050%2050'%20keyTimes='0;0.5;1'%20dur='1.4s'%20begin='0s'%20repeatCount='indefinite'%20class=''%3e%3c/animateTransform%3e%3canimate%20attributeName='stroke-dasharray'%20calcMode='linear'%20values='18.84955592153876%20169.64600329384882;94.2477796076938%2094.24777960769377;18.84955592153876%20169.64600329384882'%20keyTimes='0;0.5;1'%20dur='1.4'%20begin='0s'%20repeatCount='indefinite'%20class=''%3e%3c/animate%3e%3c/circle%3e%3c/svg%3e") no-repeat;
    background-size: 35px 35px;
    width: 35px;
    height: 35px;
    content: " ";
    color: #fff;
    display: block;
    position: absolute;
    overflow: visible;
    left: calc(50% - 17.5px);
    top: calc(50% - 17.5px)
}

.package-container .package .progress {
    position: relative;
}

.package-container .package .progress .progress-bar {
    min-height: 6px;
}

.package-container .package .progress .animated-progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 6px;
    width: 11px;
    background: linear-gradient(90deg,#fba342,#fbc241);
    border-radius: 2px;
    animation: animateProgress;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    opacity: .4;
    z-index: 99;
}

@keyframes animateProgress {
    0% {
        left: 0
    }

    to {
        left: calc(100% - 11px)
    }
}

.package-container .package .progress .progress-bar {
    min-height: 6px
}

.package-container .package .progress .progress-bar .progress-bg {
    height: 6px;
    background: linear-gradient(90deg,#fba342,#fbc241);
    display: flex;
    flex-wrap: nowrap;
    border-radius: 2px;
    position: relative
}

.text-right {
    text-align: right;
}

.package-container .package .progress .progress-bar .progress-bg div.orange {
    height: 100%;
    background-color: #eee;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    position: absolute;
    right: 0;
}

.text-color {
    color: #3a3939;
}

.noselect {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.modal-dialog-custom {
    position: relative;
    width: 400px;
    margin: var(--bs-modal-margin);
    pointer-events: none;
}

@media (min-width: 576px){
  .modal-dialog-custom {
      max-width: var(--bs-modal-width);
      margin-right: auto;
      margin-left: auto;
  }
}

.main-container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 10px;
}

#content {
    flex: 1 0 auto;
    padding-bottom: 32px;
    position: relative;
}

</style>