<template>
  <div>
    <!-- <BitcoinPrice key="bitcoin-price" /> -->

    <section id="slider" class="slider-element dark min-vh-25 min-vh-md-50 include-header py-5"  style="background-color: #002BAE;">
        <!-- <div class="cloud-wrap">
          <div class="c1"><div class="cloud"></div></div>
          <div class="c2"><div class="cloud"></div></div>
          <div class="c3"><div class="cloud"></div></div>
          <div class="c4"><div class="cloud"></div></div>
          <div class="c5"><div class="cloud"></div></div>
        </div> -->

        <div class="slider-inner">
            <div class="vertical-middle text-center">
                <div class="container">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-6 col-md-6">
                            <h2 class="fw-bold text-white mb-2">{{$t('privacyPolicy')}}</h2>
                            <p class="lead mb-0 fw-normal">{{$t('privacyPolicySubtitle')}}</p>
                            <div class="privacy-policy mt-4">
                                <p>{{$t('privacyPMain')}}</p>
                                <p>{{$t('privacyP2')}}</p>
                                <p>{{$t('privacyP3')}}</p>
                                <ul>
                                    <li>{{ $t('email') }}</li>
                                    <li>{{ $t('username') }}</li>
                                    <li>{{$t('privacyPassword')}}</li>
                                </ul>
                                <p>{{$t('privacyP4')}}</p>
                                <p>{{$t('privacyP5')}}</p>
                                <h4>{{$t('privacyTl')}}</h4>
                                <p>{{$t('privacyP6')}}</p>
                                <p>{{$t('privacyP7')}}</p>
                                <p>{{$t('privacyP8')}}</p>
                                <p>{{$t('privacyP9')}}</p>
                                <ul>
                                    <li>{{ $t('privacyLst1') }}</li>
                                    <li>{{ $t('privacyLst2') }}</li>
                                    <li>{{$t('privacyLst3')}}</li>
                                    <li>{{$t('privacyLst4')}}</li>
                                </ul>
                                <h4>{{$t('privacyTl2')}}</h4>
                                <p>{{$t('privacyP10')}}</p>
                                <p>{{$t('privacyP11')}}</p>
                                <p>{{$t('privacyP12')}}</p>
                                <p>{{$t('privacyP13')}}</p>
                                <p>{{$t('privacyP14')}}</p>
                                <p>{{$t('privacyP15')}}</p>
                                <p>{{$t('privacyP16')}}</p>
                            </div>
                        </div>
                    </div><!-- row -->
                </div>
            </div>
        </div>


    </section>

    <Footer/>
  </div>
</template>

<script>
import BitcoinPrice from '../components/BitcoinPrice.vue';
import Footer from './Footer.vue';
export default {
  name: 'CloudhashFrontendPrivacy',
  components: { BitcoinPrice, Footer },
  data() {
    return {
        bgImg: {
            backgroundImage: `url(${require('@/assets/images/1.svg')})`
        },
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>
<style scoped>
.privacy-policy {
  text-align: left; /* Alinea el texto a la izquierda */
  margin-top: 30px; /* Espacio superior */
  padding: 20px; /* Espaciado interior */
  flex-grow: 1;
  /* background-color: #f8f9fa; */ /* Color de fondo */
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra */
}

.privacy-policy p {
  margin-bottom: 30px; /* Espacio entre párrafos */
}

.privacy-policy li {
    position: relative;
    padding-left: 20px;
}

.privacy-policy li:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 10px;
    height: 10px;
    background: white;
    margin-top: -5px;
}

.privacy-policy ul {
    list-style: none;
    margin: 0 0 30px 0;
    padding: 0;
}
</style>