import axios from 'axios';

export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token') || null,
        user: null,
        balance: 0,
        power: 0,
        requiresTwoFactorAuth: JSON.parse(localStorage.getItem('requiresTwoFactorAuth')) || false,
    },
    mutations: {
        EL_TOKEN(state, token) {
            state.token = token
        },
        EL_USUARIO(state, data) {
            state.user = data
        },
        setBalance(state, newBalance) {
            state.balance = newBalance;
        },
        setPower(state, newPower) {
            state.power = newPower;
        },
        SET_TOKEN(state, token) {
            state.token = token;
            localStorage.setItem('token', token); // Guardar token en localStorage
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // Configurar encabezado global
        },
        CLEAR_TOKEN(state) {
            state.token = null;
            localStorage.removeItem('token');
            delete axios.defaults.headers.common['Authorization'];
        },
        SET_USER(state, user) {
            state.user = user;
        },
        SET_BALANCE(state, newBalance) {
            state.balance = newBalance;
        },
        SET_POWER(state, newPower) {
            state.power = newPower;
        },
        SET_REQUIRES_TWO_FACTOR_AUTH(state, status) {
            state.requiresTwoFactorAuth = status;
            localStorage.setItem('requiresTwoFactorAuth', JSON.stringify(status));
        },
    },
    getters: {
        autenticado(state) {
            return state.token && state.user
        },
        usuario(state) {
            return state.user
        },
        balance(state) {
            return state.balance
        },
        power(state) {
            return state.power
        },
        requiresTwoFactorAuth(state) {
            return state.requiresTwoFactorAuth;
        },
    },
    actions: {
        async fetchSaldo({ commit }) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + 'getUserBalance');
                const newBalance = parseFloat(response.data.balance); // Ajusta según la estructura de tu respuesta
                const newPower = response.data.power;
                commit('setBalance', newBalance);
                commit('setPower', newPower);
            } catch (error) {
                console.error('Error fetching balance:', error);
            }
        },
        async signIn({ dispatch, commit }, credenciales) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_API_URL}login`, credenciales);
                commit('SET_TOKEN', res.data.token);

                // Verificar 2FA
                if (res.data.twoFactorEnabled) {
                    commit('SET_REQUIRES_TWO_FACTOR_AUTH', true);
                    return { twoFactorEnabled: true };
                } else {
                    commit('SET_REQUIRES_TWO_FACTOR_AUTH', false);
                    await dispatch('intento', res.data.token);
                    return { twoFactorEnabled: false };
                }
            } catch (error) {
                /* console.error('Error during login:', error);
                throw new Error('Login failed'); */
                throw error.response || { message: "Unexpected error" };
            }
        },
        async intento({ commit }, token) {
            if (token) {
                commit('SET_TOKEN', token);
            }

            try {
                const res = await axios.get(`${process.env.VUE_APP_API_URL}authData`);
                commit('SET_USER', res.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
                commit('CLEAR_TOKEN');
                commit('SET_USER', null);
            }
        },
        async logout({ commit }) {
            commit('CLEAR_TOKEN');
            commit('SET_USER', null);
            commit('SET_REQUIRES_TWO_FACTOR_AUTH', false);
        },
        async verifyTwoFactorCode({ commit, dispatch }, code) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_API_URL}twoFactorLogin`, { code });
                if (res.data.success) {
                    commit('SET_REQUIRES_TWO_FACTOR_AUTH', false);
                    await dispatch('intento', res.data.token);
                } else {
                    throw new Error(res.data.error || 'Invalid verification code');
                }
            } catch (error) {
                console.error('Error verifying 2FA code:', error);
                throw error;
            }
        },
        /*  async signIn({ dispatch }, credenciales) {
             let res = await axios.post(process.env.VUE_APP_API_URL + 'login', credenciales)
             // Verificar si el usuario tiene habilitado el 2FA
             if (res.data.twoFactorEnabled) {
                 // Si tiene 2FA habilitado, redirigir a la página de verificación de 2FA
                 // o mostrar el formulario de 2FA aquí
                 // Aquí asumimos que tienes una ruta llamada 'verify-2fa' para la verificación de 2FA
                 await dispatch('intento', res.data.token);
                 return { twoFactorEnabled: true };
             } else {
                 // Si no tiene 2FA habilitado, continuar con la acción normal de inicio de sesión
                 await dispatch('intento', res.data.token);
                 return { twoFactorEnabled: false };
             }
         },
         async intento({ commit, state }, token) {
             if (token) {
                 commit('EL_TOKEN', token)
             }
 
             if (!state.token) {
                 return
             }
 
             try {
                 let res = await axios.get(process.env.VUE_APP_API_URL + 'me')
                 commit('EL_USUARIO', res.data)
             } catch (e) {
                 commit('EL_USUARIO', null)
                 commit('EL_TOKEN', null)
             }
         },
         async logout(context) {
             let res = await axios.post(process.env.VUE_APP_API_URL + 'logout');
             localStorage.removeItem('token');
             context.commit('EL_USUARIO', null);
             context.commit('EL_TOKEN', null);
             return res.data
         }, */
    }
}