<template>
  <div>

    <div id="top-bar" class="settings-part dark" style="background-color: #002BAE;">
        <ul class="bitcoin-prices-list">
            <template v-if="loading">
                <li v-for="n in 7" :key="n" class="skeleton">
                    <div class="skeleton-left">
                        <div class="line h25 w50 m10"></div>
                    </div>
                </li>
            </template>
            <template v-else>
                <li v-for="(price, index) in prices" :key="index">
                    <span 
                     :class="[
                                                                            priceChanges[index] === 1 ? 'price-up-color' : 'price-text',
                                                                            priceChanges[index] === -1 ? 'price-down-color' : 'price-text',
                                                                            priceChanges[index] === 0 ? 'font-bold text-gray-500' : 'price-text',
                                                                            palpitar ? 'palpitar' : ''
                                                                        ]">
                    <!-- class="price-text" :class="[subebtc ? 'price-up-color' : 'price-down-color', palpitar ? 'palpitar' : '']"> --> 
                        1 {{convertName(price.crypto_name)}} = {{ price.price }} {{ price.currency.toUpperCase() }}
                    </span> 
                    
                    <span class="arrow price-up" v-if="priceChanges[index] === 1"></span>
                    <span class="arrow price-down" v-else-if="priceChanges[index] === -1"></span>
                </li>
            </template>
        </ul>

        <div class="selectbox up locale locale-select locale" :class="{ open: isDropdownOpen }" tabindex="0">
            <div class="field" @click="toggleDropdown" v-click-outside="hide" :aria-expanded="isDropdownOpen ? 'true' : 'false'">
                <div class="arrows">
                    <i class="fa fa-caret-up"></i>
                    <i class="fa fa-caret-down"></i>
                </div>
                <div class="flag" :class="selectedLanguage.flag"></div> {{ selectedLanguage.label }} 
            </div>
            <div class="dropdown flex flex--no-wrap">
                <div class="options" role="listbox" aria-label="Options">
                    <div class="option" 
                    :class="selectedLanguage.label === lang.label ? 'selected' : ''" 
                    role="option" 
                    :aria-selected="selectedLanguage.label === lang.label ? true :false" 
                    v-for="(lang, index) in supportedLanguages" 
                    :key="index"
                    @click="changeLocale(lang)"
                    >
                        <div class="flag" :class="lang.flag"></div> {{ lang.label }}
                    </div>
                </div>
            </div>
        </div>

       
    </div>
    
    <Header />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import Header from './Header.vue';
import i18n from '@/i18n/index.js';
import clickOutside from '../directives/clickOutside';
export default {
  name: 'BitcoinPrice',
  components:{ Header },
  data() {
    return {
        loading: true,
        firstLoad: true,
        prices: [],
        interval: null,
        subebtc: true,
        previousPrices: [],
        priceChanges: [], // Cambios de precio (-1 = baja, 1 = sube, 0 = igual)
        palpitar: false,
        supportedLanguages: [
          { label: 'English', value: 'en', flag: 'gb' },
          { label: 'Español', value: 'es', flag: 'es' }
        ],
        isDropdownOpen: false
    };
  },
  directives: {
    clickOutside,
  },
  created() {
    if (!this.prices.length) {
        this.fetchBitcoinPrices();
    }
  },
  mounted() {
    i18n.locale = this.locale;
  },
  beforeMount() {
    this.interval = setInterval(this.fetchBitcoinPrices, 60000);
  },
  beforeDestroy() {
   // clearInterval(this.interval);
  },
  methods: {
    ...mapActions(['setLocale']),
    changeLocale(locale) {
        i18n.locale = locale.value;
        this.setLocale(locale.value);
        this.isDropdownOpen = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    hide() {
        this.isDropdownOpen = false;
    },
    convertName(name){
      const names = {
        "bitcoin": "BTC",
        "bitcoin-cash": "BCH",
        "litecoin": "LTC",
        "binancecoin": "BNB",
        "dogecoin": "DOGE",
        "tether": "USDT",
        "tron": "TRX",
      };
      return names[name] || "No Name";
    },
    fetchBitcoinPrices() {
        axios.get(process.env.VUE_APP_API_URL + 'getPrices')
        .then((res)=>{
            this.previousPrices = [...this.prices];
            this.prices = res.data.prices;
            this.checkPriceChange();
            if (this.firstLoad) {
                this.firstLoad = false; // Desactiva la bandera después de la primera carga
            }
        })
        .catch((error)=>{
            console.error("Error fetching crypto prices:", error);
        })
        .finally(() => {
            this.loading = false; // Siempre oculta el efecto de carga al finalizar la llamada
        });
    },
    checkPriceChange() {
        if (this.prices.length === 0) return;

        // Iterar a través de todos los precios
        this.prices.forEach((current, index) => {
            const currentPrice = current.price;
            const lastPrice = this.previousPrices[index]?.price || currentPrice;

            if (currentPrice > lastPrice) {
                // Precio subió
                this.priceChanges[index] = 1;
                this.palpitar = true;
                setTimeout(() => {
                    this.palpitar = false;
                }, 3000);
            } else if (currentPrice < lastPrice) {
                // Precio bajó
                this.priceChanges[index] = -1;
                this.palpitar = true;
                setTimeout(() => {
                    this.palpitar = false;
                }, 3000);
            } else {
                // Precio se mantuvo igual
                this.priceChanges[index] = 0;
            }
        });

        // Actualizar `previousPrices` con los precios actuales
        this.previousPrices = [...this.prices];
    },
    /* checkPriceChange() {
        if (this.bitcoinPrices.length < 1) return;
        
        const currentPrice = this.bitcoinPrices[0].price;
        const lastPrice = this.previousPrices.length > 0 ? this.previousPrices[0].price : currentPrice;

        if (currentPrice > lastPrice) {
            this.subebtc = true;
            this.palpitar = true;
            setTimeout(() => {
                this.palpitar = false;
            }, 3000);
        } else if (currentPrice < lastPrice) {
            this.subebtc = false;
            this.palpitar = true;
            setTimeout(() => {
                this.palpitar = false;
            }, 3000);
        }
    } */
  },
  computed: {
    ...mapGetters(['locale', 'supportedLanguages2']), // Obtener el idioma seleccionado y los idiomas admitidos desde Vuex
    selectedLanguage: {
      get() {
        // Devolver el idioma seleccionado del array de idiomas admitidos según el estado de Vuex
        return this.supportedLanguages.find(lang => lang.value === this.locale);
      },
      set(value) {
        // Podemos añadir lógica aquí si necesitamos actualizar selectedLanguage desde el componente
      }
    },
    isSpecificRoute() {
          const specificRoutes = ['/my/dashboard', '/extra/rewards', '/extra/lucky', '/extra/mining','/eddie/admin'];
          return specificRoutes.includes(this.$route.path);
          //return this.$route.path === this.specificRoute;
      },
  },
  watch: {
    locale(newLocale) {
      this.selectedLanguage = this.supportedLanguages2.find(lang => lang.value === newLocale);
    }
  }
};
</script>
<style scoped>
.price-text {
  color: white;
  font-size: 12px;
  font-weight: bold;
}

#top-bar {
    height: 52px;
    line-height: 52px;
    border-bottom: 1px solid rgba(255, 255, 255, .1); /* Agregamos un borde inferior */
}

.text-center {
    text-align: center!important;
}

.palpitar {
    animation: palpitar 2s infinite;
}

@keyframes palpitar {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}

.bitcoin-prices-list {
  list-style: none;
  margin-left:15px;
  display: flex;
}

.bitcoin-prices-list li {
  margin-right: 20px;
}

.bitcoin-prices-list li:last-child {
  margin-right: 0;
}

.price-up-color {
    color: white;
}
.price-down-color {
    color: #e80707;
}

.arrow {
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-left: 3px;
}

.settings-part {
    display: flex;
}

.locale {
    width: 145px;
}

.locale {
    text-align: left;
    margin-right: 16px;
    display: inline-block;
    position: relative;
    margin-left: 25px;
    margin-top: 5px;
    margin-bottom: 12px;
    line-height: 1;
}

div.selectbox.up.open .field {
    border-radius: 0 0 4px 4px;
}

div.selectbox .dropdown .options .option:hover {
    background-color: #15888a40
}

div.selectbox .field {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 4px #23232329;
    font-size: 14px;
    border: 1px solid transparent;
    color: #000000;
    transition: .2s ease box-shadow;
    padding: 0 32px 0 16px;
    cursor: pointer;
    height: 39px;
    line-height: 39px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

div.selectbox .dropdown .options {
    overflow: hidden;
    overflow-y: auto;
    flex: 1;
}

div.selectbox .dropdown .options .option.selected {
    background-color: #3883dd;
    color: #fff;
}

div.selectbox .dropdown .options .option:hover {
    background-color: #3883dd40
}

.arrows {
    position: absolute;
    top: 11px;
    right: 12px;
    width: 15px;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
}

div.selectbox .arrows i {
    display: block;
    font-size: 12px;
    line-height: 6px;
    color: #cecdcd;
    position: relative;
    top: 3px;
    left: 5px;
}

.flex--no-wrap {
    flex-wrap: nowrap;
}

div.selectbox.open .field {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

div.selectbox {
    position: relative;
}

div.selectbox:focus .field {
    border: 1px solid #15888a;
    box-shadow: 0 5px 10px #fba3421a
}

div.selectbox.up .dropdown {
    border-radius: 0 0 4px 4px;
    box-shadow: 0 1px 4px #23232329;
}

div.selectbox .dropdown {
    position: absolute;
    background: #fff;
    z-index: 99999;
    min-width: 100%;
    box-shadow: 0 1px 4px #23232329;
    max-height: 0;
    overflow: hidden;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    transition: max-height 0.2s ease;
}

div.selectbox.open .dropdown {
    max-height: 360px; /* ajusta según sea necesario */
}

div.selectbox .dropdown .options .option {
    padding: 8px 16px;
    cursor: pointer;
    color: black;
    border-bottom: 1px solid #e0e0e0; /* añade un borde inferior a cada opción */
}

div.selectbox .dropdown .options .option:last-child {
    border-bottom: none; /* elimina el borde inferior de la última opción */
}

@keyframes shimmer {
    to {
        background-position: -200% 0;
    }
}

.skeleton {
     padding:15px;
     max-width: 300px;
     width: 120px;
     height: 40px;
     background-color: rgba(242, 242, 242, 0.2); /* Color de fondo gris claro con opacidad */
     color: transparent; /* Texto transparente para simular un esqueleto */
     margin-top: 5px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 4px;
     box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .2), 0 1px 8px 0 rgba(0, 0, 0, .12);
}

.h25{
    height: 25px !important;
}

.w50{
    width: 100% !important
}
.skeleton-left{
    flex:1;
}

.skeleton .line {
     height: 12px;
     margin-top:6px;
     border-radius: 4px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation:  shimmer 1.5s infinite linear;
}

@keyframes wave-lines {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}

.m10{
    margin-bottom: 10px !important;
}

@media (max-width: 991px){
    #top-bar {
        display:none
    }
}


.header-section-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

</style>