<template>
  <div>
    <div class="container mb32 mt-5">
      <div class="row">
        <div class="col-lg-3">
          <Sidebar/>
        </div>
        <div class="col-lg-9">
          <div class="content">
            
            <div class="panel shadow">

              <div class="container mt-3">
                <div class="row">
                  <div class="col-md-3">
                    <h3 class="mb16">{{ $t('overview') }}</h3>
                    <div class="box shadow">
                      <div class="total mined">
                        <small class="text-muted">{{$t('powerCommisions')}}</small>
                        <template v-if="isLoading">
                          <h1 class="pt8 pb16 balance skeleton-balance">
                              <div class="skeleton-left">
                                  <div class="line h25 w50 m10"></div>
                              </div>
                          </h1>
                        </template>
                        <template v-else>
                          <h1 class="pt8 pb16 balance">{{sumCommissions.toFixed(2)}} TH/s</h1>
                        </template>
                      </div>
                      <div class="total">
                        <small class="text-muted">{{$t('affiliates')}}</small>
                        <template v-if="isLoading">
                          <h1 class="pt8 pb16 balance skeleton-balance">
                              <div class="skeleton-left">
                                  <div class="line h25 w50 m10"></div>
                              </div>
                          </h1>
                        </template>
                        <template v-else>
                          <h1 class="pt8 pb16 balance">
                            <ICountUp
                                  :delay="delay"
                                  :endVal="totalReferralsCount"
                                  :options="options"
                                  @ready="onReady"
                                />
                          </h1>
                        </template>
                      </div>
                    </div>
                  </div>
                

                <div class="col-md-9">
              <h2>{{$t('referrals')}}</h2>
              <!-- Aquí mostramos el enlace de referencia del usuario -->
              <div>
                <div class="field-wrap input-undefined mb24">
                  <!-- Contenido de input-undefined -->

                  <div class="label-row">
                    <label>{{$t('referralLink')}}</label>
                  </div>
                  <div class="input-group medium copy-on-hover">
                    <span class="copy-feedback" 
                    :style="showMessage 
                    ? 
                    'display:block;' 
                    : 
                    'display:none'">
                    {{$t('copiedClipboard')}}
                    </span>
                    <input readonly="" v-on:focus="$event.target.select()" type="text" ref="myinput" class="copy-address" :value="'https://cloudhashx.com/sign-up?ref='+user.user.referral_code" maxlength="">
                  <button @click="copyRef" class="btn primary small copy" type="button">
                    <span>{{$t('copyBtn')}}</span>
                  </button>
                </div>
              </div>

              <div class="row">

              <div class="col-xl-4 col-lg-12 col-md-6 col-sm-12 col-12 mb-3">
                                <div class="shop_card_desc">
                                <div class="card">
                                <div class="widtget d-flex widget-six ">
                                    <div class="widget-uno-icon-container background-orange center">
                                    </div>
                                    <div class="p-3 flex-grow-1">
                                        <p class="m-2 h6">{{$t('level')}} I {{$t('affiliates')}}</p>
                                        <template v-if="isLoading">
                                          
                                          <h4 class="miner-color skeleton-balance">
                                            <div class="skeleton-left">
                                                <div class="line h20 w50 m10"></div>
                                            </div>
                                          </h4>
                                        </template>
                                        <template v-else>
                                          <p class="small text-muted mb-2">{{level1ReferralsCount}}</p>
                                        </template>
                                        <p class="ml-2"
                                            style="padding-top: 8px; border-top: 1px solid rgba(33, 40, 50, 0.3);">7% <span
                                                class="text-muted text-xs">{{$t('level')}} I {{$t('commissions')}}</span></p>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>

                            <div class="col-xl-4 col-lg-12 col-md-6 col-sm-12 col-12 mb-3">
                                <div class="shop_card_desc">
                                <div class="card">
                                <div class="widtget d-flex widget-six ">
                                    <div class="widget-uno-icon-container background-orange center">
                                    </div>
                                    <div class="p-3 flex-grow-1">
                                        <p class="m-2 h6">{{$t('level')}} II {{$t('affiliates')}}</p>
                                        <template v-if="isLoading">
                                          
                                          <h4 class="miner-color skeleton-balance">
                                            <div class="skeleton-left">
                                                <div class="line h20 w50 m10"></div>
                                            </div>
                                          </h4>
                                        </template>
                                        <template v-else>
                                          <p class="small text-muted mb-2">{{level2ReferralsCount}}</p>
                                        </template>
                                        <p class="ml-2"
                                            style="padding-top: 8px; border-top: 1px solid rgba(33, 40, 50, 0.3);">2% <span
                                                class="text-muted text-xs">{{$t('level')}} II {{$t('commissions')}}</span></p>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>

                            <div class="col-xl-4 col-lg-12 col-md-6 col-sm-12 col-12 mb-3">
                                <div class="shop_card_desc">
                                <div class="card">
                                <div class="widtget d-flex widget-six ">
                                    <div class="widget-uno-icon-container background-orange center">
                                    </div>
                                    <div class="p-3 flex-grow-1">
                                        <p class="m-2 h6">{{$t('level')}} III {{$t('affiliates')}}</p>
                                        <template v-if="isLoading">
                                          
                                          <h4 class="miner-color skeleton-balance">
                                            <div class="skeleton-left">
                                                <div class="line h20 w50 m10"></div>
                                            </div>
                                          </h4>
                                        </template>
                                        <template v-else>
                                          <p class="small text-muted mb-2">{{level3ReferralsCount}}</p>
                                        </template>
                                        <p class="ml-2"
                                            style="padding-top: 8px; border-top: 1px solid rgba(33, 40, 50, 0.3);">1% <span
                                                class="text-muted text-xs">{{$t('level')}} III {{$t('commissions')}}</span></p>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>

                      </div>
              <!-- Aquí mostramos un listado de usuarios referidos -->
              <div>
                <h3>{{$t('registeredReferrals')}}</h3>
                <div class="table-wrap relative shadow">
                  <div class="table-responsive table-container">
                    <table class="table-striped nh padding-normal">
                      <thead>
                          <tr>
                              <th scope="col">{{$t('username')}}</th>
                              <th scope="col" class="text-right">{{$t('level')}}</th>
                              <th scope="col" class="text-right">{{$t('amount')}}</th>
                              <th scope="col" class="text-right">{{$t('commissions')}}</th>
                          </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(referrals, index) in referralsData.data" :key="index">
                          <td>{{referrals.username}}</td>
                          <td class="text-right">{{referrals.level}}</td>
                          <td class="text-right">{{referrals.amount.toFixed(2)}} USD</td>
                          <td class="text-right">{{referrals.commission.toFixed(2)}}</td>
                        </tr>
                      </tbody>
                    </table>

                    <!-- <div v-else class="d-flex justify-content-center mt-4">
                        <p>{{$t('noData')}}</p>
                    </div> -->

                    <div class="overlay loader" v-if="isLoadingTable">
                      <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                    </div>
                  </div>
                </div>

                <vue-pagination  :pagination="referralsData"
                                @paginate="getReferrals()"
                                :offset="4">
                </vue-pagination>

              </div>
              
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
    </div>
  </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from './Footer.vue';
import Sidebar from './Sidebar.vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import VuePagination from '../components/Pagination.vue';
import axios from 'axios';
import ICountUp from 'vue-countup-v2';
export default {
  name: 'CloudhashFrontendPartners',
  components: { Footer, Sidebar, VuePagination, ICountUp },
  data() {
    return {
      firstLoad: true,
      showMessage: false,
      interval: null,
      level1ReferralsCount: 0,
      level2ReferralsCount: 0,
      level3ReferralsCount: 0,
      totalReferralsCount: 0,
      sumCommissions: 0,
      referralsData: {
          data: [],
          total: 0,
          per_page: 2,
          from: 1,
          to: 0,
          current_page: 1
      },
      offset: 4,
      previousreferralsData: null,
      isLoading: true,
      isLoadingTable: false,
      previousUserCount: 0,
      delay: 0,
      options: {
        separator: '',
        decimal: '.',
        prefix: '',
        suffix: ''
      }
    };
  },

  mounted() {
    this.getReferralData();
    this.getReferrals();
  },
  beforeMount() {
    this.interval = setInterval(this.getReferralData, 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    ...mapGetters({
          autenticado: 'user/autenticado',
          user: 'user/usuario'
      }),
  },

  methods: {
    getReferrals() {
      this.isLoadingTable = true;
      axios.get(process.env.VUE_APP_API_URL+`getReferrals?page=${this.referralsData.current_page}`)
      .then((res)=>{
            //console.log(res.data);
            this.referralsData = res.data;
            this.isLoadingTable = false;
        })
        .catch((error)=>{
          this.isLoadingTable = false;
            console.log(error);
        })
    },
    onReady(instance, CountUp) {
      if (this.previousUserCount !== this.totalReferralsCount) {
        instance.update(this.totalReferralsCount);
      }
    },
    getReferralData() {
      //this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL + 'getReferralData')
      .then((res) => {
        const newUserCount = res.data.totalReferralsCount;
        this.previousUserCount = this.totalReferralsCount;
        this.totalReferralsCount = newUserCount;
        this.level1ReferralsCount = res.data.level1ReferralsCount;
        this.level2ReferralsCount = res.data.level2ReferralsCount;
        this.level3ReferralsCount = res.data.level3ReferralsCount;
        this.totalReferralsCount  = res.data.totalReferralsCount;
        this.sumCommissions       = res.data.sumCommissions;
        
        /* this.previousreferralsData = {
            level1ReferralsCount: this.level1ReferralsCount,
            level2ReferralsCount: this.level2ReferralsCount,
            level3ReferralsCount: this.level3ReferralsCount,
            totalReferralsCount: this.totalReferralsCount,
            sumCommissions: this.sumCommissions
        };
        this.checkDataChange(); */
        if (this.firstLoad) {
            this.firstLoad = false; // Desactiva la bandera después de la primera carga
        }
      })
      .catch((error)=>{
            console.error("Error fetching Data:", error);
      })
      .finally(() => {
          this.isLoading = false; // Siempre oculta el efecto de carga al finalizar la llamada
      });
    },
    checkDataChange() {
      // Comparamos los datos previos con los datos actuales
      if (this.previousreferralsData !== null) {
            if (this.level1ReferralsCount > this.previousreferralsData.level1ReferralsCount) {
                this.animateCounter('level1ReferralsCount');
            }
            if (this.level2ReferralsCount > this.previousreferralsData.level2ReferralsCount) {
                this.animateCounter('level2ReferralsCount');
            }
            if (this.level3ReferralsCount > this.previousreferralsData.level3ReferralsCount) {
                this.animateCounter('level3ReferralsCount');
            }
            if (this.totalReferralsCount > this.previousreferralsData.totalReferralsCount) {
                this.animateCounter('totalReferralsCount');
            }
            if (this.sumCommissions > this.previousreferralsData.sumCommissions) {
                this.animateCounter('sumCommissions');
            }
        }
        // Actualizamos los datos previos
        this.previousreferralsData = {
            level1ReferralsCount: this.level1ReferralsCount,
            level2ReferralsCount: this.level2ReferralsCount,
            level3ReferralsCount: this.level3ReferralsCount,
            sumCommissions: this.sumCommissions
        };
    },
    animateCounter(counter) {
        // Guardamos el valor anterior
        const oldValue = this[counter] - 1;
        // Hacemos el conteo hacia arriba con una pequeña animación
        const interval = setInterval(() => {
            this[counter]++;
            // Si llegamos al valor actual, detenemos el intervalo
            if (this[counter] === oldValue) {
                clearInterval(interval);
            }
        }, 1000);
        
    },
    copyRef() {
        this.showMessage = true;
        this.$refs.myinput.focus();
        document.execCommand('copy');
        setTimeout(() => {
            this.showMessage = false
        }, 5000)
    },
  },
};
</script>
<style scoped>
.dashboard-container {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 32px;
}

.enable2fa-banner {
    position: relative;
    box-shadow: 0 1px 4px #23232329;
    border-radius: 4px;
    padding: 16px 24px;
    line-height: 1.5;
    background-color: #fff;
}

.enable2fa-banner .text {
    font-size: 14px;
}

.enable2fa-banner .text-bold {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 4px;
}

.mb24 {
    margin-bottom: 24px;
}

.btn.primary.normal {
    box-shadow: 0 12px 4px -10px #19345D66;
}

.btn.fluid {
    width: 100%;
}

.btn.normal {
    padding: 13px 24px 12px;
    font-size: 12px;
    letter-spacing: .5px;
}

.btn.primary {
    background: linear-gradient(90deg,#19345D,#19344D);
    color: #fff;
    box-shadow: 0 20px 4px -18px #19345D52;
}

.btn {
    border-radius: 4px;
    border: 0;
    padding: 10px 12px 9px;
    transition: box-shadow .2s ease,border-radius .2s ease,border-radius .2s ease,background-color .2s ease,color .2s ease;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
}

#content {
    flex: 1 0 auto;
    position: relative;
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 16px;
    padding-left: 16px;
}

.content {
    box-shadow: none;
    background: rgb(255, 255, 255);
    flex: 1 1 0%;
    border-radius: 4px;
}

.box {
    box-shadow: 0 1px 4px #23232329;
    border-radius: 4px;
    margin-bottom: 16px;
    background: #fff;
}

.box .total {
    padding: 24px 16px 0;
    border-bottom: 1px solid #EEE;
    position: relative;
}

.box .available {
    padding: 24px 16px 12px;
    position: relative;
}

.box i.info {
    position: absolute;
    top: 12px;
    right: 12px;
}

.box .total h1 {
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -.1px;
    line-height: 40px;
}

.mined {
  background-color: rgb(25, 52, 93);
  color: white;
  border-radius: 4px;
}

.box .available h1 {
  font-size: 24px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -.1px;
    line-height: 40px;
}

.mb40 {
    margin-bottom: 40px;
}

.mb16 {
    margin-bottom: 16px;
}

.mt16 {
    margin-top: 16px;
}

.ml32 {
  margin-left: 32px;
}

.ml24 {
  margin-left: 18px;
}

.fs12 {
  font-size: 12px
}

.text-toggle {
  color: #fba342;
}

.input-group.copy-on-hover .copy {
    display: none
}

.input-group.copy-on-hover:hover .copy {
    display: block
}

.input-group input:disabled[type=copy], .input-group input:read-only[type=copy] {
    cursor: default;
}

.input-group.auth input, .input-group.medium input {
    padding: 18px 14px;
}

.input-group input:disabled, .input-group input:read-only {
    box-shadow: none;
    border: 1px solid #EEE;
}

.input-group .copy {
    position: absolute;
    right: 8px;
    top: 8px;
}

.btn.small {
    padding: 6px 23px;
    font-size: 10px;
    letter-spacing: .4px;
}

.input-group.medium .copy {
    top: 16px;
    right: 16px;
}

.copy-feedback {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    line-height: 20px;
    padding: 13px 0;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .05em;
    text-align: center;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    border: 1px solid rgba(211, 224, 243, 0.5);
    color: #00ba94;
}

.copy-address {
    border: none;
    color: #495463;
    line-height: 20px;
    padding: 12px 50px 12px 40px;
    border-radius: 4px;
    border: 1px solid rgba(211, 224, 243, 0.5);
    width: 100%;
    background: #fff;
}

.table {
    box-shadow: 0 1px 4px #23232329;
    margin: 0 auto 80px;
}

.table-container {
    position: relative;
    min-height: 90px;
    overflow-x: auto;
}

table.nh {
    width: 100%;
    border: none;
}

table.nh thead {
    background: #fff;
}

table.nh th:first-child {
    padding-left: 32px;
}

.table th {
    color: #959595;
}

table.nh th {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.6;
    font-weight: 400;
    padding: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

table.nh tbody tr:nth-child(odd) {
    background-color: #fcfcfc;
}

table.nh tbody tr:first-child {
    box-shadow: inset 1px 4px 6px -5px #23232373;
}

table.nh tbody tr {
    background-color: #fff;
}

table.nh tbody tr td:first-child {
    padding-left: 32px;
}

table.nh tbody tr td {
    padding: 12px 16px;
    border-bottom: 1px solid #EEE;
    font-size: 12px;
    line-height: 1.33;
    color: #3a3939;
}

.skeleton-balance .line {
     height: 12px;
     margin-top: 6px;
     margin-bottom: 6px;
     border-radius: 4px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation:  shimmer 1.5s infinite linear;
}

.skeleton-left {
    flex:1;
}

.skeleton-balance {
     padding:15px 15px 15px 0;
     max-width: 300px;
     width: 135px;
     height: 10px;
     color: transparent; /* Texto transparente para simular un esqueleto */
     margin-top: 5px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 4px;
}
</style>