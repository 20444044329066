<template>
  <div>
    <keep-alive>
      <BitcoinPrice key="bitcoin-price" />
    </keep-alive>
  </div>
</template>

<script>
import BitcoinPrice from '../components/BitcoinPrice.vue';
export default {
  name: 'CloudhashFrontendAbout',
  components: { BitcoinPrice },
  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>