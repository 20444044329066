// src/plugins/axios.js
import axios from 'axios';
import { store } from '../store'; // Asegúrate de que este sea el path correcto a tu Vuex store
// eslint-disable-next-line
import router from '@/router'; // Asegúrate de que este sea el path correcto a tu router

// Configuración global de Axios para manejar la expiración del token
axios.interceptors.response.use(
    response => {
        return response;  // Si la respuesta es exitosa, simplemente la devolvemos
    },
    error => {
        // Verificamos si el error es de token expirado (status 401)
        if (error.response && error.response.status === 401) {
            const errorMessage = error.response.data.message || '';
            // Si el token ha expirado, redirigimos al usuario al login
            if (errorMessage === 'Token has expired' || errorMessage === 'Unauthenticated.') {
                store.dispatch('user/logout');  // Acción para limpiar el estado del usuario
                router.push("/login").catch(() => { });
            }
        }

        return Promise.reject(error);
    }
);