<template>
  <div>

    <div class="toast-container position-fixed top-50 start-50 translate-middle">
          <div class="toast align-items-center border-0 fade" :class="{'show': showToast ,'text-bg-danger': showError }" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
              <div class="toast-body">
                <p class="text-center">{{message}}</p>
              </div>
              <button type="button" @click="closeToast" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
          </div>
      </div>

      <div class="toast-container position-fixed top-50 start-50 translate-middle">
          <div class="toast align-items-center border-0 fade" :class="{'show': showToastSc ,'text-bg-success': showSuccess }" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
              <div class="toast-body">
                <p class="text-center">{{message}}</p>
              </div>
              <button type="button" @click="closeToastSc" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
          </div>
      </div>


    <div class="container mb32 mt-5">
      <div class="row">
        <div class="col-lg-3">
          <Sidebar/>
        </div>
        <div class="col-lg-9">
          <div class="content">
            <div class="panel shadow">
              <div class="mb32">
                <h1 class="text-bold mb8">{{$t('upgradePower')}}</h1>
                <small class="text-muted mb32">{{$t('upgradeSmallDesc')}}</small>
              </div>
              <div class="container mt-3">
                <div class="row">
                  <div class="col-md-3">
                    <h3 class="mb16">{{ $t('overview') }}</h3>
                    <div class="box shadow">
                      <div class="total mined">
                        <small class="text-muted">{{$t('balanceUser')}} <span class="text-secondary pointer" @click="changeCurrency">{{currency}} <i class="fa fa-sort"></i></span></small></small>
                        <h1 class="pt8 pb16 balance">
                          <template v-if="currency === 'USD'">
                            {{balance}} {{currency}}
                          </template>
                          <template v-else>
                            <span class="fs16">{{balanceBTC}} {{currency}}</span>
                          </template>
                        </h1>
                      </div>
                      <div class="total mined">
                        <small class="text-muted">{{$t('totalPower')}}</small>
                        <template v-if="showSkeleton">
                          <h1 class="pt8 pb16 balance skeleton-balance">
                              <div class="skeleton-left">
                                  <div class="line h25 w50 m10"></div>
                              </div>
                          </h1>
                        </template>
                        <template v-else>
                          <h1 class="pt8 pb16 balance">{{power}} {{ convertPower(power)}}</h1>
                        </template>

                      </div>
                      <div class="total">
                        <small class="text-muted">{{$t('purchasedPower')}}</small>
                        <template v-if="showSkeleton">
                          <h1 class="pt8 pb16 balance skeleton-balance">
                              <div class="skeleton-left">
                                  <div class="line h25 w50 m10"></div>
                              </div>
                          </h1>
                        </template>
                        <template v-else>
                          <h1 class="pt8 pb16 balance">{{purchasedPower.toFixed(4)}} {{ convertPower(purchasedPower)}}</h1>
                        </template>
                      </div>
                      <div class="total">
                        <small class="text-muted">{{$t('powerCost')}}</small>
                        <template v-if="showSkeleton">
                          <h1 class="pt8 pb16 balance skeleton-balance">
                              <div class="skeleton-left">
                                  <div class="line h25 w50 m10"></div>
                              </div>
                          </h1>
                        </template>
                        <template v-else>
                        <h1 class="pt8 pb16 balance">
                          <template v-if="currency === 'USD'">
                            {{purchasedCost}}  {{currency}}
                          </template>
                          <template v-else>
                            <span class="fs16">{{purchasedCostBTC}} {{currency}}</span>
                          </template>
                        </h1>
                      </template>
                      </div>
                      <div class="total">
                        <small class="text-muted">{{$t('purchaseQuantity')}}</small>
                        <template v-if="showSkeleton">
                          <h1 class="pt8 pb16 balance skeleton-balance">
                              <div class="skeleton-left">
                                  <div class="line h25 w50 m10"></div>
                              </div>
                          </h1>
                        </template>
                        <template v-else>
                          <h1 class="pt8 pb16 balance">{{purchasedCount}}</h1>
                        </template>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-9">
                    <!-- Aquí mostramos como podrá comprar potencia el usuario -->
                    <h3 class="mb16">{{$t('upgradePower')}}</h3>
                    <hr>

                    <div class="row">
                      <div class="col">
                        <button :class="{ 'primary': buyVia === 'btc', 'secondary': buyVia !== 'btc' }" class="btn medium fluid" @click="setBuyVia('btc')">BUY VIA CRYPTO</button>
                      </div>  
                      <div class="col">
                        <button :class="{ 'primary': buyVia === 'balance', 'secondary': buyVia !== 'balance' }" class="btn medium fluid" @click="setBuyVia('balance')">{{$t('buyViaBalanceBtn')}}</button>
                      </div>  
                    </div>
                    <div class="row mt-4">
                      <template v-if="buyVia === 'btc'">
                        <div class="col-6 d-grid gap-2 d-md-flex justify-content-md-start">
                          <p class="text-muted">{{$t('minimum')}}</p>
                        </div>
                        <div class="col-6 d-grid gap-2 d-flex justify-content-md-end">
                          <template v-if="showSkeleton">
                            <h4 class="miner-color skeleton-balance">
                              <div class="skeleton-left">
                                  <div class="line h20 w50 m10"></div>
                              </div>
                            </h4>
                          </template>
                          <template v-else>
                            <p><strong>{{ minimumHash }} TH/s</strong></p>
                          </template>
                        </div>
                        <div class="col-6 d-grid gap-2 d-flex justify-content-md-start">
                          <p class="text-muted">{{$t('maximum')}}</p>
                        </div>
                        <div class="col-6 d-grid gap-2 d-flex justify-content-md-end">
                          <p><strong>{{ maximumHash.toFixed(2) }} PH/s</strong></p>
                        </div>

                        <div class="field-wrap input-undefined">
                          <div class="label-row">
                            <label>{{$t('depositIn')}} </label>
                          </div>
                          <div class="selectbox large deposit-to" :class="{ open: isDropdownOpen }" tabindex="0">
                              <div class="field" @click="toggleDropdown" aria-haspopup="true" v-click-outside="hide" :aria-expanded="isDropdownOpen ? 'true' : 'false'">
                                  <div class="arrows">
                                      <i class="fa fa-caret-up"></i>
                                      <i class="fa fa-caret-down"></i>
                                  </div>
                                  <span>
                                    <img :src="getCurrencyImage(selectedCurrency)">
                                    <div class="name">{{ selectedCurrency.label }}</div>
                                  </span>
                              </div>
                              <div class="dropdown flex flex--no-wrap">
                                  <div class="options" role="listbox">
                                      <div class="option" 
                                      :class="selectedCurrency.value === currency.value ? 'selected' : ''" 
                                      role="option" 
                                      :aria-selected="selectedCurrency.value === currency.value ? true :false" 
                                      v-for="(currency, index) in supportedCurrencies" 
                                      :key="index"
                                      @click="changeCurrency2(currency)"
                                      >
                                      <img :src="getCurrencyImage(currency)">
                                      <div class="name">{{ currency.label }}</div>    
                                      
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>



                        <div class="field-wrap input-undefined relative mb-3">
                          <div class="input-group2 large">
                            <div class="input-group-prepend">
                              <span class="">USD</span>
                            </div>
                            <input type="text" class="form-control" 
                            v-model="inputAmount"
                            @input="updateCalculator"
                            @keypress="restrictToNumbers"
                            >
                          </div>
                        </div>
                        
                        <div class="row">
                          <div class="col-6 d-grid gap-2 d-md-flex justify-content-md-start">
                            <p><strong>{{$t('minerHashRate')}}</strong></p>
                          </div>
                          <div class="col-md-6 d-grid gap-2 d-md-flex justify-content-md-end">
                            <template v-if="showSkeleton">
                            <h4 class="miner-color skeleton-balance">
                              <div class="skeleton-left">
                                  <div class="line h20 w50 m10"></div>
                              </div>
                            </h4>
                          </template>
                          <template v-else>
                            <p><strong> {{ minerHashRate }} {{ hashUnit }} </strong> <small class="text-muted">(≈ {{formatedValue}} BTC)</small></p>
                          </template>
                          </div>
                        </div>  
                        <p class="mt-3"><strong>Send {{coinName}} to:</strong></p>
                        <!-- <p class="mt-3"><strong>{{$t('sendBitcointo')}}</strong></p> -->
                        <div class="flex justify-content-center">
                          <template v-if="isLoading">
                            <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                          </template>
                          <template v-else>
                            <div class="input-group medium copy-on-hover">
                                <span class="copy-feedback" 
                                :style="showMessage 
                                ? 
                                'display:block;' 
                                : 
                                'display:none'">
                                {{$t('copiedClipboard')}}
                                </span>
                                <input readonly="" v-on:focus="$event.target.select()" type="text" ref="myinput" class="copy-address" :value="addresBtc" maxlength="">
                              <button @click="copyRef" class="btn primary small copy" type="button">
                                <span>{{$t('copyBtn')}}</span>
                              </button>
                            </div>
                            <div class="mt-3">
                              <qrcode-vue :value="addresBtc" size="250" level="H"></qrcode-vue>
                            </div>
                          </template>
                          <div class="disclaimer mt-5">
                              <p><strong>{{ $t('disclaimerUpgrade') }}</strong> {{ $t('disclaimerDesc') }} </p>
                          </div>
                          <p class="mt-3"> {{ $t('upgradeDisclaimer') }} </p>
                        </div>
                      </template>
                      <template v-else>
                        <div class="col-6 d-grid gap-2 d-md-flex justify-content-md-start">
                          <p class="text-muted">{{$t('minimum')}}</p>
                        </div>
                        <div class="col-6 d-grid gap-2 d-md-flex justify-content-md-end">
                          <p><strong>{{ minimumReinvestHash }} TH/s</strong> <small class="text-muted">(≈ $ 5.00)</small></p>
                        </div>
                        <div class="col-6 d-grid gap-2 d-md-flex justify-content-md-start">
                          <p class="text-muted">{{$t('maximum')}}</p>
                        </div>
                        <div class="col-6 d-grid gap-2 d-md-flex justify-content-md-end">
                          <p><strong>{{ maximumHash.toFixed(2) }} PH/s</strong></p>
                        </div>
                        <div class="field-wrap input-undefined relative mb-3">
                          <div class="input-group2 large">
                            <div class="input-group-prepend">
                              <span class="">USD</span>
                            </div>
                            <input type="text" class="form-control" 
                            v-model="inputReinvestAmount"
                            @input="reinvestCalculator"
                            @keypress="restrictToNumbers"
                            >
                            <div class="use-max">
                              <button class="btn primary small outline" @click="useMax">
                                <span>{{$t('useMaxBtn')}}</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <p><strong>{{$t('minerHashRate')}}</strong></p>
                          </div>
                          <div class="col-md-6 d-grid gap-2 d-md-flex justify-content-md-end">
                            <p><strong> {{ minerReinvestHashRate }} {{ hashUnitReinvest }} </strong> <small class="text-muted">(≈ {{formatedReinvestValue}} BTC)</small></p>
                          </div>
                        </div>
                        <p class="mt-3">{{$t('balanceDisclaimer')}}</p>
                        <div class="row mt24">

                          <button class="btn primary normal flex__fill-gap ml-2" @click="reinvest" :disabled="isSubmitting">
                              <span v-if="!isSubmitting">{{$t('reinvestBtn')}}</span>
                              <span v-else>
                                <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                                {{ $t('loggingIn') }}...
                              </span>
                          </button>
                        </div>

                      </template>

                    </div>
                    

                    

                  </div>

                </div><!-- row -->
              </div>


              <div class="col mt-5">
          <h3>{{$t('yourUpgrades')}}</h3>
          <div class="table-wrap relative shadow">
            <div class="table-responsive table-container">
              <table class="table-striped nh padding-normal" v-if="upgradeData.data.length > 0">
                <thead>
                    <tr>
                        <th scope="col">{{$t('transaction')}}</th>
                        <th scope="col" class="text-right">{{$t('method')}}</th>
                        <th scope="col" class="text-right">{{$t('status')}}</th>
                        <th scope="col" class="text-right">{{$t('hashRate')}}</th>
                        <th scope="col" class="text-right">{{$t('amount')}}</th>
                        <th scope="col" class="text-right">{{$t('dateTime')}}</th>
                    </tr>
                </thead>
                <tbody>
                  <tr v-for="(upgrade, index) in upgradeData.data" :key="index">
                    <td>
                        <template v-if="upgrade.type === 1 || upgrade.type === 2">
                          <span>{{ upgrade.type === 0 ? 'Bitcoin Payment' : (upgrade.type === 1 ? 'Balance Payment' : 'Commission Payment') }}</span>
                        </template>
                        <template v-else>
                          <a :href="'https://www.blockchain.com/explorer/transactions/btc/' + upgrade.transaction_id" target="_blank">
                                {{ upgrade.transaction_id.slice(0, 20) }}...
                              </a>
                        </template>
                          
                      
                    </td>
                    <td class="text-right">
                        {{ upgrade.type === 0 ? 'Bitcoin Payment' : (upgrade.type === 1 ? 'Balance Payment' : 'Commission Payment') }}
                    </td>
                    <td class="text-right">
                        <span v-if="upgrade.status === 0" class="text-primary">{{$t('pending')}}</span>
                        <span v-else-if="upgrade.status === 1" class="text-success">{{$t('completed')}}</span>
                        <span v-else-if="upgrade.status === 2" class="text-warning">{{$t('processing')}}</span>
                    </td>
                    <td class="text-right">{{ upgrade.power }} {{ convertPower(upgrade.power)}}</td>
                    <td class="text-right">{{ upgrade.amount }} <span class="text-muted">BTC</span> ~ {{upgrade.usd.toFixed(2)}} <span class="text-muted">USD</span></td>
                    <td class="text-right">{{ formatDate(upgrade.created_at) }}</td>
                  </tr>
                </tbody>
              </table>

              <div v-else class="d-flex justify-content-center mt-4">
                  <p>{{$t('noData')}}</p>
              </div>

              <div class="overlay loader" v-if="isLoadingTable">
                <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
              </div>


            </div>
          </div>

          <vue-pagination v-if="upgradeData.data.length > 0"  :pagination="upgradeData"
                                @paginate="getUpgrades()"
                                :offset="4">
                </vue-pagination>


        </div>


            </div><!-- panel -->
          </div>

          


        </div>

        

      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from './Footer.vue';
import Sidebar from './Sidebar.vue';
import QrcodeVue from 'qrcode.vue';
import axios from 'axios';
import VuePagination from '../components/Pagination.vue';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import clickOutside from '../directives/clickOutside';
export default {
  name: 'CloudhashFrontendUpgrade',
  components: { Footer, Sidebar, VuePagination, QrcodeVue },
  data() {
    return {
      coinName: 'Bitcoin (BTC)',
      coinType: 'BTC',
      selectedCurrency: { label: 'Bitcoin (BTC)', value: 'BTC', image: 'btc.png' },
      supportedCurrencies: [
        { label: 'Bitcoin (BTC)', value: 'BTC', image: 'btc.png' },
        { label: 'Tether (USDT TRC20)', value: 'USDT_TRC20', image: 'usdt.png' },
        { label: 'Tron (TRX)', value: 'TRX', image: 'trx.png' },
        { label: 'Dogecoin (DOGE)', value: 'DOGE', image: 'doge.png' },
        { label: 'BinanceCoin (BNB)', value: 'BNB', image: 'bnb.svg' },
        { label: 'Bitcoin cash (BCH)', value: 'BCH', image: 'bch.png' },
        { label: 'Litecoin (LTC)', value: 'LTC', image: 'ltc.png' }
      ],
      isDropdownOpen: false,
      inputReinvestAmount: 5,
      currency: 'USD',
      minimumReinvestHash: 0,
      minimumHash: 0,
      maximumHash: 50.10,
      minerHashRate: 0,
      minerReinvestHashRate: 0,
      hashUnit: 'TH/s',
      hashUnitReinvest: 'TH/s',
      isLoading: false,
      isLoadingTable: false,
      isSubmitting: false,
      buyVia: 'btc',
      inputAmount: 20,
      showMessage: false,
      addresBtc: '',
      upgradeData: {
        data: [],
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1
      },
      offset: 4,
      btcPrice: 0,
      btcValue: 0,
      btcReinvestValue: 0,
      balanceBTC: 0,
      showSkeleton: false,
      purchasedCount: 0,
      totalPower: 0,
      purchasedCost: 0,
      purchasedCostBTC: 0,
      purchasedPower: 0,
      message: '',
      showToast: false,
      showError: false,
      showSuccess: false,
      showToastSc: false,
      hashReinvest:0
    };
  },
  computed: {
    formatedValue() {
      return this.btcValue.toFixed(8);
    },
    formatedReinvestValue() {
      return this.btcReinvestValue.toFixed(8);
    },
    ...mapGetters({
            balance: 'user/balance',
            power: 'user/power'
        }),
  },
  directives: {
    clickOutside,
  },
  mounted() {
    this.getUpgrades();
    this.getBtcPrice();
    this.getPurchasedData();
    this.getBitcoinAddress();
    this.$store.dispatch('user/fetchSaldo');
  },
  created() {
    //this.getBtcPrice();
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    getCurrencyImage(currency) {
        if (currency.value === 'BNB') {
            return require(`@/assets/images/currencies/${currency.image}`);
        } else {
            return require(`@/assets/images/currencies/${currency.image.toLowerCase()}`);
        }
    },
    changeCurrency2(currency) {
      this.selectedCurrency = currency;
      this.coinType = currency.value;
      this.coinName = currency.label;
      this.isDropdownOpen = false;
      this.getBitcoinAddress();
    },
    hide() {
        this.isDropdownOpen = false;
    },
    closeToastSc() {
      this.showToastSc = false;
      this.showSuccess = false;
    },
    closeToast() {
      this.showToast = false;
      this.showError = false;
    },
    getBitcoinAddress() {
      this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL + 'getBitcoinAddress', {
          params: {
            coinType: this.coinType
          }
      })
      .then((res)=>{
        this.addresBtc = res.data;
        this.isLoading = false;
      })
      .catch((error)=>{
        console.log('error getting DepositAddress',error.response.data);
        this.isLoading = false;
      })
    },
    getPurchasedData() {
      this.showSkeleton = true;
      axios.get(process.env.VUE_APP_API_URL + 'getPurchasedData')
      .then((res)=>{
        //this.totalPower = res.data.power;
        this.purchasedCount = res.data.purchasedCount;
        this.purchasedCost = res.data.purchasedCost.toFixed(2);
        this.purchasedPower = res.data.purchasedPower;
        this.showSkeleton = false;
      })
      .catch((error)=>{
        console.error("Error fetching Data:", error);
      })
    },
    changeCurrency() {
      this.currency = this.currency === 'USD' ? 'BTC' : 'USD';

      if (this.currency === 'BTC') {
          // Convertir el saldo a BTC si la moneda es BTC
          this.balanceBTC = (this.balance / this.btcPrice).toFixed(8);
          this.purchasedCostBTC = (this.purchasedCost / this.btcPrice).toFixed(8);
      } 
    },
    reinvest() {
      // Verificar la conexión con el servidor
      if (!navigator.onLine) {
          // Mostrar un mensaje de error si no hay conexión a internet
          this.showToast = true;
          this.showError = true;
          this.message = 'No internet connection available. Please try again later.';
          setTimeout(() => {
              this.showToast = false;
              this.showError = false;
              this.message = '';
          }, 5000);
          return;
      }

      // Validar si el usuario ha ingresado un valor válido
      const amountInDollars = parseFloat(this.inputReinvestAmount);
      if (amountInDollars < 5 || isNaN(amountInDollars)) {
          // Mostrar un mensaje de error o realizar alguna acción
          this.showToast = true;
          this.showError = true;
          this.message = 'Invalid Reinvest value';
          setTimeout(()=>{
              this.showToast = false;
              this.showError = false;
              this.message = '';
            },5000);
          return;
      }

      // Verificar si el valor ingresado es un número válido y mayor que cero
      if (isNaN(amountInDollars) || amountInDollars <= 0) {
          // Mostrar un mensaje de error o realizar alguna acción
          this.showToast = true;
          this.showError = true;
          this.message = 'Please enter a valid amount greater than zero';
          setTimeout(() => {
              this.showToast = false;
              this.showError = false;
              this.message = '';
          }, 5000);
          return;
      }

      // Verificar si el monto a reinvertir es mayor que el saldo disponible
      if (amountInDollars > this.balance) {
          // Mostrar un mensaje de error si el monto es mayor que el saldo
          this.showToast = true;
          this.showError = true;
          this.message = 'Insufficient balance';
          setTimeout(() => {
              this.showToast = false;
              this.showError = false;
              this.message = '';
          }, 5000);
          return;
      }


      const amountBtc = (amountInDollars / this.btcPrice).toFixed(8);

      // Crear un objeto con los datos de reinversión
      const reinvestData = {
          usd: amountInDollars,
          hash: this.hashReinvest,
          amount: amountBtc
      };

      // Hacer la solicitud HTTP al backend
      this.isSubmitting = true; // Mostrar spinner de carga
      axios.post(process.env.VUE_APP_API_URL+'reinvest', reinvestData)
          .then(response => {
              // Manejar la respuesta del backend
              //console.log(response.data);
              this.showToastSc = true;
              this.showSuccess = true;
              this.message = response.data.message;
              this.isSubmitting = false; // Ocultar spinner de carga
              this.$store.dispatch('user/fetchSaldo');
              this.getUpgrades();
              this.getPurchasedData();
              this.inputReinvestAmount = 5;
              this.reinvestCalculator();
              setTimeout(()=>{
              this.showToastSc = false;
              this.showSuccess = false;
              this.message = '';
            },5000);
          })
          .catch(error => {
              // Manejar cualquier error de la solicitud
              if (error.response.status === 422) {
                if (typeof error.response.data.error === 'string') {
                  this.message = error.response.data.error;
                  this.showToast = true;
                  this.showError = true;
                  this.isSubmitting = false;
                  setTimeout(()=>{
                    this.showToast = false;
                    this.showError = false;
                    this.message = '';
                  },5000);
                } 
              }
          });
    },
    getBtcPrice() {
      axios.get(process.env.VUE_APP_API_URL+'getBtcPrice')
      .then((res)=>{
        this.btcPrice = res.data.price;
        this.updateCalculator();
        this.reinvestCalculator();
      })
      .catch((error)=>{
        console.log('error getting BTC Price',error.response.data);
      })
    },
    restrictToNumbers(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        if (!/^[0-9]+$/.test(keyValue)) {
            event.preventDefault();
        }
    },
    convertToTHS(amount) {
        // Convertir a TH/s
        // Puedes ajustar esta función según las unidades que uses
        // GH/s, TH/s, PH/s, EH/s
        // Por ejemplo, si inputAmount es en PH/s, necesitas multiplicar por 1000 para obtener TH/s
        const hashUnits = ['GH/s', 'TH/s', 'PH/s', 'EH/s']; // Unidades de hash
        let index = 1; // Índice de TH/s por defecto
        
        while (amount >= 1000 && index < hashUnits.length - 1) {
            amount /= 1000;
            index++;
        }
        
        return {
            hashValue: amount,
            hashUnit: hashUnits[index]
        };
    },
    convertPower(amount) {
        // Convertir a TH/s
        // Puedes ajustar esta función según las unidades que uses
        // GH/s, TH/s, PH/s, EH/s
        // Por ejemplo, si inputAmount es en PH/s, necesitas multiplicar por 1000 para obtener TH/s
        const hashUnits = ['GH/s', 'TH/s', 'PH/s', 'EH/s']; // Unidades de hash
        let index = 1; // Índice de TH/s por defecto
        if(amount === 0){
          index = 0;
        }
        
        while (amount >= 1000 && index < hashUnits.length - 1) {
            amount /= 1000;
            index++;
        }
        
        return hashUnits[index];
    },
    useMax() {
      if (confirm('Are you sure you want to reinvest your entire balance?')) {
        this.inputReinvestAmount =  this.balance;
        this.reinvestCalculator();
      }
    },
    reinvestCalculator() {
      const amountInDollars = parseFloat(this.inputReinvestAmount);
      const unitPrice = 0.21; // Valor por defecto
      const minInvestment = 5; // Mínimo de reinversión en dólares
      let hashRate = 0;
      hashRate = (unitPrice * amountInDollars).toFixed(2);

      this.hashReinvest = hashRate;

      // Convertir el valor ingresado a TH/s
      let { hashValue, hashUnit } = this.convertToTHS(hashRate);

      //console.log(hashValue);
      if(isNaN(amountInDollars)){
        this.minerReinvestHashRate = 0.00;
      } else {

        this.minerReinvestHashRate = hashValue;
        this.hashUnitReinvest = hashUnit; // Unidad de hash
      }
      
      this.minimumReinvestHash = (unitPrice * minInvestment).toFixed(2);

      if (this.btcPrice) {
        if(isNaN(amountInDollars)){
          this.btcReinvestValue = 0;
        } else {
          this.btcReinvestValue = amountInDollars / this.btcPrice;
        }
      }
      
    },
    updateCalculator() {
        const inputAmount = this.inputAmount; // Valor por defecto
        const unitPrice = 0.21; // Valor por defecto
        const minInvestment = 20;
        let hashRate = 0;
        hashRate = (unitPrice * inputAmount).toFixed(2);

        // Convertir el valor ingresado a TH/s
        let { hashValue, hashUnit } = this.convertToTHS(hashRate);

        //console.log(hashValue);
        this.minerHashRate = hashValue;
        this.hashUnit = hashUnit; // Unidad de hash
        this.minimumHash = (unitPrice * minInvestment).toFixed(2);
        
        if (this.btcPrice) {
            this.btcValue = inputAmount / this.btcPrice;
        }
    },
    formatDate(date) {
        return moment(date).format('DD MMM YYYY HH:mm');
    },
    setBuyVia(method) {
        this.buyVia = method;
    },
    copyRef() {
        this.showMessage = true;
        this.$refs.myinput.focus();
        document.execCommand('copy');
        setTimeout(() => {
            this.showMessage = false
        }, 5000)
    },
    getUpgrades() {
      this.isLoadingTable = true;
      axios.get(process.env.VUE_APP_API_URL+`getUpgrades?page=${this.upgradeData.current_page}`)
      .then((res)=>{
        this.upgradeData = res.data;
        this.isLoadingTable = false;
      })
      .catch((error)=>{
        console.log('error getting upgrades data',error.response.data);
        this.isLoadingTable = false;
      })
    },
  },
};
</script>
<style scoped>
.mined {
  background-color: #002BAE;
  color: white;
  border-radius: 4px;
}

.btn.primary:hover:not(.disabled) {
    background: linear-gradient(90deg,#19345D,#19345D);
    box-shadow: 0 24px 12px -19px #19345D8f
}

.btn.primary.normal {
    box-shadow: 0 12px 4px -10px #19345D66;
}

.btn.fluid {
    width: 100%;
}

.btn.normal {
    padding: 13px 24px 12px;
    font-size: 12px;
    letter-spacing: .5px;
}

.btn.primary {
    background: linear-gradient(90deg,#19345D,#19344D);
    color: #fff;
    box-shadow: 0 20px 4px -18px #19345D52;
}

.btn {
    border-radius: 4px;
    border: 0;
    padding: 10px 12px 9px;
    transition: box-shadow .2s ease,border-radius .2s ease,border-radius .2s ease,background-color .2s ease,color .2s ease;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn.secondary {
    background: #fff;
    color: #3a3939;
    box-shadow: 0 1px 4px #23232329
}

.btn.secondary:hover:not(.disabled) {
    box-shadow: 0 4px 12px #23232329
}

.input-group.copy-on-hover .copy {
    display: none
}

.input-group.copy-on-hover:hover .copy {
    display: block
}

.input-group input:disabled[type=copy], .input-group input:read-only[type=copy] {
    cursor: default;
}

.input-group.auth input, .input-group.medium input {
    padding: 18px 14px;
}

.input-group input:disabled, .input-group input:read-only {
    box-shadow: none;
    border: 1px solid #EEE;
}

.input-group .copy {
    position: absolute;
    right: 8px;
    top: 8px;
}

.btn.small {
    padding: 6px 23px;
    font-size: 10px;
    letter-spacing: .4px;
}

.input-group.medium .copy {
    top: 16px;
    right: 16px;
}

.copy-feedback {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    line-height: 20px;
    padding: 13px 0;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .05em;
    text-align: center;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    border: 1px solid rgba(211, 224, 243, 0.5);
    color: #00ba94;
}

.copy-address {
    border: none;
    color: #495463;
    line-height: 20px;
    padding: 12px 50px 12px 40px;
    border-radius: 4px;
    border: 1px solid rgba(211, 224, 243, 0.5);
    width: 100%;
    background: #fff;
}

.table {
    box-shadow: 0 1px 4px #23232329;
    margin: 0 auto 80px;
}

.table-container {
    position: relative;
    min-height: 90px;
    overflow-x: auto;
}

table.nh {
    width: 100%;
    border: none;
}

table.nh thead {
    background: #fff;
}

table.nh th:first-child {
    padding-left: 32px;
}

.table th {
    color: #959595;
}

table.nh th {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.6;
    font-weight: 400;
    padding: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

table.nh tbody tr:nth-child(odd) {
    background-color: #fcfcfc;
}

table.nh tbody tr:first-child {
    box-shadow: inset 1px 4px 6px -5px #23232373;
}

table.nh tbody tr {
    background-color: #fff;
}

table.nh tbody tr td:first-child {
    padding-left: 32px;
}

table.nh tbody tr td {
    padding: 12px 16px;
    border-bottom: 1px solid #EEE;
    font-size: 12px;
    line-height: 1.33;
    color: #3a3939;
}

.relative {
    position: relative;
}

.field-wrap .label-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    font-size: 12px;
}

.field-wrap .label-row label {
    color: #959595;
    display: block;
    flex: 1;
}

.input-group2 {
    position: relative;
}

.input-group2.large .input-group-prepend {
    width: 76px;
    line-height: 72px;
    height: 72px;
    color: #959595;
    font-size: 24px;
}

.input-group2 .input-group-prepend {
    position: absolute;
    top: 1px;
    min-width: 44px;
    border-right: 1px solid #EEE;
    z-index: 1;
    text-align: center;
    line-height: 38px;
    height: 37px;
    color: #cecdcd;
}

.input-group2.large .input-group-prepend~input {
    padding-left: 92px;
}

.input-group2.large input {
    height: 72px;
    font-size: 32px;
}

.input-group2.large .input-group-prepend span.smaller {
    font-size: 20px;
    position: relative;
    top: -2px
}

.input-group2.large .input-group-prepend~input {
    padding-left: 92px
}

.use-max {
    position: absolute;
    top: 25px;
    right: 40px;
}

.btn.outline.primary {
    color: rgb(25, 52, 93);
    box-shadow: inset 0 0 0 1px rgb(25, 52, 93)!important;
}

.input-group2 input:hover {
    box-shadow: 0 4px 12px #23232329
}

.input-group2 input:focus {
    outline: none;
    border: 1px solid #19345D;
    box-shadow: 0 5px 10px #19345D1a
}

.input-group2 input:-moz-read-only {
    box-shadow: none;
    border: 1px solid #EEE
}

.input-group2 input:disabled,.input-group input:read-only {
    box-shadow: none;
    border: 1px solid #EEE
}

.use-max button {
    width: 60px;
    margin-left: 8px;
    padding: 6px 0 !important;
}

.btn.small {
    padding: 6px 23px;
    font-size: 10px;
    letter-spacing: .4px;
}

.btn.outline.primary:hover,.btn.outline.primary:active {
    background: rgb(25, 52, 93);
    color: #fff;
    box-shadow: inset 0 0 0 1px rgb(25, 52, 93)!important
}

.btn {
    border-radius: 4px;
    border: 0;
    padding: 10px 12px 9px;
    transition: box-shadow .2s ease,border-radius .2s ease,border-radius .2s ease,background-color .2s ease,color .2s ease;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
}

div:focus label:before {
    border-color: #19345D;
    box-shadow: 0 5px 10px #19345D1a
}

div input+label:after {
    content: none
}

.field-wrap+.field-wrap {
    margin-top: 24px
}

.field-wrap .label-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    font-size: 12px
}

.field-wrap .label-row label {
    color: #959595;
    display: block;
    flex: 1
}

.field-wrap.dark label {
    color: #ffffff80
}

.btn.outline {
    background: transparent;
}

.text-secondary {
    color: #fba342 !important;
}

.fs16 {
  font-size: 16px;
}

.skeleton-balance .line {
     height: 12px;
     margin-top: 6px;
     margin-bottom: 6px;
     border-radius: 4px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation:  shimmer 1.5s infinite linear;
}

.skeleton-left {
    flex:1;
}

.skeleton-balance {
     padding:15px 15px 15px 0;
     max-width: 300px;
     width: 135px;
     height: 10px;
     color: transparent; /* Texto transparente para simular un esqueleto */
     margin-top: 5px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 4px;
}

.selectbox.deposit-to img,.selectbox.deposit-to .img,.selectbox.deposit-from img,.selectbox.deposit-from .img {
    margin-left: 8px;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    border-radius: 40px
}

.selectbox.deposit-to .field img,.selectbox.deposit-from .field img {
    position: relative;
    vertical-align: middle;
    top: -2px
}

.selectbox.deposit-to .field .name,.selectbox.deposit-from .field .name {
    position: absolute;
    top: 0px;
    left: 88px
}

div.selectbox.large .arrows {
    top: 28px;
}

div.selectbox.large .field {
    height: 72px;
    line-height: 72px
}

div.selectbox.large .input-group-prepend {
    line-height: 71px;
    height: 70px
}

div.selectbox {
    position: relative
}

div.selectbox .field {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 4px #23232329;
    font-size: 14px;
    border: 1px solid transparent;
    color: #3a3939;
    transition: .2s ease box-shadow;
    padding: 0 32px 0 16px;
    cursor: pointer;
    height: 39px;
    line-height: 39px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.theme-dark div.selectbox .field {
    background-color: #052339;
    border: 1px solid #40494f;
    color: #eee
}

div.selectbox .field.placeholder {
    color: #cecdcd
}

div.selectbox:focus {
    outline: none
}

div.selectbox:focus .field {
  border: 1px solid #15888a;
    box-shadow: 0 5px 10px #fba3421a
}

div.selectbox.open .field {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

div.selectbox .dropdown .options {
    overflow: hidden;
    overflow-y: auto;
    flex: 1
}

div.selectbox .dropdown .options .option {
    padding: 8px 16px;
    cursor: pointer
}

div.selectbox .dropdown .options .option:hover {
    background-color: #fba34240
}

div.selectbox .dropdown .options .option.selected {
    background-color: #fba342;
    color: #fff
}

div.selectbox .dropdown {
    position: absolute;
    background: #fff;
    z-index: 2;
    min-width: 100%;
    box-shadow: 0 1px 4px #23232329;
    max-height: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    transition: max-height .2s;
    flex-direction: column;
    overflow: hidden
}

div.selectbox.open .dropdown {
    max-height: 350px
}

.selectbox.deposit-to img,.selectbox.deposit-to .img,.selectbox.deposit-from img,.selectbox.deposit-from .img {
    margin-left: 8px;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    border-radius: 40px
}

.selectbox.deposit-to .field img,.selectbox.deposit-from .field img {
    position: relative;
    vertical-align: middle;
    top: -2px
}

.selectbox.deposit-to .field .balance2,.selectbox.deposit-from .field .balance2 {
    position: absolute;
    top: 12px;
    left: 88px;
    font-size: 12px
}

.selectbox.deposit-to .option,.selectbox.deposit-from .option {
    position: relative
}

.selectbox.deposit-to .option img,.selectbox.deposit-from .option img {
    margin-left: 8px;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    position: relative;
    top: 2px
}

.selectbox.deposit-to .option .name,.selectbox.deposit-from .option .name {
    position: absolute;
    top: 11px;
    left: 88px
}

.selectbox.deposit-to .option .balance2,.selectbox.deposit-from .option .balance2 {
    position: absolute;
    top: 33px;
    left: 88px;
    font-size: 12px
}

.selectbox.deposit-to .option.selected .text-muted,.selectbox.deposit-from .option.selected .text-muted,.selectbox.deposit-to .option.selected .label-warning,.selectbox.deposit-from .option.selected .label-warning {
    color: #fff
}

.selectbox.deposit-to .option a .img,.selectbox.deposit-from .option a .img {
    border: 2px solid #FBA342;
    line-height: 36px;
    text-align: center;
    font-size: 18px;
    color: #fba342
}

.selectbox.deposit-to .option a .name,.selectbox.deposit-from .option a .name {
    color: #959595;
    top: 20px
}

.selectbox.deposit-to .option.selected a .name,.selectbox.deposit-from .option.selected a .name {
    color: #fff
}

.field-wrap .label-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    font-size: 12px;
}

div.selectbox .arrows i {
    display: block;
    font-size: 12px;
    line-height: 6px;
    color: #cecdcd;
    position: relative;
    left: 5px
}

div.selectbox .field {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 4px #23232329;
    font-size: 14px;
    border: 1px solid transparent;
    color: #3a3939;
    transition: .2s ease box-shadow;
    padding: 0 32px 0 16px;
    cursor: pointer;
    height: 39px;
    line-height: 39px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.selectbox.deposit-to img,.selectbox.deposit-to .img,.selectbox.deposit-from img,.selectbox.deposit-from .img {
    margin-left: 8px;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    border-radius: 40px
}

.selectbox.deposit-to .field img,.selectbox.deposit-from .field img {
    position: relative;
    vertical-align: middle;
    top: -2px
}

.selectbox.deposit-to .field .balance2,.selectbox.deposit-from .field .balance2 {
    position: absolute;
    top: 12px;
    left: 88px;
    font-size: 12px
}

.selectbox.deposit-to .option,.selectbox.deposit-from .option {
    position: relative
}

.selectbox.deposit-to .option img,.selectbox.deposit-from .option img {
    margin-left: 8px;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    position: relative;
    top: 2px
}

.selectbox.deposit-to .option .name,.selectbox.deposit-from .option .name {
    position: absolute;
    top: 11px;
    left: 88px
}

.selectbox.deposit-to .option .balance2,.selectbox.deposit-from .option .balance2 {
    position: absolute;
    top: 33px;
    left: 88px;
    font-size: 12px
}

.selectbox.deposit-to .option.selected .text-muted,.selectbox.deposit-from .option.selected .text-muted,.selectbox.deposit-to .option.selected .label-warning,.selectbox.deposit-from .option.selected .label-warning {
    color: #fff
}

.selectbox.deposit-to .option a .img,.selectbox.deposit-from .option a .img {
    border: 2px solid #FBA342;
    line-height: 36px;
    text-align: center;
    font-size: 18px;
    color: #fba342
}

.selectbox.deposit-to .option a .name,.selectbox.deposit-from .option a .name {
    color: #959595;
    top: 20px
}

.selectbox.deposit-to .option.selected a .name,.selectbox.deposit-from .option.selected a .name {
    color: #fff
}

div.selectbox .dropdown .options .option:hover {
    background-color: #15888a40
}

div.selectbox .field {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 4px #23232329;
    font-size: 14px;
    border: 1px solid transparent;
    color: #000000;
    transition: .2s ease box-shadow;
    padding: 0 32px 0 16px;
    cursor: pointer;
    height: 39px;
    line-height: 39px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

div.selectbox .dropdown .options {
    overflow: hidden;
    overflow-y: auto;
    flex: 1;
}

div.selectbox .dropdown .options .option.selected {
    background-color: #3883dd;
    color: #fff;
}

div.selectbox .dropdown .options .option:hover {
    background-color: #3883dd40
}

.arrows {
    position: absolute;
    top: 11px;
    right: 12px;
    width: 15px;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
}

div.selectbox .arrows i {
    display: block;
    font-size: 12px;
    line-height: 6px;
    color: #cecdcd;
    position: relative;
    top: 3px;
    left: 5px;
}

.flex--no-wrap {
    flex-wrap: nowrap;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}
</style>