<template>
  <div>

      <section id="slider" class="slider-element dark min-vh-25 min-vh-md-50 include-header py-5"  style="background-color: #002BAE">

        <div class="slider-inner">
          <div class="vertical-middle text-center">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-6">
                      <h2 class="fw-bold text-white mb-2">{{$t('faqTitle')}}</h2>
                      <p class="lead mb-0 fw-normal">{{$t('faqSubtitle')}}</p>
                    </div>
                </div>
            </div>
          </div>

        <div class="row justify-content-center mt-3">
            <div class="col-md-7 dotted-bg">
                <div class="toggle-wrap">
                  <div class="toggle" v-for="(faq, key) in faqs" :key="key">
                    <div class="toggle-header" @click="toggleFAQ(key)">
                      <div class="toggle-icon">
                        <i class="toggle-closed fa-solid fa-angle-right" v-if="!faq.open"></i>
                        <i class="toggle-open fa-solid fa-angle-down" v-if="faq.open"></i>
                      </div>
                      <div class="toggle-title">
                        {{ $t(faq.question) }}
                      </div>
                    </div>
                    <div class="toggle-content" :style="faq.open ? 'display: block;' : 'display: none;'">
                      {{ $t(faq.answer) }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mt-3">
                  <router-link to="/sign-up" class="button bg-white text-dark button-light button-rounded button-large color ms-0">{{$t('signUpNow')}} <i class="fa fa-arrow-right fw-semibold"></i></router-link>
                </div>
            </div>
            
        
          
        </div>

        
        </div>
      </section>
    <Footer/>
  </div>
</template>

<script>
import Footer from './Footer.vue';
export default {
  name: 'CloudhashFrontendFAQs',
  components: { Footer },
  data() {
    return {
      bgImg: {
        backgroundImage: `url(${require('@/assets/images/1.svg')})`
      },
      faqs: {
        faq1: {
          question: 'faq1.question',
          answer: 'faq1.answer',
          open: false
        },
        faq2: {
          question: 'faq2.question',
          answer: 'faq2.answer',
          open: false
        },
        faq3: {
          question: 'faq3.question',
          answer: 'faq3.answer',
          open: false
        },
        faq4: {
          question: 'faq4.question',
          answer: 'faq4.answer',
          open: false
        },
        faq5: {
          question: 'faq5.question',
          answer: 'faq5.answer',
          open: false
        },
        faq6: {
          question: 'faq6.question',
          answer: 'faq6.answer',
          open: false
        },
        faq7: {
          question: 'faq7.question',
          answer: 'faq7.answer',
          open: false
        },
        faq8: {
          question: 'faq8.question',
          answer: 'faq8.answer',
          open: false
        },
        faq9: {
          question: 'faq9.question',
          answer: 'faq9.answer',
          open: false
        },
        faq10: {
          question: 'faq10.question',
          answer: 'faq10.answer',
          open: false
        },
        faq11: {
          question: 'faq11.question',
          answer: 'faq11.answer',
          open: false
        },
        faq12: {
          question: 'faq12.question',
          answer: 'faq12.answer',
          open: false
        },
        faq13: {
          question: 'faq13.question',
          answer: 'faq13.answer',
          open: false
        },
        // Agrega más preguntas y respuestas aquí...
      }
    };
  },

  mounted() {
    
  },

  methods: {
    toggleFAQ(key) {
      Object.keys(this.faqs).forEach(k => {
        if (k !== key) {
          this.faqs[k].open = false;
        }
      });
      this.faqs[key].open = !this.faqs[key].open;
    }
  },
};
</script>
<style scoped>
a:not(.btn-link):not(.text-decoration-underline):not(.more-link) {
    text-decoration: none!important;
}

.button.button-large {
    --cnvs-btn-padding-x: 1.625rem;
    --cnvs-btn-padding-y: 0.5rem;
    --cnvs-btn-fontsize: 1.125rem;
}

.button.button-light {
    --cnvs-btn-color: var(--cnvs-btn-color-light);
    color: var(--cnvs-btn-color-dark);
}

.button {
    --cnvs-btn-padding-x: 1.375rem;
    --cnvs-btn-padding-y: 0.5rem;
    --cnvs-btn-fontsize: 1rem;
    --cnvs-btn-color: var(--cnvs-themecolor);
    --cnvs-btn-color-dark: var(--bs-gray-900);
    --cnvs-btn-color-light: #e9ecef;
    --cnvs-btn-color-hover: var(--cnvs-btn-color-dark);
    --cnvs-btn-lineheight: calc(var(--cnvs-btn-fontsize) * 1.6);
    --cnvs-btn-border-width: 1px;
    --cnvs-btn-icon-margin: 10px;
    --cnvs-btn-icon-margin-offset: 0px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    outline: 0;
    white-space: nowrap;
    margin: 5px;
    padding: var(--cnvs-btn-padding-y) var(--cnvs-btn-padding-x);
    font-size: var(--cnvs-btn-fontsize);
    line-height: var(--cnvs-btn-lineheight);
    text-align: center;
    background-color: var(--cnvs-btn-color);
    color: #fff;
    font-weight: 500;
    border: var(--cnvs-btn-border-width) solid transparent;
    transition: all .2s ease-in-out;
}

.button-rounded {
    border-radius: 0.25rem;
}

.button:hover {
  background-color: #f8f9fa; /* Cambia el color de fondo en hover */
  color: #343a40; /* Cambia el color del texto en hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Añade sombra en hover */
}
</style>