<template>
  <header class="navbar navbar-expand-lg blur blur-rounded shadow bd-navbar" id="tnh">
    <div class="container">
      <!-- Logo -->
      <router-link to="/" class="navbar-brand">
        <img src="@/assets/logo-cloud.svg" alt="CloudHash" style="width: 160px;">
        <!-- <p ref="slogan" class="text-white slogan">{{$t('slogan')}}</p> -->
      </router-link>
      
      <!-- Botón para colapsar en dispositivos pequeños -->
      <button class="navbar-toggler" type="button" @click="toggleNavbar" v-click-outside="hide" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      
      <!-- Enlaces de navegación -->
      <div class="collapse navbar-collapse" :class="{ 'show': isNavbarOpen }" id="navbarNav">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link to="/" class="nav-link" style="color: #262626;font-weight: 500;">{{ $t('home') }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/pricing" class="nav-link" style="color: #262626;font-weight: 500;">{{ $t('pricing') }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/insights" class="nav-link" style="color: #262626;font-weight: 500;">{{ $t('insights') }}</router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link to="/about" class="nav-link" style="color: white;">{{ $t('about') }}</router-link>
          </li> -->
          <li class="nav-item">
            <router-link to="/faqs" class="nav-link" style="color: #262626;font-weight: 500;">{{ $t('faqsNav') }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/affiliate" class="nav-link" style="color: #262626;font-weight: 500;">{{ $t('affiliate') }}</router-link>
          </li>

          <!-- <li class="nav-item">
            <router-link to="/contact-us" class="nav-link" style="color: white;">{{ $t('contactUs') }}</router-link>
          </li> -->

          <!-- <li class="nav-item">
            <router-link to="/bounty-program" class="nav-link" style="color: #262626;font-weight: 500;">{{ $t('bountyProgram')}}</router-link>
          </li> -->

        </ul>
      </div>
      <div class="btn-group">
        <template v-if="!autenticado">
          <router-link to="/login" class="btn btn-outline-dark" >{{ $t('login') }}</router-link>
          <router-link to="/sign-up" class="btn btn-dark register-btn" >{{ $t('signUp') }}</router-link>
        </template>
        <template v-else>
          <router-link to="/my/dashboard" class="btn btn-outline-dark" >{{ $t('dashboard') }}</router-link>
          <a href="javascript:void(0)" class="btn btn-dark register-btn" @click="onLogOut">{{ $t('logOut') }}</a>
        </template>

        <div class="dropdown ml-3 d-sm-block d-md-none">
          <button class="btn btn-secondary dropdown-toggle" @click="toggleDropdown" v-click-outside="hideDropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{ selectedLanguage.label }}
          </button>
          <ul class="dropdown-menu" :class="{ 'show': isDropdownOpen }">
            <li v-for="(lang, index) in supportedLanguages" 
                    :key="index">
                    <a class="dropdown-item" @click="changeLocale(lang)" href="javascript:void(0)">{{ lang.label }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import clickOutside from '../directives/clickOutside';
import i18n from '@/i18n/index.js';
export default {
  name: 'CloudhashFrontendHeader',

  data() {
    return {
      typingStarted: false,
      isNavbarOpen: false,
      supportedLanguages: [
          { label: 'English', value: 'en', flag: 'gb' },
          { label: 'Español', value: 'es', flag: 'es' }
        ],
        isDropdownOpen: false
    };
  },
  computed: {
    ...mapGetters({
          autenticado: 'user/autenticado',
          user: 'user/usuario'
      }),
      ...mapGetters(['locale', 'supportedLanguages2']), // Obtener el idioma seleccionado y los idiomas admitidos desde Vuex
    selectedLanguage: {
      get() {
        // Devolver el idioma seleccionado del array de idiomas admitidos según el estado de Vuex
        return this.supportedLanguages.find(lang => lang.value === this.locale);
      },
      set(value) {
        // Podemos añadir lógica aquí si necesitamos actualizar selectedLanguage desde el componente
      }
    },
  },
  watch: {
    locale(newLocale) {
      this.selectedLanguage = this.supportedLanguages2.find(lang => lang.value === newLocale);
    }
  },
  directives: {
    clickOutside,
  },
  mounted() {
    i18n.locale = this.locale;
  },
  methods: {
    ...mapActions(['setLocale']),
    changeLocale(locale) {
        i18n.locale = locale.value;
        this.setLocale(locale.value);
        this.isDropdownOpen = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    hideDropdown() {
      this.isDropdownOpen = false;
    },
    hide() {
        this.isNavbarOpen = false;
    },
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },
    ...mapActions({
        logout: 'user/logout'
    }),
    onLogOut() {
        this.logout().then((res) => {
            this.$router.push({ name: "Login" });
        })
    },
  },
};
</script>
<style scoped>
.navbar-toggler {
    background-color: white;
}

.register-btn {
  border-bottom-right-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

/* #tnh {
    position: fixed;
    width: 100%;
    height: 72px;
    background: #fff;
    z-index: 9999;
}

#tnh #tnl a, #tnh #tnl .elnk {
    color: #262626;
    font-weight: 500;
} */
</style>