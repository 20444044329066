<template>
  <div class="slider-element dark min-vh-25 min-vh-md-50 include-header py-5" style="background-color: rgb(25, 52, 93);">
    <div class="cloud-wrap">
            <div class="c1"><div class="cloud"></div></div>
            <div class="c2"><div class="cloud"></div></div>
            <div class="c3"><div class="cloud"></div></div>
            <div class="c4"><div class="cloud"></div></div>
            <div class="c5"><div class="cloud"></div></div>
          </div>

          <div class="slider-inner">
              <div class="vertical-middle text-center">
                <div class="container">
                  <div class="row align-items-center justify-content-center">
                      <div class="col-lg-6 col-md-6">
                        <h2 class="fw-bold text-white mb-2"> {{$t('contactTitle')}}</h2>
                        <p class="lead mb-0 fw-normal">{{$t('contactSubtitle')}}</p>
                        <p class="lead mb-0 fw-normal"><a href="mailto:Cloudhash@protonmail.com">Cloudhash@protonmail.com</a></p>
                        <br>
                        <p class="lead mb-0 fw-normal">Twitter</p>
                        <p class="lead mb-0 fw-normal"><a href="https://twitter.com/Cloudhash" target="_blank">@Cloudhash</a></p>
                      </div>
                  </div>
                </div>
            </div>

    
  <div class="row justify-content-center mt-3">
    <div class="col-md-8">
      <p class="mb-0">{{ $t('contactPGP')}}</p>
      <div class="card">
            <div class="card-body">
          <pre class="card-text text-center">
            -----BEGIN PGP PUBLIC KEY BLOCK-----

mQGNBGY8TgMBDADL4UNpDGljUESxIm/f2sWMKXcR2XsMZashtGY8VOg1YjPzkxwy
E9egTX3+MRnOP9NVM35ZIOS5f3pnPwMGkHjfqWO2tcV327SuEkiVS40D/+57Gl44
JSor0Pqbb0kSFzT6H6mBlRH+HjyQF1vc7k/mKNxDc62pYR85mUVwwj28TrV7qVTA
flNFmBjf4qHD3xY40Tj2iKv8Ywy0KuXwaeH+HIKsRduoOYPcgmeMkYhgwbYHPmog
qITzzfUWmFO+Od0n4vi+LssrI+JQm8F/przyerTGmDZGdDyL/9Lk6xevLLSsXn4/
tCheYzDHHCaiIzT28wbOeQEgM3iLZjeEXtEaPPPyt+Nwg1KuC2PyuKDRztGRFYFh
BovTHOEglR5PFsJdjK+KrOt/qtfF8THl/VyXECyM1b02ho8DjyxBSwca/WEy+h4e
dcfVNDhnTImPuZ9aJF+ui2Y+fUYAg42XeLsC2A03DLSlmP5cmugQyaHzys315oJK
SKLPN7heqhtFdw8AEQEAAbQ5TmltYnVzSGFzaCAoTmltYnVzSGFzaFBHUEtFWSkg
PG5pbWJ1c2hhc2hAcHJvdG9ubWFpbC5jb20+iQHRBBMBCAA7FiEE+UwIRA+OAfuN
VwW5JUcQL6pCYYoFAmY8TgMCGwMFCwkIBwICIgIGFQoJCAsCBBYCAwECHgcCF4AA
CgkQJUcQL6pCYYrTVgv9GLYTnPc5WwSN8oqQTlMAl4dhwVVmOer0zqq7HRdukG4o
aaviwXmQvT11lM0BB7HFZU31WWQU4sg1Om9MeSnEFDqzBR39blvRATdLhTfi3wYW
ME2pKZXAcMno0misWJ741CVHzOprOxKD8wLWJ0q3vUbTMj6l0ngcNim27ZciUNb6
2EXRjZ5JbgGvtyARhdaPvAMfVx8KouhDkVYioXHaYiPOK/PzlHPPbEZMv5mDoMow
H/ZRPsmOwnwaLmRBz8mAt5AB/OF1/K4hpargcdC4996qI4P636loMB78NALnXSqh
cs9Dj4w/hK0uhnT1CRPoT3rL4cjt72e2dgYVi2P6TcTjxGvDOq7DGnLiOkkH5KBH
BH7JYRHtSPYTraUIlw+YxDkhUceYheJGng9Wb+ze4Jt13Jg2PAMBeewGCv0M73r7
Jk+DP1u/ZbIc30Un8KIUcHmJaStV5ve0JDX8RH2lwG5MXmZpih5dqLqHzsQ+Vi45
JAQRSn8X5frf8PmLgLWSuQGNBGY8TgMBDADNRc2RV9cCQ62U4Lwr/wAeuvuwPUTy
xhAonwJj9Ts/MyrwYukUMFHfxHskxVsgjQp9ZU6m0ia9TscBpWtueQ4Fs2gH2cdB
flR5//1CGeStfNZn1X5RaxCQAMVWNQe239nXgXqHs1bJxfPs6qNKEqxnMcYH4XUH
Lnhdi6oLK5M15N+0MtVtynJrLv7/ws+TeJMH3tFsEBqW5ISm8KEmdjf2wvFb0LB5
QTjx8teYx6zR5iQJqRmhQ+1oIEfoBS+U+RnCy4K6N23xyRO79n8ZEO1EuNZjrHQ+
3X3rOV2qiQbxMAhE/lrMMpK83YWGsva4Stpp03UJVVDzciAwwJBSLYEMTNUrIS5t
5qDODTqvxEGVl9q+rS+tA/csM1aSKEnknOsVBLIlTJ/R0nmS24qa9UJrvZooVL9P
tD6Ebr8YABEUt5bxmHtAAppCcdi2w+MLgrev2MnlvqUoyBNq2/IGTMR0FnfE0T8c
n+qOCkzJCmkBHqz9mQpClL9cVfLny6oOQJ0AEQEAAYkBtgQYAQgAIBYhBPlMCEQP
jgH7jVcFuSVHEC+qQmGKBQJmPE4DAhsMAAoJECVHEC+qQmGK2RoMAMReHR9k7iCF
9Agn6bSHOCFh9mGCFrapdKWZavHpZKmlg434OmHU0a7l/CxoxzV+QrZ0yh9WsQAa
g1LMwqzR9SdygPQxNFGaNE67yxTsR4gxVyxxJGN5MlPKHnMO/ltp4NnQDB1+0bFF
1hRJM25qK4DwcUANRJ4EUWFQqZLEwDLwrZ+VCQJBbXrEe5hJtVR13W+WAIb5zj/h
UcbG0hGvSzbSOWMktX62xjCtGhLrDBzjT9Psxge8TSfNlDset5qluCx3aRfXHWvZ
CNd57H37YQyqPKzYlxRzdlEJddZeW9iKdaVxRjR+/45n3uDwDmHvVpVouo+jMUU8
dn/EabEf6x7vM5paCOGK7+iyIZQ4aPo/JZamNOIB7QU7qgXXJr3qOL7aod6RxiAj
HI9ZGz+lqS/YcFFEFXzvPslZ0ufPVeSJwMlH0tJBakhQZPv9QaV//3eRPtnokSfo
qHUSM0T/w6rb+i09TE72e8uXmvZcXndv2wgo8pj9WIkFYLeWKLgceQ==
=1o5Q
-----END PGP PUBLIC KEY BLOCK-----
          </pre>
        </div>
      </div>
    </div>
  </div>

</div>
        <Footer/>
  </div>
</template>

<script>
import Footer from './Footer.vue';
export default {
  name: 'CloudhashFrontendContact',
  components: { Footer },
  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>