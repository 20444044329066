<template>
  <div>

    <div class="toast-container start-50 translate-middle-x">
          <div class="toast align-items-center border-0 fade" :class="{'show': showToast ,'text-bg-danger': showError }" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
              <div class="toast-body">
                <template v-if="message === ''">
                  <ul>
                    <template v-for="(fieldErrors, field) in errors">
                      <li v-for="error in fieldErrors" :key="error">{{ field }}: {{ error }}</li>
                    </template>
                  </ul>
              </template>
              <template v-else>
                <p class="text-center">{{message}}</p>
              </template>
              </div>
              <button type="button" @click="closeToast" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
          </div>
      </div>

      <div class="toast-container start-50 translate-middle-x">
          <div class="toast align-items-center border-0 fade" :class="{'show': showToastSc ,'text-bg-success': showSuccess }" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
              <div class="toast-body">
                <p class="text-center">{{message}}</p>
              </div>
              <button type="button" @click="closeToastSc" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
          </div>
      </div>

      <div class="container mb32 mt-3">
    <div class="row">
      <div class="col-lg-3 mb-3">
        <SidebarSettings/>
      </div>

      
      <div class="col-lg-9 mt-sm-3 mt-0">
        <div class="content shadow ">
          <div class="panel ">
            <div class="mb32">
              <h1 class="text-bold mb8">{{$t('security')}}</h1>
              <small class="text-muted mb32">{{$t('securityReview')}}</small>
            </div>

            


            <h3 class="mb8 mt32">{{$t('changePassword')}}</h3>
            <hr class="mb16">
            <div class="row">
              <div class="col-xs-12 col-sm-6">
                <button class="btn primary normal fluid" @click="openModalPw"><span>{{$t('changePassword')}}</span></button>
              </div>
            </div>
            
            <h3 class="mt32">{{$t('tfaDesc')}}</h3>
            <hr class="mb8">
            <div class="mb16">
              <small class="text-muted">{{$t('tfaDescSmall')}} </small>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-6">
                <template v-if="!user.user.google2fa_enable">
                  <button class="btn primary normal fluid" @click="open2faModal"><span>{{$t('enable2FABtn')}}</span></button>
                </template>
                <template v-else>
                  <button class="btn primary normal fluid" @click="openDisModal"><span>{{$t('dis2FABtn')}}</span></button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>
  

  

    <template v-if="showModalPw">
    <div class="modal fade" :class="showModalPw ? 'show' : ''" tabindex="-1" :style="showModalPw ? 'display: block;' : 'display:none;'">
      <div class="modal-dialog" >
        <div class="modal-content">
          
          <div class="modal-header">
            <h5 class="modal-title">{{$t('changePassword')}}</h5>
            <button type="button" class="btn-close" @click="hideModalPw"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="changePassword">
                <div class="field-wrap">
                  <div class="label-row">
                    <label>{{$t('currentPassword')}}</label>
                  </div>
                  <div class="input-group">
                    <input type="password" class="form-control" v-model="form.current" :placeholder="$t('currentPassword')">
                  </div>
                </div>
                <div class="field-wrap">
                  <div class="label-row">
                    <label>{{$t('newPassword')}}</label>
                  </div>
                  <div class="input-group mb16">
                    <input type="password" class="form-control" v-model="form.password" :placeholder="$t('newPassword')">
                  </div>
                  <div class="input-group">
                    <input type="password" class="form-control" v-model="form.password_confirmation" :placeholder="$t('newPasswordAgain')">
                  </div>
                </div>
                <div class="text-center">
                  <button type="submit" class="btn primary normal fluid mt24" :disabled="isSubmitting">
                    <span v-if="!isSubmitting">{{$t('changePassword')}}</span>
                    <span v-else>
                        <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                        {{ $t('loggingIn') }}...
                    </span>
                  </button>
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-if="show2faModal">
    <div class="modal fade" :class="show2faModal ? 'show' : ''" tabindex="-1" :style="show2faModal ? 'display: block;' : 'display:none;'">
      <div class="modal-dialog-custom" >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{$t('enable2FABtn')}}</h5>
            <button type="button" class="btn-close" @click="hide2faModal"></button>
          </div>
          <div class="modal-body">
            <template v-if="!showNext">
            <div  class="content2">
              <div  class="section mt32">
                <h3 class="mb24" v-html="$t('twoFactorDescription1')"></h3>
                <div  class="row">
                  <div  class="col-sm-6 flex justify-content-center">
                    <a  target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en" rel="noopener">
                      <img  src="@/assets/images/google-play.png" class="store" alt="Play Store">
                    </a>
                  </div>
                  <div  class="col-sm-6 flex justify-content-center mt16-xs-down">
                    <a  target="_blank" href="https://itunes.apple.com/si/app/google-authenticator/id388497605?mt=8" rel="noopener">
                      <img  src="@/assets/images/apple-store.png" alt="App Store" class="store">
                    </a>
                  </div>
                </div>
              </div>
              <hr  class="mt32 mb24">
              <div  class="section">
                <h3 v-html="$t('twoFactorDescription2')">
                </h3>
              </div>
              <hr  class="mt24 mb24">
              <div  class="section">
                <h3 >
                  <span v-html="$t('twoFactorDescription3')"> </span> {{privateCode}}
                </h3>
                <div  class="flex justify-content-center">
                  <template v-if="isLoading">
                    <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                  </template>
                  <template v-else>
                    <qrcode-vue :value="qrCode" size="256" level="H"></qrcode-vue>
                  </template>
                </div>
              </div>
              
            </div>

            <div  class="row">
              <div  class="col-xs-12 flex flex--justify-center mt24">
                <button  @click="toggleNext" class="btn primary normal flex__fill-gap">
                  <span>{{$t('nextBtn')}}</span>
                </button>
              </div>
            </div>
          </template>
          <template v-else>
            <div  class="content2">
                
                  <div  class="section">
                    <br >
                    <p >{{$t('sixDigitDesc')}}</p>
                    <div class="field-wrap mb16">
                      
                      <div class="input-group2 auth">
                        <div class="input-group-prepend" >
                          <i class="fa fa-mobile-alt"></i>
                        </div>
                        <input v-model="verifyCode" autocomplete="off" type="tel" @keypress="restrictToNumbers" class="form-control" :placeholder="$t('sixDigitCodePlaceholder')" maxlength="">
                      </div>
                    </div>
                  </div>
              </div>
              <div class="row">
                <div class="col-xs-12 flex flex--justify-center mt24">
                  <button @click="enableTwoFactor" class="btn primary normal flex__fill-gap" :disabled="isSubmitting">
                    <span v-if="!isSubmitting">{{$t('verify')}}</span>
                    <span v-else>
                        <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                        {{ $t('loggingIn') }}...
                    </span>
                  </button>
                </div>
              </div>
                
          </template>
            
          </div>
        </div>
      </div>
    </div>

    
  </template>

  <template v-if="showdis2faModal">
    <div class="modal fade" :class="showdis2faModal ? 'show' : ''" tabindex="-1" :style="showdis2faModal ? 'display: block;' : 'display:none;'">
      <div class="modal-dialog-custom">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{$t('dis2FABtn')}}</h5>
            <button type="button" class="btn-close" @click="hidedis2faModal"></button>
          </div>
          <div class="modal-body">
            <div  class="content2">
                <div  class="section">
                  <br >
                  <p >{{$t('sixDigitDesc')}}</p>
                  <div class="field-wrap mb16">
                    
                    <div class="input-group2 auth">
                      <div class="input-group-prepend" >
                        <i class="fa fa-mobile-alt"></i>
                      </div>
                      <input v-model="verifyCode" autocomplete="off" type="tel" @keypress="restrictToNumbers" class="form-control" :placeholder="$t('sixDigitCodePlaceholder')" maxlength="">
                    </div>
                  </div>
                </div>
            </div>
            <div class="row">
              <div class="col-xs-12 flex flex--justify-center mt24">
                <button @click="disableTwoFactor" class="btn primary normal flex__fill-gap" :disabled="isSubmitting">
                  <span v-if="!isSubmitting">{{$t('disableBtn')}}</span>
                  <span v-else>
                      <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                      {{ $t('loggingIn') }}...
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  

  
    <div class="modal-backdrop fade show" v-if="showdis2faModal"></div>
    <div class="modal-backdrop fade show" v-if="showModalPw"></div>
    <div class="modal-backdrop fade show" v-if="show2faModal"></div>
    <Footer/>
  </div>
</template>

<script>
import Footer from './Footer.vue';
import SidebarSettings from './SidebarSettings.vue';
import clickOutside from '../directives/clickOutside';
import QrcodeVue from 'qrcode.vue';
import axios from 'axios';
import { mapGetters, mapActions, mapState } from 'vuex';
export default {
  name: 'CloudhashFrontendSettings',
  components: { Footer, SidebarSettings, QrcodeVue },
  data() {
    return {
      showModalPw: false,
      show2faModal: false,
      showdis2faModal: false,
      form: {
          current: '',
          password: '',
          password_confirmation: ''
      },
      isSubmitting: false,
      errors: {},
      message: '',
      showToast: false,
      showError: false,
      showSuccess: false,
      showToastSc: false,
      qrCode: '',
      privateCode: '',
      showNext: false,
      ipClient: 0,
      verifyCode: '',
      isActive: false,
      isLoading: false,
      showoModalmnemonic: false,
      mnemonic: '',
      selectedWords: [],
    };
  },

  mounted() {
    
  },
  computed: {
    ...mapGetters({
          autenticado: 'user/autenticado',
          user: 'user/usuario'
      }),
      shuffledMnemonic() {
        return this.mnemonic.split(' ').sort(() => Math.random() - 0.5);
      },
      selectedWordsText() {
        return this.selectedWords.map(index => this.shuffledMnemonic[index]).join(' ');
      }
  },
  directives: {
    clickOutside,
  },
  methods: {
    restrictToNumbers(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        if (!/^[0-9]+$/.test(keyValue)) {
            event.preventDefault();
        }
    },
    verifyMnemonic() {
      if (this.isSubmitting) return;

      // Inicia la animación del botón y deshabilítalo
      this.isSubmitting = true;
      axios.post(process.env.VUE_APP_API_URL + 'verifyMnemonic', {
        mnemonic: this.selectedWords.map(index => this.shuffledMnemonic[index]).join(' ')
      })
      .then((res) =>{
        this.showToastSc = true;
        this.showSuccess = true;
        this.message = res.data.message;
        this.hidemnemonic();
        this.selectedWords = [];
        this.isSubmitting = false;
        this.$store.dispatch('user/intento');
        setTimeout(()=>{
          this.showToastSc = false;
          this.showSuccess = false;
          this.message = '';
        },5000);
      })
      .catch((error) =>{
        if (error.response.status === 422) {
            this.message = error.response.data.error;
            this.showToast = true;
            this.showError = true;
            this.isSubmitting = false;
            setTimeout(()=>{
              this.showToast = false;
              this.showError = false;
              this.message = '';
            },5000);
          
        }
      })
    },
    selectWord(index) {
        // Lógica para seleccionar las palabras en orden
        if (!this.isSelected(index)) {
          this.selectedWords.push(index);
        } else {
          this.selectedWords.splice(this.selectedWords.indexOf(index), 1);
        }
    },
    isSelected(index) {
      // Verifica si la palabra está seleccionada
      return this.selectedWords.includes(index);
    },
    disableTwoFactor() {
      if (this.isSubmitting) return;

      // Inicia la animación del botón y deshabilítalo
      this.isSubmitting = true;
      if(this.verifyCode === ''){
        this.message = 'Invalid Code'
            this.showToast = true;
            this.showError = true;
            this.isSubmitting = false;
            setTimeout(()=>{
              this.showToast = false;
              this.showError = false;
              this.message = '';
            },5000);
            return;
      }
      axios.post(process.env.VUE_APP_API_URL + 'disableTwoFactor', {
        verifyCode: this.verifyCode
      })
      .then((res)=>{
        this.showToastSc = true;
        this.showSuccess = true;
        this.message = res.data.message;
        this.hidedis2faModal();
        this.verifyCode = '';
        this.isSubmitting = false;
        this.$store.dispatch('user/intento');
        setTimeout(()=>{
          this.showToastSc = false;
          this.showSuccess = false;
          this.message = '';
        },5000);
        //console.log(res.data);
      })
      .catch((error)=>{
        if (error.response.status === 422) {
            this.message = error.response.data.error;
            this.showToast = true;
            this.showError = true;
            this.isSubmitting = false;
            setTimeout(()=>{
              this.showToast = false;
              this.showError = false;
              this.message = '';
            },5000);
          
        }
        //console.log(error.response.data.error);
      })
    },
    enableTwoFactor() {
      if (this.isSubmitting) return;

      // Inicia la animación del botón y deshabilítalo
      this.isSubmitting = true;
      if(this.verifyCode === ''){
        this.message = 'Invalid Code'
            this.showToast = true;
            this.showError = true;
            this.isSubmitting = false;
            setTimeout(()=>{
              this.showToast = false;
              this.showError = false;
              this.message = '';
            },5000);
            return;
      }
      axios.post(process.env.VUE_APP_API_URL + 'enableTwoFactor', {
        verifyCode: this.verifyCode
      })
      .then((res)=>{
        this.showToastSc = true;
        this.showSuccess = true;
        this.message = res.data.message;
        this.hide2faModal();
        this.verifyCode = '';
        this.isSubmitting = false;
        this.$store.dispatch('user/intento');
        setTimeout(()=>{
          this.showToastSc = false;
          this.showSuccess = false;
          this.message = '';
        },5000);
        //console.log(res.data);
      })
      .catch((error)=>{
        if (error.response.status === 422) {
            this.message = error.response.data.error;
            this.showToast = true;
            this.showError = true;
            this.isSubmitting = false;
            setTimeout(()=>{
              this.showToast = false;
              this.showError = false;
              this.message = '';
            },5000);
          
        }
        //console.log(error.response.data.error);
      })
    },
    toggleNext() {
      this.showNext = true;
    },
    hide2faModal() {
      this.show2faModal = false;
      this.showNext = false;
      document.body.classList.remove('modal-open');
    },
    hidedis2faModal() {
      this.showdis2faModal = false;
      document.body.classList.remove('modal-open');
    },
    openDisModal() {
      this.showdis2faModal = true;
      document.body.classList.add('modal-open');
    },
    open2faModal() {
      this.show2faModal = true;
      document.body.classList.add('modal-open');
      this.getTwoGoogleCode();
    },
    hidemnemonic() {
      this.showoModalmnemonic = false;
      this.showNext = false;
      document.body.classList.remove('modal-open');
    },
    openModalmnemonic() {
      this.showoModalmnemonic = true;
      document.body.classList.add('modal-open');
      this.getMnemonic();
    },
    getMnemonic() {
      this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL + 'getMnemonic')
      .then((res)=>{
        this.mnemonic = res.data.mnemonic;
        this.isLoading = false;
      })
      .catch((error)=>{
        console.log(error.response.data.error);
      })
    },
    getTwoGoogleCode() {
      this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL + 'getGoogleCode')
      .then((res)=>{
        this.qrCode = res.data.qrCode;
        this.privateCode = res.data.secret;
        this.isLoading = false;
      })
      .catch((error)=>{
        console.log(error.response.data.error);
      })
    },
    closeToastSc() {
      this.showToastSc = false;
      this.showSuccess = false;
    },
    closeToast() {
      this.showToast = false;
      this.showError = false;
    },
    changePassword () {
      // Evita el envío si el formulario ya se está enviando
      if (this.isSubmitting) return;

      // Inicia la animación del botón y deshabilítalo
      this.isSubmitting = true;

      axios.post(process.env.VUE_APP_API_URL + 'changePassword', {
        current: this.form.current,
        password: this.form.password,
        password_confirmation: this.form.password_confirmation,
      })
      .then(response => {
        this.showToastSc = true;
        this.showSuccess = true;
        this.message = response.data.message;
        this.hideModalPw();
        this.form.current = '';
        this.form.password = '';
        this.form.password_confirmation = '';
        this.isSubmitting = false;
        setTimeout(()=>{
          this.showToastSc = false;
          this.showSuccess = false;
          this.message = '';
        },5000);
      })
      .catch((error)=>{
        if (error.response.status === 422) {
          if (typeof error.response.data.error === 'string') {
            this.message = error.response.data.error;
            this.showToast = true;
            this.showError = true;
            this.isSubmitting = false;
            setTimeout(()=>{
              this.showToast = false;
              this.showError = false;
              this.message = '';
            },5000);
          } else {
            this.errors = error.response.data.error;
            this.showToast = true;
            this.showError = true;
            this.isSubmitting = false;
            setTimeout(()=>{
              this.showToast = false;
              this.showError = false;
              this.errors = {};
            },5000);
          }
        }
      })
    },
    hideModalPw2() {
      this.showModalPw = false;
      document.body.classList.remove('modal-open');
    },
    hideModalPw() {
      this.showModalPw = false;
      document.body.classList.remove('modal-open');
    },
    openModalPw() {
      this.showModalPw = true;
      document.body.classList.add('modal-open');
    }
  },
};
</script>
<style scoped>
h1 {
    font-size: 24px;
    font-weight: 700;
}

.mb8 {
    margin-bottom: 8px;
}

.text-muted {
    color: #959595;
}

small {
    font-size: 12px;
    line-height: 1.42;
}

.mb24 {
    margin-bottom: 24px;
}

hr {
    border: 0;
    border-bottom: 1px solid #EEE;
}

h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.mb16 {
    margin-bottom: 16px;
}

.btn.primary.normal {
    box-shadow: 0 12px 4px -10px #19345D66;
}

.btn.fluid {
    width: 100%;
}

.btn.normal {
    padding: 13px 24px 12px;
    font-size: 12px;
    letter-spacing: .5px;
}

.btn.primary {
    background: linear-gradient(90deg,#002BAE,#002BAE);
    color: #fff;
    box-shadow: 0 20px 4px -18px #19345D52;
}

.btn {
    border-radius: 4px;
    border: 0;
    padding: 10px 12px 9px;
    transition: box-shadow .2s ease,border-radius .2s ease,border-radius .2s ease,background-color .2s ease,color .2s ease;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mt32 {
    margin-top: 20px;
}

.btn.primary:hover:not(.disabled) {
    background: linear-gradient(90deg,#19345D,#19345D);
    box-shadow: 0 24px 12px -19px #19345D8f
}

.modal .spacer {
    height: 80px;
    margin: 0 0 -80px;
}

.content2 {
    margin-left: -24px;
    width: calc(100% + 48px);
}

.content2 .section {
    padding: 0px 24px;
}

.field-wrap .label-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    font-size: 12px;
}

.field-wrap .label-row label {
    color: #959595;
    display: block;
    flex: 1;
}

.input-group input[type=password] {
    padding-right: 82px
}

.input-group input::placeholder {
    color: #cecdcd;
    font-size: 14px
}

.field-wrap+.field-wrap {
    margin-top: 24px;
}

.mt24 {
    margin-top: 20px;
}

.content[data-v-e6989c53] {
    margin-left: -24px;
    width: calc(100% + 48px);
}

.toast-body ul {
    list-style-type: none; /* Quita el marcador de lista */
    padding: 0; /* Elimina el relleno predeterminado de la lista */
}

.content2 .section {
    padding: 0 24px;
}

a {
    color: #128ede !important;
    text-decoration: none;
    transition: .2s ease color;
    cursor: pointer;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.content2 .store {
    width: 160px;
}

.modal-dialog-custom {
    position: relative;
    width: 400px;
    margin: var(--bs-modal-margin);
    pointer-events: none;
}

@media (min-width: 576px){
.modal-dialog-custom {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
}
}

.content {
    box-shadow: rgba(35, 35, 35, 0.16) 0px 1px 4px;
    background: transparent;
    flex: 1 1 0%;
    border-radius: 4px;
}

.section {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: var(--nbhs-section-bg);
    overflow: hidden;
}

.content2 {
    margin-left: -24px;
    width: calc(100% + 48px);
}

.flex__fill-gap {
    flex: 1;
}

.modal p {
    margin: 16px 0 24px;
    color: #959595;
    line-height: 1.43;
    font-size: 14px;
}

.input-group.auth .input-group-prepend~input,.input-group.medium .input-group-prepend~input {
    padding-left: 56px
}

.input-group.auth .input-group-prepend,.input-group.medium .input-group-prepend {
    line-height: 54px;
    height: 53px
}

.input-group input:focus-within + .input-group-prepend {
    color: #cecdcd; /* Cambia el color cuando el input está en foco */
}

.input-group .input-group-prepend {
    position: absolute;
    top: 1px;
    min-width: 44px;
    border-right: 1px solid #EEE;
    z-index: 1;
    text-align: center;
    line-height: 38px;
    height: 37px;
    color: #cecdcd;
}

.input-group .input-group-prepend.active {
    position: absolute;
    top: 1px;
    min-width: 44px;
    border-right: 1px solid #EEE;
    z-index: 1;
    text-align: center;
    line-height: 38px;
    height: 37px;
    color: #cecdcd;
}

.input-group-prepend.active {
  color: #cecdcd; /* Cambia el color del icono cuando está activo */
}

.input-group.auth .input-group-prepend, .input-group.medium .input-group-prepend {
    line-height: 54px;
    height: 53px;
}

.client-info {
    margin: 0 0 16px;
    background: #eee;
    line-height: 18px;
    padding: 8px 0;
}

.fs12 {
    font-size: 12px;
}

.text-center {
    text-align: center;
}

.text-muted {
    color: #959595;
}

.mb8 {
    margin-bottom: 8px;
}

@media only screen and (min-width: 321px) {
  .col-micro-6 {
      max-width: 50%;
      flex-basis: 50%;
  }
}

.mr4 {
    margin-right: 4px;
}

.text-wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
}



/* @media only screen and (min-width: 481px) {
  .col-xs-12 {
      max-width: 100%;
      flex-basis: 100%;
  }
} */

.input-group.auth .input-group-prepend, .input-group.medium .input-group-prepend {
    line-height: 54px;
    height: 53px;
}

.mnemonic-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mnemonic-buttons button {
  margin-bottom: 10px;
}

.btn-outline-primary {
  text-transform: none;
}

.input-group2 input:hover {
    box-shadow: 0 4px 12px #23232329
}

.input-group2 input:focus {
    outline: none;
    border: 1px solid #19345D;
    box-shadow: 0 5px 10px #19345D1a
}

.input-group2 input:-moz-read-only {
    box-shadow: none;
    border: 1px solid #EEE
}

.input-group2 input:disabled,.input-group input:read-only {
    box-shadow: none;
    border: 1px solid #EEE
}

.input-group2 .input-group-prepend {
    position: absolute;
    top: 1px;
    min-width: 44px;
    border-right: 1px solid #EEE;
    z-index: 1;
    text-align: center;
    line-height: 38px;
    height: 37px;
    color: #cecdcd;
}

.input-group2.large .input-group-prepend~input {
    padding-left: 92px;
}

.input-group2 {
    position: relative;
}

.input-group2.auth .input-group-prepend~input {
    padding-left: 56px
}

@media (min-width: 576px) {
    .mt-sm-3 {
        margin-top: 1rem !important;
    }
}
</style>