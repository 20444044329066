var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"settings-part dark",staticStyle:{"background-color":"#002BAE"},attrs:{"id":"top-bar"}},[_c('ul',{staticClass:"bitcoin-prices-list"},[(_vm.loading)?_vm._l((7),function(n){return _c('li',{key:n,staticClass:"skeleton"},[_vm._m(0,true)])}):_vm._l((_vm.prices),function(price,index){return _c('li',{key:index},[_c('span',{class:[
                                                                          _vm.priceChanges[index] === 1 ? 'price-up-color' : 'price-text',
                                                                          _vm.priceChanges[index] === -1 ? 'price-down-color' : 'price-text',
                                                                          _vm.priceChanges[index] === 0 ? 'font-bold text-gray-500' : 'price-text',
                                                                          _vm.palpitar ? 'palpitar' : ''
                                                                      ]},[_vm._v(" 1 "+_vm._s(_vm.convertName(price.crypto_name))+" = "+_vm._s(price.price)+" "+_vm._s(price.currency.toUpperCase())+" ")]),(_vm.priceChanges[index] === 1)?_c('span',{staticClass:"arrow price-up"}):(_vm.priceChanges[index] === -1)?_c('span',{staticClass:"arrow price-down"}):_vm._e()])})],2),_c('div',{staticClass:"selectbox up locale locale-select locale",class:{ open: _vm.isDropdownOpen },attrs:{"tabindex":"0"}},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hide),expression:"hide"}],staticClass:"field",attrs:{"aria-expanded":_vm.isDropdownOpen ? 'true' : 'false'},on:{"click":_vm.toggleDropdown}},[_vm._m(1),_c('div',{staticClass:"flag",class:_vm.selectedLanguage.flag}),_vm._v(" "+_vm._s(_vm.selectedLanguage.label)+" ")]),_c('div',{staticClass:"dropdown flex flex--no-wrap"},[_c('div',{staticClass:"options",attrs:{"role":"listbox","aria-label":"Options"}},_vm._l((_vm.supportedLanguages),function(lang,index){return _c('div',{key:index,staticClass:"option",class:_vm.selectedLanguage.label === lang.label ? 'selected' : '',attrs:{"role":"option","aria-selected":_vm.selectedLanguage.label === lang.label ? true :false},on:{"click":function($event){return _vm.changeLocale(lang)}}},[_c('div',{staticClass:"flag",class:lang.flag}),_vm._v(" "+_vm._s(lang.label)+" ")])}),0)])])]),_c('Header')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skeleton-left"},[_c('div',{staticClass:"line h25 w50 m10"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"arrows"},[_c('i',{staticClass:"fa fa-caret-up"}),_c('i',{staticClass:"fa fa-caret-down"})])
}]

export { render, staticRenderFns }