<template>
  <div>

    <div class="section m-0 " >
          


          <div class="toast-container position-fixed top-50 start-50 translate-middle">
              <div class="toast align-items-center border-0 fade" :class="{'show': showToast ,'text-bg-danger': showError }" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="d-flex">
                  <div class="toast-body">
                    <template>
                      <p>{{message}}</p>
                    </template>
                  </div>
                  <button type="button" @click="closeToast" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
              </div>
          </div>

          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-6">
                <div class="text-center  mb-5">
                    <h2 class="fw-bold mb-2 ">{{ $t('login') }}</h2>
                    <p class="fw-normal mb-2 lead ">{{ $t('loginDescription') }}</p>
                </div>
                <div class="card bg-white shadow-lg border-0" >
                  <div class="card-body">
                    <form @submit.prevent="login">

                      <div class="field-wrap input- mb24">
                        <div class="input-group2 auth">
                          <div class="input-group-prepend">
                            <i class="fa fa-user"></i>
                          </div>
                          <input autocomplete="off" type="text" @focus="isEmailFocused = true" @blur="isEmailFocused = false" name="email" :class="{ 'up': isEmailFocused || loginForm.email  }" class="form-control" v-model="loginForm.email" placeholder="" maxlength="" required>
                          <span class="floating-placeholder prepend" :class="{ 'up': isEmailFocused || loginForm.email  }">{{ $t('emailOrUsername') }}</span>
                        </div>
                      </div>

                      <div class="field-wrap input- mb24">
                        <div class="input-group2 auth">
                          <div class="input-group-prepend">
                            <i class="fa fa-lock"></i>
                          </div>
                          <input autocomplete="current-password" :type="showPassword2 ? 'text' : 'password'" 
                          @focus="isPasswordFocused = true" @blur="isPasswordFocused = false"
                          :class="{ 'up': isPasswordFocused || loginForm.password  }"
                          name="password" class="form-control" v-model="loginForm.password" placeholder="" maxlength="">
                          <span class="floating-placeholder prepend" :class="{ 'up': isPasswordFocused || loginForm.password  }">{{ $t('password') }}</span>
                          <div class="show-password" :class="showPassword2 ? 'show' : ''" @click="togglePasswordVisibility2">
                            <span>{{!showPassword2 ? $t('showPw') : $t('hidePw')}}</span>
                            <i class="fa" :class="showPassword2 ? 'fa-eye-slash' : 'fa-eye'"></i>
                          </div>
                        </div>
                      </div>



                      <div class="col-12 mt-3 d-grid gap-2 d-md-flex justify-content-md-end mt-3">
                        <router-link to="/forgot-password" class="link fw-bold text-decoration-none text-primary">{{ $t('forgotPassword') }}</router-link>
                      </div>
                      <div class="col-12">
                        <button type="submit" class="btn w-100 text-white btn-primary rounded-3 py-3 fw-semibold text-uppercase mt-2" :disabled="isSubmitting">
                            <span v-if="!isSubmitting">{{ $t('login') }}</span>
                            <span v-else>
                                <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                                {{ $t('loggingIn') }}...
                            </span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>


    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'CloudhashFrontendLogin',
  data() {
    return {
      loginForm:{
        email: '',
        password: '',
      },
      showPassword2: false,
      isSubmitting: false,
      message: '',
      showToast: false,
      showError: false,
      isEmailFocused: false,
      isPasswordFocused: false,
    };
  },

  mounted() {
    
  },
  computed: {
    ...mapGetters('user', ['twoFactorEnabled']),
  },
  methods: {
    ...mapActions({
      signIn: 'user/signIn'
    }),
    async login() {
      try {
        if (this.isSubmitting) return;

        // Inicia la animación del botón y deshabilítalo
        this.isSubmitting = true;

        const { twoFactorEnabled } = await this.signIn(this.loginForm);
        if (twoFactorEnabled) {
          // Redirigir al usuario al formulario 2FA
          this.$router.push({ name: "TwoFactorAuth" });
        } else {
          // Redirigir al usuario al dashboard u otra página
          this.$router.push({ name: "Dashboard" });
        }
      } catch (error) {
        const status = error.status || 500;
        const message =
          error.data?.error || "Ocurrió un error inesperado, inténtalo nuevamente.";

          this.message = message;
          this.showToast = true;
        this.showError = true;
        this.isSubmitting = false;

        setTimeout(() => {
          this.showError = false;
          this.showToast = false;
          this.message = "";
        }, 5000);

        //console.error(`Login failed (${status}):`, message);

        
      }
    },
    
    togglePasswordVisibility2() {
      this.showPassword2 = !this.showPassword2;
    },
    closeToast() {
      this.showToast = false;
      this.showError = false;
    }
  },
};
</script>
<style scoped>
.mb24 {
    margin-bottom: 24px;
}

.input-group2 {
    position: relative;
}

.input-group2.auth .input-group-prepend, .input-group.medium .input-group-prepend {
    line-height: 54px;
    height: 53px;
}

.input-group2 .input-group-prepend {
    position: absolute;
    top: 3px;
    min-width: 44px;
    border-right: 1px solid #EEE;
    z-index: 1;
    text-align: center;
    line-height: 38px;
    height: 37px;
    color: #cecdcd;
}

.input-group2.auth .input-group-prepend~input, .input-group.medium .input-group-prepend~input {
    padding-left: 56px;
}

.input-group2.auth input, .input-group.medium input {
    padding: 18px 14px;
}

.input-group2.auth span.floating-placeholder, .input-group.medium span.floating-placeholder {
    position: absolute;
    pointer-events: none;
    left: 19px;
    top: 19px;
    transition: .2s ease all;
    color: #cecdcd;
    font-size: 14px;
}

.input-group2.auth span.floating-placeholder.prepend,.input-group.medium span.floating-placeholder.prepend {
    left: 56px!important
}

.input-group2.auth span.floating-placeholder.up,.input-group.medium span.floating-placeholder.up {
    top: 11px;
    left: 15px;
    font-size: 10px;
    color: #959595
}

.input-group2.auth input.up,.input-group.medium input.up {
    padding-top: 25px;
    padding-bottom: 11px
}

.input-group2 input:focus {
    outline: none;
    border: 1px solid #19345D;
    box-shadow: 0 5px 10px #19345D1a
}

.input-group2.auth .show-password, .input-group.medium .show-password {
    line-height: 54px;
}

.input-group2.auth .show-password {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 60px;
    z-index: 1;
    color: #19345D;
    cursor: pointer;
}

.input-group2.auth .show-password span {
    margin-right: 7px;
    font-size: 12px;
}


/* .input-group2 .show-password {
    position: absolute;
    right: 20px;
    top: 1px;
    width: 60px;
    z-index: 1;
    text-align: center;
    line-height: 38px;
    color: #19345D;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.input-group2 .show-password span {
    margin-right: 7px;
    display: inline-block;
    font-size: 12px;
} */

.input-group2 .show-password i {
    display: inline-block;
}

.input-group2 .show-password.show {
    color: #cecdcd;
    right: 20px
}
</style>