import Vue from 'vue'
import Vuex from 'vuex'
import locales from '@/i18n/locales.js';
import user from "./user";

Vue.use(Vuex)

const defaultLocale = 'en';

export const store = new Vuex.Store({
  state: {
    locale: localStorage.getItem('locale') || defaultLocale,
    supportedLanguages2: [
      { label: 'English', value: 'en', flag: 'gb' },
      { label: 'Español', value: 'es', flag: 'es' },
      { label: 'Français', value: 'fr', flag: 'fr' },
      { label: 'Italiano', value: 'it', flag: 'it' },
      { label: 'русский', value: 'ru', flag: 'ru' },
      { label: 'हिन्दी', value: 'hi', flag: 'in' },
      { label: 'Deutsch', value: 'de', flag: 'de' },
      { label: 'Português', value: 'pt', flag: 'pt' },
      { label: '中文', value: 'zh', flag: 'cn' },
      { label: '日本語', value: 'ja', flag: 'jp' }
    ]
  },
  getters: {
    locale: state => state.locale,
    supportedLanguages2: state => state.supportedLanguages2
  },
  mutations: {
    SET_LOCALE(state, locale) {
      if (locales[locale]) {
        state.locale = locale;
        localStorage.setItem('locale', locale);
      }
    }
  },
  actions: {
    setLocale({ commit }, locale) {
      commit('SET_LOCALE', locale);
    }
  },
  modules: {
    user: user
  }
})
