import Vue from 'vue';
import VueRouter from 'vue-router';
import { store } from '../store'
import Home from '@/views/Home.vue';
import Pricing from '@/views/Pricing.vue';
import Insights from '@/views/Insights.vue';
import About from '@/views/About.vue';
import FAQs from '@/views/FAQs.vue';
import Affiliate from '@/views/Affiliate.vue';
import Register from '@/views/Register.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import Login from '@/views/Login.vue';
import Forgot from '@/views/Forgot.vue';
import TwoFactorAuth from '@/views/2FA.vue';
import Terms from '@/views/Terms.vue';
import Privacy from '@/views/Privacy.vue';
import Dashboard from '@/views/Dashboard.vue';
import Upgrade from '@/views/Upgrade.vue';
import Withdraw from '@/views/Withdraw.vue';
import Partners from '@/views/Partners.vue';
import Settings from '@/views/Settings.vue';
import AccountSettings from '@/views/AccountSettings.vue';
import Notification from '@/views/Notification.vue';
import ReferralRegister from '@/views/ReferralRegister.vue';
import Contact from '@/views/Contact.vue';
import Bounty from '@/views/Bounty.vue';

Vue.use(VueRouter);


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Home',
      description: 'Welcome to CloudHash - the cloud bitcoin mining platform designed by AI. Start mining bitcoin today and maximize your profits.'
    },
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: Contact,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Contact us',
      description: 'Welcome to CloudHash - the cloud bitcoin mining platform designed by AI. Start mining bitcoin today and maximize your profits.'
    },
  },
  {
    path: '/bounty-program',
    name: 'Bounty',
    component: Bounty,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Bounty program',
      description: 'Welcome to CloudHash - the cloud bitcoin mining platform designed by AI. Start mining bitcoin today and maximize your profits.'
    },
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Pricing',
      description: 'Explore our flexible pricing plans for cloud bitcoin mining at CloudHash. Choose the plan that suits your needs and start mining today.'
    },
  },
  {
    path: '/insights',
    name: 'Insights',
    component: Insights,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Insights',
      description: 'Gain valuable insights into the world of bitcoin mining with CloudHash. Stay updated with the latest trends, news, and analysis to optimize your mining strategy.'
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | About Us',
      description: 'Descripción de los términos de uso de CloudHash.'
    },
  },
  {
    path: '/faqs',
    name: 'FAQs',
    component: FAQs,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | FAQs',
      description: 'Find answers to frequently asked questions about bitcoin mining at CloudHash. Learn about our platform, services, and how to get started.'
    },
  },
  {
    path: '/affiliate',
    name: 'Affiliate',
    component: Affiliate,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Affiliate',
      description: 'Join the CloudHash affiliate program and earn rewards for referring others to our cloud bitcoin mining platform. Start earning passive income today!'
    },
  },
  {
    path: '/terms-of-use',
    name: 'Terms',
    component: Terms,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Terms of Use',
      description: 'Read the terms of use for CloudHash, the cloud bitcoin mining platform designed by AI. Understand our policies, rules, and regulations.'
    },
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Privacy Policy',
      description: 'Review the privacy policy of CloudHash, the cloud bitcoin mining platform designed by AI. Learn how we collect, use, and protect your data.'
    },
  },
  {
    path: '/sign-up',
    name: 'Register',
    component: Register,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Register',
      description: 'Join CloudHash, the cloud bitcoin mining platform designed by AI, and start mining today. Sign up now to get started!'
    },
  },
  {
    path: '/reset-password/:token/:email',
    name: 'reset-password',
    component: ResetPassword,
  },
  {
    path: '/referral',
    name: 'ReferralRegister',
    component: ReferralRegister,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Register',
      description: 'Join CloudHash, the cloud bitcoin mining platform designed by AI, and start mining today. Sign up now to get started!'
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Login',
      description: 'Log in to CloudHash, the cloud bitcoin mining platform designed by AI. Access your account and start mining bitcoin today.'
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot',
    component: Forgot,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Forgot Password',
      description: 'Forgot your password? Reset it easily with CloudHash, the cloud bitcoin mining platform designed by AI. Follow the steps to regain access to your account.'
    },
  },
  {
    path: '/two-factor-auth',
    name: 'TwoFactorAuth',
    component: TwoFactorAuth,
    meta: {
      requiresAuth: true,
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Login',
      description: 'Enable two-factor authentication (2FA) for added security on CloudHash, the cloud bitcoin mining platform designed by AI. Protect your account from unauthorized access.'
    },
  },
  {
    path: '/my/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Dashboard',
      description: 'Access your dashboard on CloudHash, the cloud bitcoin mining platform designed by AI. Monitor your mining activities, earnings, and more.'
    },
  },
  {
    path: '/my/upgrade',
    name: 'Upgrade',
    component: Upgrade,
    meta: {
      requiresAuth: true,
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Upgrade Mining Power',
      description: 'Upgrade your mining power on CloudHash, the cloud bitcoin mining platform designed by AI. Increase your mining capabilities and earn more bitcoin.'
    },
  },
  {
    path: '/my/withdraw',
    name: 'Withdraw',
    component: Withdraw,
    meta: {
      requiresAuth: true,
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Withdraw',
      description: 'Withdraw your earnings from CloudHash, the cloud bitcoin mining platform designed by AI. Easily convert your mined bitcoin to your preferred currency.'
    },
  },
  {
    path: '/my/partners',
    name: 'Partners',
    component: Partners,
    meta: {
      requiresAuth: true,
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Partners',
      description: 'View your referrals and commissions on CloudHash, the cloud bitcoin mining platform designed by AI. Earn rewards for bringing new users to our platform.'
    },
  },
  {
    path: '/my/settings/security',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true,
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Account Security',
      description: 'Manage your account security settings on CloudHash, the cloud bitcoin mining platform designed by AI. Protect your account with two-factor authentication and more.'
    },
  },
  {
    path: '/my/settings/account',
    name: 'AccountSettings',
    component: AccountSettings,
    meta: {
      requiresAuth: true,
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Account Settings',
      description: 'Descripción de los términos de uso de CloudHash.'
    },
  },
  {
    path: '/my/settings/notification',
    name: 'Notification',
    component: Notification,
    meta: {
      requiresAuth: true,
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Account Notifications',
      description: 'Manage your account notification settings on CloudHash, the cloud bitcoin mining platform designed by AI. Stay informed about your mining activities and account updates.'
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

router.beforeEach((to, from, next) => {

  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.meta.description) {
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description);
  }

  const isAuthenticated = store.getters['user/autenticado']; // Verifica si está autenticado
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth); // Rutas que requieren autenticación
  const requiresTwoFactorAuth = store.getters['user/requiresTwoFactorAuth']; // Estado de 2FA
  const guestOnly = ['login', 'register', 'forgot', 'reset-password']; // Rutas solo para invitados

  // Caso 1: Usuario autenticado intenta acceder a una ruta de invitado
  if (isAuthenticated && guestOnly.includes(to.name)) {
    return next({ name: 'dashboard' });
  }

  // Caso 2: Ruta requiere autenticación pero el usuario no está autenticado
  if (requiresAuth && !isAuthenticated) {
    return next({ name: 'login' });
  }

  // Caso 3: Ruta requiere 2FA pero no ha sido completada
  if (requiresAuth && requiresTwoFactorAuth && to.name !== 'TwoFactorAuth') {
    return next({ name: 'TwoFactorAuth' });
  }

  // Caso 4: Navegación permitida
  next();
});


export default router;
