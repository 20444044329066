<template>
  <div class="slider-element dark min-vh-25 min-vh-md-50 include-header py-5" style="background-color: rgb(25, 52, 93);">
    <div class="container">
        <div class="cloud-wrap">
            <div class="c1"><div class="cloud"></div></div>
            <div class="c2"><div class="cloud"></div></div>
            <div class="c3"><div class="cloud"></div></div>
            <div class="c4"><div class="cloud"></div></div>
            <div class="c5"><div class="cloud"></div></div>
        </div>

        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="widtget bg-lead-card custom-card d-flex widget-six">
                        <div class="widget-six-icon-container background-youtube center">
                            <i class="fab fa-youtube fa-2xl text-white"></i>
                        </div>
                    <!-- <div class="p-3 flex-grow-1">
                        <h5 class="m-2">YouTube review campaign</h5>
                        <p class="ml-2 mt-2 mb-0 text-sm">Earn from $10 to $1,000 for each YouTube review of <span class="Cloud text-bold">CloudHash</span>.</p>
                        <ul class="p-3 ml-3 text-sm" style="list-style-type: none;">
                            <li>Your channel and videos must be public.</li>
                            <li>You must have at least 100 subscribers.</li>
                            <li>Include the word <span class="Cloud text-bold">CloudHash</span> in the title of your video.</li>
                            <li>Your review must be original. Do not copy content, text, or visuals from other reviews.</li>
                            <li>We may reject your review for reasons such as poor video/sound quality, plagiarism, or fake audience activity.</li>
                            <li>We will consider key points such as duration, number of views, likes, comments, and video/sound quality for rewarding.</li>
                            <li>Videos must contain human voice. Videos without your voice will not be accepted.</li>
                            <li>Repeat the process to earn Referral Commission.</li>
                            <li>Only 1 submission allowed per month for this task.</li>
                            <li>Maximum of 1 account on <span class="Cloud text-bold">CloudHash</span> per user.</li>
                        </ul>
                        <h6 class="m-2 mt-2">Reward</h6>
                        <p class="ml-2 mt-2 mb-0 font-12 text-bold">Rewards: $10 to $1,000 and Mining Power</p>
                        <p class="ml-2 mt-2 mb-0 font-12 text-bold">Email: <a href="mailto:Cloudhash@protonmail.com">Cloudhash@protonmail.com</a></p>
                    </div> -->
                    <div class="p-3 flex-grow-1">
                        <h5 class="m-2">Earn rewards with our YouTube review campaign!</h5>
                        <p class="ml-2 mt-2 mb-0 text-sm">Get rewarded from $10 to $1,000 for each YouTube review of <span class="Cloud text-bold">CloudHash</span>. Share your honest feedback and earn big!</p>
                        <ul class="p-3 ml-3 text-sm" style="list-style-type: none;">
                            <li>Your channel and videos must be public.</li>
                            <li>You must have at least 100 subscribers.</li>
                            <li>Include the word <span class="Cloud text-bold">CloudHash</span> in the title of your video.</li>
                            <li>Your review must be original. Do not copy content, text, or visuals from other reviews.</li>
                            <li>We may reject your review for reasons such as poor video/sound quality, plagiarism, or fake audience activity.</li>
                            <li>We will consider key points such as duration, number of views, likes, comments, and video/sound quality for rewarding.</li>
                            <li>Videos must contain human voice. Videos without your voice will not be accepted.</li>
                            <li>Repeat the process to earn Referral Commission.</li>
                            <li>Only 1 submission allowed per month for this task.</li>
                            <li>Maximum of 1 account on <span class="Cloud text-bold">CloudHash</span> per user.</li>
                            <li>Submit your review to <a href="mailto:Cloudhash@protonmail.com">Cloudhash@protonmail.com</a>.</li>
                        </ul>
                        <p class="ml-2 mt-2 mb-0 font-12 text-bold">Rewards: $10 to $1,000 and Mining Power</p>
                        <p class="ml-2 mt-2 mb-0 font-12 text-bold">Get started today and join our growing community!</p>
                        <div class="mt-3 ml-2">
                            <p class="text-bold mb-1">Follow us on Twitter: <a href="https://twitter.com/Cloudhash">@CloudHash</a></p>
                            <p class="text-bold mb-1">Use the hashtag #CloudHashRevolution for your review!</p>
                            <p class="text-bold mb-1">Share your review and tag us for a chance to be featured!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from './Footer.vue';
export default {
  name: 'CloudhashFrontendBounty',
  components: { Footer },
  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>
<style scoped>
.text-mining {
    color: #45f882
}

.text-center {
    text-align: center !important
}

.bounce-enter-active {
    animation: bounce-in .5s;
}

.bounce-leave-active {
    animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

.custom-card {
    /* max-width: 400px; */
    background-color: #fff;
    color: #212529 !important
    /* transition: background-color 0.3s; */
}

.fixed-height-card {
    height: 200px;
    /* Ajusta esta altura según tus necesidades */
}

.card-subtitle {
    transition: width 0.3s ease;
    /* Agrega una transición suave a la anchura */
}

.bg-color-changed {
    background-color: rgba(45, 31, 144,0.15) !important;
}

.bg-color-changed2 {
    background-color: #e30059 !important;
}

.custom-image {
    max-width: 100px;
    max-height: 100px;
}

.card-title {
    font-size: 20px;
    color: #ffffff;
    /* Tamaño de fuente para el título */
    /* font-weight: bold; */
    /* Peso de la fuente para el título */
    margin-bottom: 0.5rem;
    /* Margen inferior para separar del siguiente elemento */
}

.card-subtitle {
    font-size: 12px;
    /* Tamaño de fuente para el subtítulo */
    color: #ffffff;
    /* Color de fuente para el subtítulo */
    margin-bottom: 0.5rem;
    /* Margen inferior para separar del siguiente elemento */
}

.btn-info {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0;
}

.ml-3 {
    margin-left: 5px;
}

@media (max-width: 767.98px){
    .ml-3 {
        margin-left: 0px;
    }
}

.avatar-sm {
    width: 55px;
    height: 55px; /* Ajusta la altura según sea necesario */
    margin-left: 0.5rem;
    margin-right: 0.2rem;
    border-radius: 50%; /* Para hacer que la imagen sea redonda */
    object-fit: cover; /* Para asegurarse de que la imagen se ajuste bien dentro del círculo */
}

@media (max-width: 767.98px){
    .avatar-sm {
        width: 40px;
        height: 40px; /* Ajusta la altura según sea necesario */
        margin-left: 0px;
        margin-right: 30px;
        border-radius: 50%; /* Para hacer que la imagen sea redonda */
        object-fit: cover; /* Para asegurarse de que la imagen se ajuste bien dentro del círculo */
    }
}


.Cloud {
    color: rgb(25, 52, 93) !important;
}

/* .widget-six {
    position: relative;
    padding: 0px;
    color:var(--bs-card-color);
    border-radius: .35rem;
    overflow: hidden;
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.09019607843137255), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
} */

.bg-light-success {
    background-color: rgb(0, 186, 148) !important;
    border-color: #e6ffbf;
    color: #8dbf42
}

.widget-six-icon-container {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.widget-three {
    position: relative;
    padding: 20px;
    border-radius: 8px;
    height: 100%;
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.09019607843137255), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
}

.background-youtube {
    background-color: #f00 !important;
}
</style>