<template>
  <div>
      

      <section id="slider" class="slider-element py-2"  style="background-color: #002BAE">
        

        
                
                  <div class="container">

                    <div class="row mt-3">
                      <div class="col-lg-3">
                    
                    <!-- <div class="wallet-container"> -->

                      <div class="sidebar">
                        <h3 class="mb16 text-white">{{ $t('insights') }}</h3>
                        <div class="box">
                          <div class="total">
                            <small class="text-muted">{{ $t('totalCustomers')}} <span class="text-primary pointer" @click="changeCurrency">{{currency}} <i class="fa fa-sort"></i></span></small>
                            <template v-if="showSkeleton">
                              <h1 class="pt8 pb16 balance skeleton-balance">
                                  <div class="skeleton-left">
                                      <div class="line h25 w50 m10"></div>
                                  </div>
                              </h1>
                            </template>
                            <template v-else>
                              <h1 class="pt8 pb16 balance">
                                <ICountUp
                                  :delay="delay"
                                  :endVal="userCount"
                                  :options="options"
                                  @ready="onReady"
                                />
                              </h1>
                            </template>
                          </div>
                          <div class="total">
                            <small class="text-muted">{{$t('totalMinedBitcoins')}} </small>
                            <template v-if="showSkeleton">
                              <h1 class="pt8 pb16 balance skeleton-balance">
                                  <div class="skeleton-left">
                                      <div class="line h25 w50 m10"></div>
                                  </div>
                              </h1>
                            </template>
                            <template v-else>
                            <h1 class="pt8 pb16 balance">{{totalMinedBitcoins}} {{currency}}</h1>
                            </template>
                          </div>
                          <div class="total">
                            <small class="text-muted">{{$t('activeHardwarePower')}} </small>
                            <template v-if="showSkeleton">
                              <h1 class="pt8 pb16 balance skeleton-balance">
                                  <div class="skeleton-left">
                                      <div class="line h25 w50 m10"></div>
                                  </div>
                              </h1>
                            </template>
                            <template v-else>
                            <h1 class="pt8 pb16 balance">{{totalMiningPower}}</h1>
                            </template>
                          </div>
                          <div class="total">
                            <small class="text-muted">{{$t('totalPowerAvailable')}} </small>
                            <template v-if="showSkeleton">
                              <h1 class="pt8 pb16 balance skeleton-balance">
                                  <div class="skeleton-left">
                                      <div class="line h25 w50 m10"></div>
                                  </div>
                              </h1>
                            </template>
                            <template v-else>
                            <h1 class="pt8 pb16 balance">12 EH/s</h1>
                            </template>
                          </div>
                          <div class="available">
                            <small class="text-muted">{{$t('customersPayouts')}} </small>
                            <template v-if="showSkeleton">
                              <h1 class="pt8 pb16 balance skeleton-balance">
                                  <div class="skeleton-left">
                                      <div class="line h25 w50 m10"></div>
                                  </div>
                              </h1>
                            </template>
                            <template v-else>
                            <h1 class="pt8 pb16 balance">{{totalWithdrawals}} {{currency}}</h1>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>    

                    <div class="col-lg-9">
                      
                          <div class="content">
                            <!-- <div class="panel "> -->

                            <h3 class="mb16 text-white"> {{$t('recentCustomersWithdrawals')}}</h3>
                            <!-- <div class="col d-grid gap-2 d-md-flex justify-content-md-start mt-3">
                              
                            </div> -->
                            <div class="table-wrap relative shadow">
                              <div class="table-responsive table-container">
                                  <table class="table-striped nh padding-normal" v-if="transactions.length > 0">
                                      <thead>
                                          <tr>
                                              <th scope="col">{{$t('transaction')}}</th>
                                              <th scope="col" class="text-right">{{$t('amount')}}</th>
                                              <th scope="col" class="text-right">{{$t('username')}}</th>
                                              <th scope="col" class="text-right">{{$t('dateTime')}}</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr v-for="(transaction, index) in transactions" :key="index">
                                              <td><a :href="'https://www.blockchain.com/explorer/transactions/btc/' + transaction.transaction_id" target="_blank">
                                                      {{ transaction.transaction_id.slice(0, 20) }}...
                                                  </a>
                                              </td>
                                              <td class="text-right">{{ currency === 'BTC' ? transaction.amountBTC : transaction.usd }} <span class="text-muted"> {{currency}}</span></td>
                                              <td class="text-right">{{ transaction.username }}</td>
                                              <td class="text-right">{{ formatDate(transaction.created_at) }}</td>
                                          </tr>
                                      </tbody>
                                  </table>

                                  <div v-else-if="!isLoading && transactions.length > 0" class="panel d-flex justify-content-center mt-4">
                                      <p>{{$t('noData')}}</p>
                                  </div>

                                  <div class="overlay loader" v-if="isLoading">
                                    <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                                  </div>

                              </div>
                            </div>
                            <!-- </div> -->
                          </div>
                      </div>
                    </div>
                  <!-- </div> -->
            
                </div>
                   
            
    </section>

    <Footer/>
    
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import Footer from './Footer.vue';
import ICountUp from 'vue-countup-v2';
export default {
  name: 'CloudhashFrontendInsights',
  components: { Footer, ICountUp },
  data() {
    return {
      currency: 'USD',
      isLoading: true,
      showSkeleton: true,
      bgImg: {
            backgroundImage: `url(${require('@/assets/images/1.svg')})`
        },
       transactions:[],
       bitcoinValue: 0,
       totalMinedBitcoins: 0,
       totalMinedBitcoinsOriginal: 0,
       userCount: 0,
       totalMiningPower: 0,
       totalWithdrawalsOriginal: 0,
       totalWithdrawals: 0,
       interval: null,
       firstLoad: true,
       previousUserCount: 0,
       delay: 0,
       options: {
          separator: '',
          decimal: '.',
          prefix: '',
          suffix: ''
      }
    };
  },

  mounted() {
   // this.getBtcPrice();
    //this.insightsData();
  },
  beforeMount() {
    //this.interval = setInterval(this.insightsData, 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    insightsData() {
      axios.get(process.env.VUE_APP_API_URL+'insightsData')
      .then((res)=>{
        const newUserCount = res.data.userCount;
        this.previousUserCount = this.userCount;
        this.userCount = newUserCount;
        this.totalMinedBitcoins = res.data.totalMinedBitcoins.toFixed(2);
        this.totalMinedBitcoinsOriginal = res.data.totalMinedBitcoins;
        let { hashValue, hashUnit } = this.convertToTHS(res.data.totalMiningPower);
        this.totalMiningPower = `${hashValue} ${hashUnit}`;
        this.totalWithdrawals = res.data.totalWithdrawals.toFixed(2);
        this.totalWithdrawalsOriginal = res.data.totalWithdrawals;
        this.transactions = res.data.lastWithdrawals;
        if (this.firstLoad) {
            this.firstLoad = false; // Desactiva la bandera después de la primera carga
        }
        
      })
      .catch((error)=>{
        console.log('error getting insightsData',error.response.error);
      })
      .finally(() => {
          this.showSkeleton = false; // Siempre oculta el efecto de carga al finalizar la llamada
          this.isLoading = false;
      });
    },
    onReady(instance, CountUp) {
      if (this.previousUserCount !== this.userCount) {
        instance.update(this.userCount);
      }
    },
    checkDataChange() {
        // Actualizamos los datos previos
        this.previousData = {
            userCount: this.userCount
        };
    },
    getBtcPrice() {
      axios.get(process.env.VUE_APP_API_URL+'getBtcPrice')
      .then((res)=>{
        this.bitcoinValue = res.data.price;
      })
      .catch((error)=>{
        console.log('error getting BTC Price',error.response.error);
      })
    },
    formatDate(date) {
        return moment(date).format('DD MMM YYYY HH:mm');
    },
    convertToTHS(amount) {
        // Convertir a TH/s
        // Puedes ajustar esta función según las unidades que uses
        // GH/s, TH/s, PH/s, EH/s
        // Por ejemplo, si inputAmount es en PH/s, necesitas multiplicar por 1000 para obtener TH/s
        const hashUnits = ['GH/s', 'TH/s', 'PH/s', 'EH/s']; // Unidades de hash
        let index = 1; // Índice de TH/s por defecto
        
        while (amount >= 1000 && index < hashUnits.length - 1) {
            amount /= 1000;
            index++;
        }
        
        return {
            hashValue: amount.toFixed(2),
            hashUnit: hashUnits[index]
        };
    },
    changeCurrency() {
        this.currency = this.currency === 'USD' ? 'BTC' : 'USD';
        //this.currency = currency;
        var total = 1200;
//        console.log();
        // Lógica para obtener el valor del Bitcoin en la moneda seleccionada
         if (this.currency === 'BTC') {
            // Lógica para obtener el valor del Bitcoin en USD
            //this.bitcoinValue = 50000; // Ejemplo
            this.transactions.forEach(transaction => {
                transaction.amountBTC = (transaction.usd / this.bitcoinValue).toFixed(8); // Multiplica el amount por el valor del Bitcoin en USD
            });
            this.totalMinedBitcoins = (this.totalMinedBitcoins / this.bitcoinValue).toFixed(8); // Ejemplo
            this.totalWithdrawals = (this.totalWithdrawals / this.bitcoinValue).toFixed(8);
        } else if(this.currency === 'USD'){
            this.totalMinedBitcoins = this.totalMinedBitcoinsOriginal.toFixed(2);
            this.totalWithdrawals = this.totalWithdrawalsOriginal.toFixed(2);
        }
    }
  },
};
</script>
<style scope>
.ml-3 {
  margin-left:15px
}

.br-table {
  border-radius: 0.375rem;
}

.mb-2 {
  margin-bottom: 3px
}

.py-2 {
    padding-top: 0.375rem!important;
}

.feature-box {
  background-color: var(--bs-body-bg);
  border-radius: 4px;
  flex: 1 1 auto;
    padding: 1rem 1rem;
    color: var(--bs-card-color);
}

.active {
    background-color: #FF4D6B;
    color: white;
}

.table {
    box-shadow: 0 1px 4px #23232329;
    margin: 0 auto 80px;
}

.table-container {
    position: relative;
    min-height: 90px;
    overflow-x: auto;
}

table.nh {
    width: 100%;
    border: none;
}

table.nh thead {
    background: #fff;
}

table.nh th:first-child {
    padding-left: 32px;
}

.table th {
    color: #959595;
}

table.nh th {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.6;
    font-weight: 400;
    padding: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

table.nh tbody tr:nth-child(odd) {
    background-color: #fcfcfc;
}

table.nh tbody tr:first-child {
    box-shadow: inset 1px 4px 6px -5px #23232373;
}

table.nh tbody tr {
    background-color: #fff;
}

table.nh tbody tr td:first-child {
    padding-left: 32px;
}

table.nh tbody tr td {
    padding: 12px 16px;
    border-bottom: 1px solid #EEE;
    font-size: 12px;
    line-height: 1.33;
    color: #3a3939;
}

.text-muted {
    color: #959595 !important;
}

.text-right {
    text-align: right;
}

.wallet-container {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 32px;
}

.sidebar {
    width: 232px;
}

.box {
    box-shadow: 0 1px 4px #23232329;
    border-radius: 4px;
    margin-bottom: 16px;
    background: #fff;
}

.box .total {
    padding: 24px 16px 0;
    border-bottom: 1px solid #EEE;
    position: relative;
}

.box .available {
    padding: 24px 16px 12px;
    position: relative;
}

.box i.info {
    position: absolute;
    top: 12px;
    right: 12px;
}

.box .total h1 {
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -.1px;
    line-height: 40px;
}

.box .available h1 {
  font-size: 24px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -.1px;
    line-height: 40px;
}

.pb16 {
    padding-bottom: 16px;
}

.pt8 {
    padding-top: 8px;
}

.pointer {
    cursor: pointer;
}

.text-primary {
    color: rgb(25, 52, 93) !important;
}

.content {
    flex: 1;
    margin-left: 0px;
}

.box .pending {
    padding: 24px 16px 18px;
    position: relative;
}

.text-bold {
    font-weight: 700;
}

.fs16 {
    font-size: 16px;
}

.mb8 {
    margin-bottom: 8px;
}

.mt8 {
    margin-top: 8px;
}

.box .available .deposit-withdraw {
    margin: 16px 0;
}

.box .available .deposit-withdraw .btn {
    padding: 6px!important;
    width: calc(50% - 4px);
    margin: 0;
}

.mb16 {
    margin-bottom: 18px;
}

.content {
    box-shadow: rgba(35, 35, 35, 0.16) 0px 1px 4px;
    background: none !important;
    flex: 1 1 0%;
    border-radius: 4px;
}

.fs16 {
  font-size: 16px;
}

.skeleton-balance .line {
     height: 12px;
     margin-top: 6px;
     margin-bottom: 6px;
     border-radius: 4px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation:  shimmer 1.5s infinite linear;
}

.skeleton-left {
    flex:1;
}

.skeleton-balance {
     padding:15px 15px 15px 0;
     max-width: 300px;
     width: 135px;
     height: 10px;
     color: transparent; /* Texto transparente para simular un esqueleto */
     margin-top: 5px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 4px;
}

</style>
