<template>
  <div>
    <template v-if="showCookies">
        <div class="c-idZwvW c-idZwvW-fGHEql-breakpoint-fluid c-idZwvW-icFJdSG-css">
            <div class="CookieConsent" style="align-items: baseline; background: rgb(255, 255, 255); color: white; display: flex; flex-wrap: wrap; justify-content: space-between; left: 40px; position: fixed; width: 250px; z-index: 999; box-sizing: border-box; margin-bottom: 40px; padding: 36px 24px 24px; box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 8px 0px, rgba(0, 0, 0, 0.08) 0px 2px 4px 0px; border-radius: 8px; bottom: 0px;">
                <div class="" style="flex: 1 1 100%; margin: 0px; padding: 0px;">
                <img src="@/assets/images/cookie.svg" alt="Stars" loading="lazy" style="position: absolute; top: 0px; width: 64px; left: 50%; transform: translate(-50%, -50%);">
                <p class="c-kWnKGo c-kWnKGo-hanxqg-variant-caption1 c-kWnKGo-idZRbmA-css">We use cookies for essential website functions and to better understand how you use our site, so we can create the best possible experience for you ❤️</p>
                <router-link to="/privacy-policy" class="c-cSIKJm c-cSIKJm-ifHCYnp-css">Privacy Policy</router-link>
                </div>
                <div class="">
                <button class="" id="rcc-confirm-button" @click="gotCookies" aria-label="Accept cookies" style="background: rgb(8, 34, 8); border: 0px; border-radius: 8px; box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px; color: rgb(255, 255, 255); cursor: pointer; flex: 0 0 auto; padding: 8px 32px; margin: 0px; width: 100px; font-size: 12px; font-family: &quot;Karla Bold&quot;, sans-serif; position: absolute; bottom: 24px; right: 24px;">Got it</button>
                </div>
            </div>
        </div>
    </template>
    <footer id="footer" class=" border-0">
        <div id="copyrights" class=" py-5">
            <div class="container">
                <div class="row col-mb-30">
                    <div class="col-md-6 text-center text-white text-md-start">
                        Copyrights © {{ currentYear() }} All Rights Reserved by CloudHashX Ltda.<br>
                        <div class="copyright-links">
                            <router-link to="/terms-of-use" class="text-white">{{$t('termsOfUse')}}</router-link> / <router-link to="/privacy-policy" class="text-white">{{$t('privacyPolicy')}}</router-link>
                        </div>
                    </div>
                    <div class="col-md-6 text-center text-md-end">
                        <img src="@/assets/logo-white.svg" alt="CloudHash" style="width: 200px;">
                    </div>
                    <hr>
                    <div class="col-md-12 copyright-links">
                        <div class="mt-3">
                            <p class="text-white" v-html="$t('disclaimer')"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'CloudhashFrontendFooter',

  data() {
    return {
      showCookies: false,
    };
  },

  mounted() {
    this.checkEntrance();
  },

  methods: {
    currentYear() {
        return moment().year();
    },
    gotCookies() {
        this.showCookies = false;
        localStorage.setItem('hasSeenCookies', 'true');
    },
    checkEntrance() {
        const hasSeenModal = localStorage.getItem('hasSeenCookies');
        if (!hasSeenModal) {
            this.showCookies = true;
            localStorage.setItem('hasSeenCookies', 'true');
        }
    }
  },
};
</script>
<style scoped>
.copyright-links a {
    display: inline-block;
    margin: 0 0.25rem;
    color: var(--nbhs-copyrights-link-color);
    border-bottom: 1px dotted var(--nbhs-copyrights-link-color);
}

.c-idZwvW-icFJdSG-css {
    box-sizing: border-box;
    position: fixed;
    bottom: 0px;
}

.c-kWnKGo-idZRbmA-css {
    width: 100%;
    color: #63727e;
    margin-bottom: 18px;
}

.c-cSIKJm-ifHCYnp-css {
    margin-bottom: 8px;
    color: #000;
    font-size: 12px;
    font-family: Karla, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
}

.c-idZwvW {
    margin-left: auto;
    margin-right: auto;
}

footer .section {
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-top: 24px;
    color: #fff;
}

footer .servers div.server {
    display: inline-block;
    min-width: 150px;
    padding: 1px 0 12px;
}

.mr16 {
    margin-right: 16px;
}

footer .servers div.server i.fa {
    font-size: 11px;
    position: relative;
    top: -1px;
    margin-right: 8px;
    color: #68b641;
}
</style>