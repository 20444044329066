<template>
    <div>
        <div v-if="!finish">
            {{ minutes | twoDigits }}:{{ seconds | twoDigits }}
        </div>
        <div v-else>
            Please refresh
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone';
export default {
    props: {
        date: null
    },
    data() {
        return {
            now: null,
            event: this.date,
            finish: false,
            intervalId: null
        }
    },
    mounted() {
        this.startTimer();
    },
    methods: {
        startTimer() {
            const _self = this;
            this.intervalId = setInterval(() => {
                var now = moment().unix();
                _self.now = now;
                if (!_self.finish) {
                    if (_self.calculatedDate - _self.now <= 0) {
                        _self.finish = true;
                        clearInterval(_self.intervalId);
                        _self.$emit('onFinish');
                    }
                }
            }, 1000);
        }
    },
    computed: {
        secondCount() {
            return this.calculatedDate - this.now;
        },
        calculatedDate() {
            return moment.utc(this.event).unix();
        },
        seconds() {
            if (this.secondCount < 0) return 0;
            return this.secondCount % 60;
        },
        minutes() {
            if (this.secondCount < 0) return 0;
            return Math.trunc(this.secondCount / 60) % 60;
        },
        hours() {
            if (this.secondCount < 0) return 0;
            return Math.trunc(this.secondCount / 3600) % 24;
        },
        days() {
            if (this.secondCount < 0) return 0;
            return Math.trunc(this.secondCount / 86400);
        }
    },
    filters: {
        twoDigits(value) {
            if (value.toString().length <= 1) {
                return '0' + value.toString();
            }
            return value.toString();
        }
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    }
}
</script>


<style scoped>

</style>