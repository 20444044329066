<template>

  <div class="text-right pagination mt16">
    <template v-if="pagination.current_page !== 1">
        <button @click.prevent="changePage(pagination.current_page = 1)" class="btn">
            <i class="fas fa-angle-left"></i> {{$t('pagination.prev')}} 
        </button>
    </template>
      <span class="page">{{$t('pagination.page')}} {{pagination.current_page}}</span>
      <button class="btn" v-if="pagination.current_page !== pagination.last_page" @click="changePage(pagination.current_page + 1)">
        <i class="fas fa-angle-right"></i> {{$t('pagination.next')}} 
      </button>
  </div>
</template>

<script>
export default {
  props: {
    pagination: {
      type: Object,
      required: true
    },
    offset: {
      type: Number,
      default: 4
    }
  },
  computed: {
    pagesNumber() {
      if (!this.pagination.to) {
        return [];
      }
      let from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      let to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      let pagesArray = [];
      for (let page = from; page <= to; page++) {
        pagesArray.push(page);
      }
      return pagesArray;
    }
  },
  methods: {
    changePage(page) {
      this.pagination.current_page = page;
      this.$emit('paginate');
    }
  }
}
</script>

<style scoped>
.pagination {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    padding: 16px 32px;
}

.text-right {
    text-align: right;
}

.mt16 {
    margin-top: 16px;
}

.pagination .page {
    margin: 0px 16px;
    font-size: 12px;
}

.pagination .btn {
    border-radius: 4px;
    font-size: 12px;
    line-height: 1.33;
    color: rgb(25, 52, 93);
    padding: 8px 14px;
    border: 1px solid rgb(238, 238, 238);
    cursor: pointer;
    white-space: nowrap;
    text-transform: none;
    font-weight: 400;
    transition: background-color 0.2s ease 0s, color 0.2s ease 0s, border-color 0.2s ease 0s;
    background: rgb(238, 238, 238);
}

.pagination .btn .fa-angle-left {
    margin-right: 12px;
}

.pagination .btn .fa-angle-right {
    margin-left: 12px;
}

.pagination .btn + .btn {
    margin-left: 8px;
}

.pagination .btn.active {
    background: rgb(238, 238, 238);
    color: rgb(149, 149, 149);
}

.pagination .btn:hover:not(.active) {
    color: rgb(255, 255, 255);
    background: rgb(25, 52, 93);
    border-color: rgb(25, 52, 93);
}
</style>