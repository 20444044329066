import { render, staticRenderFns } from "./Upgrade.vue?vue&type=template&id=7ad9fceb&scoped=true"
import script from "./Upgrade.vue?vue&type=script&lang=js"
export * from "./Upgrade.vue?vue&type=script&lang=js"
import style0 from "./Upgrade.vue?vue&type=style&index=0&id=7ad9fceb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ad9fceb",
  null
  
)

export default component.exports