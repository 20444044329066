<template>
    <!-- <div class="sidebar mt-3">
        <div class="panel shadow mb-3">
            <h2>{{$t('panel')}}</h2>
            <router-link to="/my/dashboard" exact>{{$t('dashboard')}}</router-link>
            <router-link to="/my/upgrade" exact>{{$t('upgradePower')}}</router-link>
            <router-link to="/my/withdraw" exact>{{$t('withdraw')}}</router-link>
            <router-link to="/my/partners" exact>{{ $t('referrals')}}</router-link>
            <router-link to="/my/settings/security" exact>{{ $t('settings')}}</router-link>
        </div>
    </div> -->

    <div data-v-fb88c6ce="" data-v-82c01ab1="" class="main-top-menu light">
  <a data-v-fb88c6ce="" href="/my/dashboard" class="router-link-active router-link-exact-active" exact="" aria-current="page">
    <div data-v-fb88c6ce="" class="logo"></div>
  </a>
  <ul data-v-42580888="" data-v-fb88c6ce="" class="menu-left">
    <li data-v-42580888="">
      <a data-v-42580888="" href="/my/dashboard" class="router-link-active router-link-exact-active router-link-active" aria-current="page">Panel</a>
    </li>
    <li data-v-42580888="">
      <a data-v-42580888="" href="#" class="">Minería</a>
    </li>
    <li data-v-42580888="">
      <a data-v-42580888="" href="/my/marketplace" class="">Mercado de hash power</a>
    </li>
    <li data-v-42580888="">
      <a data-v-42580888="" href="/my/wallets" class="">Billetera</a>
    </li>
  </ul>
  <div data-v-fb88c6ce="" class="mobile-menu">
    <div data-v-fb88c6ce="" class="selected">
      <i data-v-fb88c6ce="" class="fa fa-bars icon"></i> Menú
    </div>
    <div data-v-fb88c6ce="" class="options">
      <a data-v-fb88c6ce="" href="/my/dashboard" class="router-link-active router-link-exact-active router-link-active" aria-current="page">Panel</a>
      <a data-v-fb88c6ce="" href="/my/mining/rigs" class="">Minería</a>
      <a data-v-fb88c6ce="" href="/my/marketplace" class="">Mercado de hash power</a>
      <a data-v-fb88c6ce="" href="/my/easymining" class="">EasyMining</a>
      <a data-v-fb88c6ce="" href="/my/easymining" class="option">Comprar un paquete</a>
      <a data-v-fb88c6ce="" href="/my/easymining/orders/massbuy" class="option">Compra Masiva</a>
      <a data-v-fb88c6ce="" href="/my/easymining/orders/active" class="option">Mis paquetes activos</a>
      <a data-v-fb88c6ce="" href="/my/easymining/orders/completed" class="option">Mis paquetes completados</a>
      <a data-v-fb88c6ce="" href="/my/wallets" class="">Billetera</a>
      <a data-v-fb88c6ce="" href="/my/buy-sell" class="">Comprar/vender criptomonedas</a>
      <!-- <a data-v-fb88c6ce="" href="/my/shop" class="">Tienda</a>
      <a data-v-fb88c6ce="" href="/my/shop" class="option">Visitar la tienda</a> -->
      <!-- <a data-v-fb88c6ce="" href="/my/shop/orders" class="option">Mis pedidos</a> -->
    </div>
  </div>
  <div data-v-fb88c6ce="" class="menu-right">
    <div data-v-203f45eb="" data-v-fb88c6ce="" class="wallets-holder" @click="openBalance" v-click-outside="closeBalance">
      <div data-v-203f45eb="" class="row-item wallets has-dropdown" :class="isBalanceOpen ? 'open' : ''">
        <i data-v-203f45eb="" class="icon-wallet"></i>
        <span data-v-203f45eb="" class="wallet-value"> ≈ {{isBalanceHidden ? "****" : "0.00"}} USD 
            <i data-v-203f45eb="" class="fa fa-sort-down"></i>
        </span>
      </div>
      <div data-v-203f45eb="" class="wallets-dropdown" v-if="isBalanceOpen">
            <div data-v-203f45eb="" class="pl16 pr16">
                <hr data-v-203f45eb="" class="mt8 mb16">
                <div data-v-203f45eb="" class="flex flex--justify-space-between">
                <div data-v-203f45eb="">Total en BTC ≈ </div>
                <div data-v-203f45eb="">{{isBalanceHidden ? "****" : "0.00000000"}}</div>
                </div>
                <div data-v-203f45eb="" class="flex flex--justify-space-between mt8">
                <div data-v-203f45eb="">Total en USD ≈ </div>
                <div data-v-203f45eb="">${{isBalanceHidden ? "****" : "0.00"}}</div>
                </div>
                <div data-v-203f45eb="" class="flex flex--justify-space-between mt16 mb16">
                <button data-v-203f45eb="" class="btn primary normal outline flex__fill-gap">Depositar</button>
                <button data-v-203f45eb="" class="btn primary normal outline flex__fill-gap">Retirar</button>
                </div>
            </div>
        </div>
    </div>
    <div data-v-f3834943="" data-v-fb88c6ce="" class="hide-amounts" @click="toggleBalance">
      <i data-v-f3834943="" class="far" :class="isBalanceHidden ? 'fa-eye-slash' : 'fa-eye'"></i>
    </div>
    
    <div data-v-d09c60ff="" data-v-fb88c6ce="" class="nav-notifications" v-click-outside="hideNotify" @click="toggleNotify">
      <span data-v-d09c60ff="" class="bell" :class="isNotifyOpen ? 'open' : ''">
        <i data-v-d09c60ff="" class="far fa-bell"></i>
      </span>
      <div data-v-d09c60ff="" class="dropdown" :class="isNotifyOpen ? 'open' : ''">
        <div data-v-d09c60ff="" class="row header">
          <div data-v-d09c60ff="" class="col-nano-8">Notificaciones</div>
          <div data-v-d09c60ff="" class="col-nano-4 text-right">
            <a data-v-d09c60ff="" href="/my/settings/notification" class="">
              <i data-v-d09c60ff="" class="fa fa-cog"></i>
            </a>
          </div>
        </div>
        <div data-v-d09c60ff="" class="content">
          <div data-v-d09c60ff="" class="empty">
            <div data-v-d09c60ff="" class="icon"></div>
            <h3 data-v-d09c60ff="">Aún no hay notificaciones</h3>
          </div>
        </div>
        <div data-v-d09c60ff="" class="footer">
          <a data-v-d09c60ff="" href="/my/notifications" class="">Ver todas las notificaciones</a>
        </div>
      </div>
    </div>
    <div data-v-ded7a97f="" data-v-fb88c6ce="" v-click-outside="hide" class="dropdown" @click="toggleDropdown" :class="isDropdownOpen ? 'open' : ''">
      <div data-v-ded7a97f="" class="selected" style="border-top-color: #002BAE;">
        <div data-v-e9688c3f="" data-v-ded7a97f="" class="avatar icon" style="color: white; font-size: 20px; line-height: 40px; width: 40px; height: 40px; border-radius: 40px; background-color: #002BAE;">N</div>
      </div>
      <div data-v-ded7a97f="" class="options">
        <div data-v-ded7a97f="" class="options-wrap">
          <div data-v-ded7a97f="" class="menu">
            <div data-v-ded7a97f="" class="option profile org" style="background-color: #002BAE">
              <div data-v-e9688c3f="" data-v-ded7a97f="" class="avatar icon" style="color: white; font-size: 32px; line-height: 72px; width: 72px; height: 72px; border-radius: 72px; background-color: #002BAE;">N</div>
              <div data-v-ded7a97f="" class="name"></div>
              <div data-v-ded7a97f="" class="email">nuevoestecel@gmail.com
              </div>
            </div>
            <div data-v-ded7a97f="" class="option">
              <a data-v-ded7a97f="" href="/my/settings/profile" class="">
                <i data-v-ded7a97f="" class="fa fa-gear"></i> Mi configuración </a>
            </div>
            <!-- <div data-v-ded7a97f="" class="option">
              <a data-v-ded7a97f="" href="/my/shop/orders" class="">
                <i data-v-ded7a97f="" class="fas fa-file-alt" style="margin-left: 2px;"></i> Mis pedidos de la NiceHash Shop </a>
            </div> -->
            <div data-v-ded7a97f="" class="option">
              <a data-v-ded7a97f="" class="nohover">
                <i data-v-ded7a97f="" class="far fa-window-restore"></i> Tema oscuro <label data-v-aaf05ae3="" data-v-ded7a97f="" class="switch pull-right">
                  <input data-v-aaf05ae3="" class="pull-right" type="checkbox">
                  <span data-v-aaf05ae3="" class="slider"></span>
                </label>
              </a>
            </div>
            <!-- <div data-v-ded7a97f="" class="option">
              <a data-v-ded7a97f="" class="pointer" href="https://www.nicehash.com/support">
                <i data-v-ded7a97f="" class="fa fa-question-circle"></i> Servicio de asistencia </a>
            </div> -->
            <div data-v-ded7a97f="" class="option">
              <a data-v-ded7a97f="" class="pointer" @click="onLogOut">
                <i data-v-ded7a97f="" class="fa fa-sign-out-alt"></i> Cerrar sesión </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import '@/assets/css/main.css';
import clickOutside from '../directives/clickOutside';
import { mapGetters, mapActions, mapState } from 'vuex';
export default {
  name: 'CloudhashFrontendSidebar',

  data() {
    return {
      isDropdownOpen: false,
      isNotifyOpen: false,
      isBalanceHidden: false,
      isBalanceOpen: false,
    };
  },
  created() {
    document.body.classList.add('theme-light');
  },
  mounted() {
    
  },
  directives: {
    clickOutside,
  },
  methods: {
    ...mapActions({
        logout: 'user/logout'
    }),
    onLogOut() {
        this.logout().then((res) => {
            this.$router.push({ name: "Login" });
        })
    },
    closeBalance() {
        this.isBalanceOpen = false;
    },
    openBalance() {
        this.isBalanceOpen = !this.isBalanceOpen
    },
    toggleBalance() {
        this.isBalanceHidden = !this.isBalanceHidden
    },
    toggleNotify() {
        this.isNotifyOpen = !this.isNotifyOpen
    },
    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen
    },
    hide() {
        this.isDropdownOpen = false;
    },
    hideNotify() {
        this.isNotifyOpen = false;
    },
  },
};
</script>
<style scoped>
.mb32 {
    margin-bottom: 32px;
}

.sidebar {
  width: 250px;
}

@media only screen and (max-width : 991px) {
    .sidebar {
        flex-basis:100%
    }
}

.sidebar .panel {
    padding-bottom: 16px;
}

.panel {
  background-color: #fff;
  border-radius: 10px;
}

.sidebar h2 {
    height: 64px;
    line-height: 64px;
    border-bottom: 1px solid #EEE;
    padding: 0 32px;
    font-weight: 300;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sidebar a {
    padding: 16px 32px 16px 30px;
    display: block;
    color: #959595;
    border-left: 2px solid transparent;
}

.sidebar a:hover {
    border-left: 2px solid rgb(25, 52, 93)
}

.sidebar a.router-link-active {
    color: rgb(25, 52, 93);
    font-weight: 700;
    border-left: 2px solid rgb(25, 52, 93);
    padding-left: 40px
}

.panel.big-shadow {
    box-shadow: 0 8px 16px #23232329
}

.content {
    box-shadow: 0 1px 4px #23232329;
    background: #fff;
    flex: 1;
    border-radius: 4px
}

@media only screen and (max-width : 991px) {
    .content {
        flex-basis:100%;
        max-width: calc(100vw - 32px)
    }
}

.content .panel {
    padding: 32px;
    box-shadow: none
}

@media only screen and (max-width : 768px) {
    .content .panel {
        padding:24px
    }
}

.content .panel .table {
    margin-left: -32px;
    width: calc(100% + 64px)
}

@media only screen and (max-width : 768px) {
    .content .panel .table {
        margin-left:-24px;
        width: calc(100% + 48px)
    }
}

.main-top-menu[data-v-fb88c6ce] {
    background-color: #fff;
    height: 64px;
    box-shadow: 0 1px 4px #23232329;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
}

</style>